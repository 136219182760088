import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageTitle, Table, TableCell, TableRow } from '..';
import { apiVouchersMissing, apiVoucherTypesGet } from '../../../api/api-vouchers';
import { TourTypeContext } from '../../../contexts/tour-type-context';
import { formatDate, formatTime } from '../../../helpers/helpers';

const TicketPriceIssues = () => {
    const [issues, setIssues] = useState([]);
    const [missing, setMissing] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        setIssues((await apiVoucherTypesGet(tourTypeId)).filter((x) => !x.isDisabled && x.remaining < x.warnBelow));
        setMissing(await apiVouchersMissing(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    if ((!issues || issues.length === 0) && (!missing || missing.length === 0)) {
        return null;
    }

    return (
        <>
            {issues && issues.length > 0 && (
                <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 my-2">
                    <PageTitle title="! Voucher Warning !" />
                    <Table slim>
                        <tbody>
                            {issues.map((item, key) => (
                                <TableRow key={key} rowNum={key}>
                                    <TableCell>{item.voucherTypeName}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
            {missing && missing.length > 0 && (
                <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 my-2">
                    <PageTitle title="! Bookings Missing Vouchers Warning !" />
                    <Table slim>
                        <thead>
                            <tr>
                                <TableCell text="Voucher" header />
                                <TableCell text="Date" header />
                                <TableCell text="Time" header />
                                <TableCell text="Lane" header center />
                                <TableCell text="Booking Name" header />
                                <TableCell text="Required" header center />
                                <TableCell text="Allocated" header center />
                            </tr>
                        </thead>
                        <tbody className="align-baseline">
                            {missing.map((item, key) => (
                                <TableRow key={key} rowNum={key}>
                                    <TableCell text={item.voucherTypeName} />
                                    <TableCell text={formatDate(item.date)} />
                                    <TableCell text={formatTime(item.time)} />
                                    <TableCell center text={item.lane} />
                                    <TableCell text={item.name} />
                                    <TableCell center text={item.amount} />
                                    <TableCell center text={item.allocated} />
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    );
};

export default TicketPriceIssues;
