import moment from 'moment';
import 'moment/locale/en-gb';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { apiLastTourDelete, apiLastToursGet } from '../../api/api-last-tours';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { closestPriorDate, formatDate } from '../../helpers/helpers';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';
import { lastToursDefaultPageActions } from '../last-tours/page-actions';

const LastTourList = () => {
    const [lastTours, setLastTours] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        setLastTours(await apiLastToursGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    const deleteLastTour = (id) => {
        swal({
            title: 'Are You Sure?',
            icon: 'warning',
            buttons: {
                no: { text: 'No', value: false },
                danger: { text: 'Yes', value: true },
            },
        }).then(async (value) => {
            if (value) {
                await apiLastTourDelete(tourTypeId, id);
                await LoadData();
            }
        });
    };

    const lastToursToDisplay = () => {
        if (lastTours.length === 0) {
            return lastTours;
        }

        const displayAfter = closestPriorDate(lastTours.map((x) => x.fromDate));

        if (!displayAfter) {
            return lastTours;
        }

        return lastTours.filter((x) => moment(x.fromDate).isSameOrAfter(displayAfter));
    };

    return (
        <div className="max-w-screen-md my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Last Tours" actions={lastToursDefaultPageActions} />
            <Table>
                <tbody>
                    {lastToursToDisplay().map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell text={formatDate(item.fromDate)} className="w-1/4" />
                            <TableCell>
                                <FontAwesomeIcon icon="clock" />
                                <span className="ml-2">{item.lastTourTimeDisplay}</span>
                            </TableCell>
                            <TableCell actionLeft>
                                <Link to={`/last-tour/${item.id}`}>
                                    <FontAwesomeIcon icon="edit" title="Edit" />
                                </Link>
                            </TableCell>
                            <TableCell actionRight>
                                <button onClick={() => deleteLastTour(item.id)}>
                                    <FontAwesomeIcon icon="trash-alt" title="Delete" />
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default LastTourList;
