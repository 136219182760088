import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { apiSeasonSave, apiTourConfigurationsGet } from '../../api/api-seasons';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, InputCheckbox, InputSpinner, InputText, PageTitle } from '../common';
import { seasonEditPageActions } from './page-actions';

const defaultData = {
    seasonName: '',
    isDisabled: false,
    maxGroups: 1,
};

const SeasonAdd = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { tourTypeId } = useContext(TourTypeContext);
    const [tourConfigurations, setTourConfigurations] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const LoadData = async () => {
            const tourConfigurationsResult = await apiTourConfigurationsGet(tourTypeId);
            setTourConfigurations(tourConfigurationsResult);

            const temp = { ...defaultData };
            tourConfigurationsResult.forEach((config) => {
                temp[`configuration_${config.id}`] = 0;
            });

            setData(temp);
            setLoading(false);
        };

        LoadData();
    }, [tourTypeId]);

    const formSchema = Yup.object().shape({
        seasonName: Yup.string().required('Required'),
    });

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="New Season" actions={seasonEditPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        const tourConfigurationsCounts = [];
                        tourConfigurations.forEach((config) => {
                            tourConfigurationsCounts.push({ id: config.id, max: values[`configuration_${config.id}`] });
                        });

                        await apiSeasonSave(tourTypeId, null, values.seasonName, values.maxGroups, tourConfigurationsCounts, values.isDisabled);

                        history.push('/');
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <div className="grid grid-cols-1 gap-y-2">
                                <InputText name="seasonName" label="Name" />
                                <InputSpinner name="maxGroups" min={0} max={99} label="Max Concurrent Tours" />
                                {tourConfigurations.map((config, key) => (
                                    <InputSpinner key={key} name={`configuration_${config.id}`} min={0} max={99} label={`${config.tourConfigurationName} Max`} />
                                ))}
                                <InputCheckbox name="isDisabled" label="Disabled" />
                            </div>
                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-2" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default SeasonAdd;
