import { useField } from 'formik';
import React from 'react';

const InputCheckbox = ({ label, wrapperClassName = '', className = '', horizontal = true, hideLabel = false, center = false, colour = 'text-blue-600', ...props }) => {
    const [field] = useField(props);
    const vertical = () => !horizontal;

    if (center) {
        className = `justify-center ${className}`;
        wrapperClassName = `text-center ${className}`;
    }

    return (
        <div className={wrapperClassName}>
            {!hideLabel && vertical() && <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">{label}</div>}
            <div className={`flex items-center ${className}`}>
                <input id={field.name} type="checkbox" className={`form-checkbox ml-2 my-1 h-6 w-6 ${colour} transition duration-150 ease-in-out`} checked={field.value} onChange={field.onChange} />
                {!hideLabel && horizontal && (
                    <label htmlFor={field.name} className="ml-2 block text-sm font-semibold leading-5 text-gray-900">
                        {label}
                    </label>
                )}
            </div>
        </div>
    );
};

export default InputCheckbox;
