import { Menu, Transition } from '@headlessui/react';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { tourTypeTitle } from '../../helpers/tour-type-helpers';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { tourTypeId } = useContext(TourTypeContext);

    const menuItems = [
        {
            title: `Switch (Current: ${tourTypeTitle(tourTypeId)})`,
            to: '/type-switch',
            orderNum: 1,
            subMenu: true,
        },
        {
            title: 'Home',
            to: '/',
            orderNum: 2,
        },
        {
            title: 'Seasons',
            to: '/seasons',
            orderNum: 20,
        },
        {
            title: 'Ticket Types',
            to: '/ticket-types',
            orderNum: 20,
        },
        {
            title: 'Day',
            to: '/day-availability',
            orderNum: 20,
        },
        { title: 'Search', to: '/search', orderNum: 50 },
        {
            title: 'Glide 1',
            to: '/glide1',
            orderNum: 900,
        },
        {
            title: 'Tester: Price',
            to: '/price-tester',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Tester: Availability',
            to: '/availability-tester',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Ticket Categories',
            to: '/ticket-categories',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Last Tours',
            to: '/last-tours',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Promo Codes',
            to: '/discounts',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Single Use Promo Codes',
            to: '/single-use-discounts',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Specials',
            to: '/specials',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Vouchers',
            to: '/vouchers',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Bolt-Ons',
            to: '/bolt-ons',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Bolt-Ons: Supplier Confirmed',
            to: '/bolt-ons/supplier-confirmed',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Affiliates',
            to: '/affiliates',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Reviews',
            to: '/reviews',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Reports',
            to: '/report',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Bookings',
            to: '/bookings',
            subMenu: true,
            orderNum: 20,
        },
        {
            title: 'Log out',
            to: '/logout',
            subMenu: true,
            orderNum: 999,
        },
    ];

    const sortMenu = (a, b) => {
        if (a.orderNum > b.orderNum) {
            return 1;
        }
        if (a.orderNum < b.orderNum) {
            return -1;
        }

        if (a.title > b.title) {
            return 1;
        }

        return -1;
    };

    return (
        <>
            <nav className="bg-white shadow">
                <div className="px-2 sm:px-4">
                    <div className="relative flex justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            {/* Mobile menu button */}
                            <button
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out"
                                onClick={() => setMenuOpen(!menuOpen)}
                                aria-label="Main menu"
                                aria-expanded="false">
                                {menuOpen ? (
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                ) : (
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                )}
                            </button>
                        </div>
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="hidden sm:flex w-full items-center">
                                <div className="relative inline-block text-left pt-1">
                                    <Menu>
                                        {({ open }) => (
                                            <>
                                                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none active:bg-gray-50 active:text-gray-800">
                                                    {open ? (
                                                        <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    ) : (
                                                        <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                                        </svg>
                                                    )}
                                                </Menu.Button>
                                                <Transition
                                                    show={open}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items static className="absolute left-0 w-64 mt-2 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none z-50">
                                                        {menuItems.sort(sortMenu).map((item, key) => (
                                                            <Menu.Item key={key}>
                                                                {({ active }) => (
                                                                    <Link to={item.to} className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} flex justify-between w-full px-4 py-2 leading-5 text-left focus:border-transparent`}>
                                                                        {item.title}
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>
                                {menuItems
                                    .filter((x) => !x.subMenu)
                                    .map((item, key) => (
                                        <Link key={key} to={item.to} className="ml-4 inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out">
                                            {item.title}
                                        </Link>
                                    ))}
                            </div>
                            <div className="flex-shrink-0 flex items-center">
                                <img className="block h-16 w-auto" src={logo} alt="Glide" /> <span className="sm:hidden text-2xl font-extrabold">Glide Booking</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${menuOpen ? 'block' : 'hidden'} sm:hidden`}>
                    <div className="pt-2 pb-4">
                        {menuItems.sort(sortMenu).map((item, key) => (
                            <Link
                                key={key}
                                to={item.to}
                                onClick={() => setMenuOpen(false)}
                                className="mt-1 block pl-3 pr-4 py-2 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out">
                                {item.title}
                            </Link>
                        ))}
                    </div>
                </div>
            </nav>
            <div className="pb-2 text-center bg-white font-extrabold">{tourTypeTitle(tourTypeId)}</div>
        </>
    );
};

export default Header;
