import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageTitle, Table, TableCell, TableRow } from '..';
import { apiBoatLaneIssues } from '../../../api/api-reports';
import { TourTypeContext } from '../../../contexts/tour-type-context';
import { formatDate } from '../../../helpers/helpers';

const BoatLaneIssues = () => {
    const [issues, setIssues] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        setIssues(await apiBoatLaneIssues(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    if (!issues || issues.length === 0) {
        return null;
    }

    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 my-2">
            <PageTitle title="! Lane Issues !" />
            <p className="pl-4">Different Punt Types in Lane:</p>
            <Table slim>
                <tbody>
                    {issues.map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell>
                                {formatDate(item.date)} - Lane: {item.lane}
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default BoatLaneIssues;
