import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiSingleUseDiscountGet, apiSingleUseDiscountSave } from '../../api/api-single-use-discounts';
import { apiTicketCategoriesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, minuteDefaults } from '../../helpers/constants';
import { toJSDate } from '../../helpers/helpers';
import { ButtonSubmit, FontAwesomeIcon, InputCheckbox, InputDate, InputSelect, InputText, PageTitle } from '../common';
import { editPageActions } from './page-actions';

const SingleUseDiscountEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { tourTypeId } = useContext(TourTypeContext);
    const [ticketCategories, setTicketCategories] = useState(undefined);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = Yup.object().shape({
        singleUseDiscountName: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
        amount: Yup.string()
            .matches(/^\d{1,5}(?:[.]\d{0,2})?$/, 'Invalid')
            .required('Required'),
    });

    useEffect(() => {
        const LoadData = async () => {
            setTicketCategories((await apiTicketCategoriesGet(tourTypeId)).filter((x) => !x.isDisabled));

            //Load the data
            const result = await apiSingleUseDiscountGet(tourTypeId, id);
            result.tourFrom = toJSDate(result.tourFrom);
            result.tourTo = toJSDate(result.tourTo);
            setData(result);

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);
    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Single Use Promo Code" actions={editPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        let validBefore = null;
                        if (values.validBeforeHour && values.validBeforeMinute) {
                            validBefore = `${values.validBeforeHour}:${values.validBeforeMinute}`;
                        }
                        let validAfter = null;
                        if (values.validAfterHour && values.validAfterMinute) {
                            validAfter = `${values.validAfterHour}:${values.validAfterMinute}`;
                        }

                        await apiSingleUseDiscountSave(
                            tourTypeId,
                            id,
                            values.singleUseDiscountName,
                            values.code,
                            values.batchId,
                            values.amount,
                            values.tourFrom,
                            values.tourTo,
                            values.ticketCategoryId,
                            values.monday,
                            values.tuesday,
                            values.wednesday,
                            values.thursday,
                            values.friday,
                            values.saturday,
                            values.sunday,
                            validBefore,
                            validAfter,
                            values.isDisabled,
                            values.used
                        );

                        history.push('/single-use-discounts');
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <InputText name="singleUseDiscountName" label="Description" />
                            <InputText name="code" label="Code" disabled />
                            <InputText name="batchId" label="Batch Id" disabled />
                            <InputText name="amount" label="Discount £" wrapperClassName="max-w-xxs" />
                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Tours Departing Between</div>
                            <div className="flex gap-2 items-center">
                                <InputDate name="tourFrom" />
                                <FontAwesomeIcon icon="arrow-right" />
                                <InputDate name="tourTo" />
                            </div>
                            <InputSelect name="ticketCategoryId" label="Ticket Category" items={ticketCategories} textField="ticketCategoryName" optional optionalText="Any" wrapperClassName="max-w-2xl" />
                            <div className="max-w-2xl">
                                <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Days</div>

                                <div className="grid grid-cols-4 sm:grid-cols-7 gap-4">
                                    <InputCheckbox name="monday" label="Mo" />
                                    <InputCheckbox name="tuesday" label="Tu" />
                                    <InputCheckbox name="wednesday" label="We" />
                                    <InputCheckbox name="thursday" label="Th" />
                                    <InputCheckbox name="friday" label="Fr" />
                                    <InputCheckbox name="saturday" label="Sa" />
                                    <InputCheckbox name="sunday" label="Su" />
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div className="">
                                    <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid Before</div>
                                    <div className="flex gap-2">
                                        <InputSelect name="validBeforeHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                        <InputSelect name="validBeforeMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid After</div>
                                    <div className="flex gap-2">
                                        <InputSelect name="validAfterHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                        <InputSelect name="validAfterMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                    </div>
                                </div>
                            </div>
                            <InputCheckbox name="used" label="Used" className="mt-4" />
                            <InputCheckbox name="isDisabled" label="Disabled" className="mt-4" />
                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default SingleUseDiscountEdit;
