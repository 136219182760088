import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TourTypeProvider } from '../../contexts/tour-type-context';
import AffiliateEdit from '../affiliates/affiliate-edit';
import AffiliateList from '../affiliates/affiliate-list';
import BoltOnEdit from '../bolt-ons/bolt-on-edit';
import BoltOnList from '../bolt-ons/bolt-on-list';
import BoltOnSupplierConfirmed from '../bolt-ons/bolt-on-supplier-confirmed';
import BookingList from '../bookings/booking-list';
import DayAvailability from '../day/day-availability';
import DayList from '../day/day-list';
import DiscountEdit from '../discounts/discount-edit';
import DiscountList from '../discounts/discount-list';
import Error404 from '../errors/error-404';
import Home from '../home/home';
import LastTourEdit from '../last-tours/last-tour-edit';
import LastTourList from '../last-tours/last-tour-list';
import Report from '../report/report';
import ReviewList from '../reviews/review-list';
import Search from '../search/search';
import SeasonAdd from '../seasons/season-add';
import SeasonAvailability from '../seasons/season-availability';
import SeasonDateAdd from '../seasons/season-date-add';
import SeasonDateEdit from '../seasons/season-date-edit';
import SeasonEdit from '../seasons/season-edit';
import SeasonList from '../seasons/season-list';
import SingleUseDiscountCreate from '../single-use-discounts/single-use-discount-create';
import SingleUseDiscountEdit from '../single-use-discounts/single-use-discount-edit';
import SingleUseDiscountList from '../single-use-discounts/single-use-discount-list';
import SpecialEdit from '../specials/special-edit';
import SpecialList from '../specials/special-list';
import AvailabilityTester from '../tester/availability-tester';
import PriceTester from '../tester/price-tester';
import TicketCategoryAdd from '../ticket-categories/ticket-category-add';
import TicketCategoryEdit from '../ticket-categories/ticket-category-edit';
import TicketCategoryList from '../ticket-categories/ticket-category-list';
import TicketPriceEdit from '../ticket-types/ticket-price-edit';
import TicketTypeAdd from '../ticket-types/ticket-type-add';
import TicketTypeEdit from '../ticket-types/ticket-type-edit';
import TicketTypeList from '../ticket-types/ticket-type-list';
import TypeSwitch from '../types/type-switch';
import VoucherTypeEdit from '../vouchers/voucher-type-edit';
import VoucherTypeList from '../vouchers/voucher-type-list';
import VouchersUpload from '../vouchers/vouchers-upload';
import Glide1 from './glide1';
import Header from './header';
import { ProtectedRoute } from './protected-route';

const DashboardLayout = () => {
    return (
        <>
            <TourTypeProvider>
                <Header />

                <div className="sm:px-4">
                    <Switch>
                        <ProtectedRoute path="/" exact component={Home} />

                        <ProtectedRoute path="/glide1" exact component={Glide1} />

                        <ProtectedRoute path="/affiliates" exact component={AffiliateList} />
                        <ProtectedRoute path="/affiliates/:displayType" exact component={AffiliateList} />
                        <ProtectedRoute path="/affiliate" exact component={AffiliateEdit} />
                        <ProtectedRoute path="/affiliate/:id" exact component={AffiliateEdit} />

                        <ProtectedRoute path="/bookings" exact component={BookingList} />
                        <ProtectedRoute path="/bookings/:dateString" exact component={BookingList} />

                        <ProtectedRoute path="/bolt-ons" exact component={BoltOnList} />
                        <ProtectedRoute path="/bolt-ons/supplier-confirmed" exact component={BoltOnSupplierConfirmed} />
                        <ProtectedRoute path="/bolt-ons/:displayType" exact component={BoltOnList} />
                        <ProtectedRoute path="/bolt-on" exact component={BoltOnEdit} />
                        <ProtectedRoute path="/bolt-on/:id" exact component={BoltOnEdit} />

                        <ProtectedRoute path="/day-availability" exact component={DayList} />
                        <ProtectedRoute path="/day-availability/:dateString" exact component={DayAvailability} />

                        <ProtectedRoute path="/discounts" exact component={DiscountList} />
                        <ProtectedRoute path="/discounts/:displayType" exact component={DiscountList} />
                        <ProtectedRoute path="/discount" exact component={DiscountEdit} />
                        <ProtectedRoute path="/discount/:id" exact component={DiscountEdit} />

                        <ProtectedRoute path="/single-use-discounts" exact component={SingleUseDiscountList} />
                        <ProtectedRoute path="/single-use-discounts/:displayType" exact component={SingleUseDiscountList} />
                        <ProtectedRoute path="/single-use-discount" exact component={SingleUseDiscountCreate} />
                        <ProtectedRoute path="/single-use-discount/:id" exact component={SingleUseDiscountEdit} />

                        <ProtectedRoute path="/last-tours" exact component={LastTourList} />
                        <ProtectedRoute path="/last-tour" exact component={LastTourEdit} />
                        <ProtectedRoute path="/last-tour/:id" exact component={LastTourEdit} />

                        <ProtectedRoute path="/availability-tester" exact component={AvailabilityTester} />
                        <ProtectedRoute path="/price-tester" exact component={PriceTester} />

                        <ProtectedRoute path="/report" exact component={Report} />

                        <ProtectedRoute path="/seasons/:displayType" exact component={SeasonList} />
                        <ProtectedRoute path="/seasons" exact component={SeasonList} />
                        <ProtectedRoute path="/season" exact component={SeasonAdd} />
                        <ProtectedRoute path="/season/:id" exact component={SeasonEdit} />
                        <ProtectedRoute path="/season-date" exact component={SeasonDateAdd} />
                        <ProtectedRoute path="/season-date/:id" exact component={SeasonDateEdit} />
                        <ProtectedRoute path="/season-availability/:id" exact component={SeasonAvailability} />

                        <ProtectedRoute path="/specials" exact component={SpecialList} />
                        <ProtectedRoute path="/specials/:displayType" exact component={SpecialList} />
                        <ProtectedRoute path="/special" exact component={SpecialEdit} />
                        <ProtectedRoute path="/special/:id" exact component={SpecialEdit} />

                        <ProtectedRoute path="/ticket-categories/:displayType" exact component={TicketCategoryList} />
                        <ProtectedRoute path="/ticket-categories" exact component={TicketCategoryList} />
                        <ProtectedRoute path="/ticket-types/:displayType" exact component={TicketTypeList} />
                        <ProtectedRoute path="/ticket-types" exact component={TicketTypeList} />
                        <ProtectedRoute path="/ticket-category" exact component={TicketCategoryAdd} />
                        <ProtectedRoute path="/ticket-category/:id" exact component={TicketCategoryEdit} />
                        <ProtectedRoute path="/ticket-price/:id" exact component={TicketPriceEdit} />
                        <ProtectedRoute path="/ticket-type" exact component={TicketTypeAdd} />
                        <ProtectedRoute path="/ticket-type/from/:copyId" exact component={TicketTypeEdit} />
                        <ProtectedRoute path="/ticket-type/:id" exact component={TicketTypeEdit} />

                        <ProtectedRoute path="/vouchers/:displayType" exact component={VoucherTypeList} />
                        <ProtectedRoute path="/vouchers" exact component={VoucherTypeList} />
                        <ProtectedRoute path="/voucher-type" exact component={VoucherTypeEdit} />
                        <ProtectedRoute path="/voucher-type/:id" exact component={VoucherTypeEdit} />
                        <ProtectedRoute path="/vouchers-upload/:id" exact component={VouchersUpload} />

                        <ProtectedRoute path="/reviews/:displayType" exact component={ReviewList} />
                        <ProtectedRoute path="/reviews" exact component={ReviewList} />

                        <ProtectedRoute path="/type-switch" exact component={TypeSwitch} />

                        <ProtectedRoute path="/search" exact component={Search} />

                        <Route path="/500" exact component={Error404} />

                        {/* Must be last or matches everything */}
                        <ProtectedRoute path="/:dateString/:bookingId" exact component={Home} />
                        <ProtectedRoute path="/:dateString" exact component={Home} />

                        <Route component={Error404} />
                    </Switch>
                </div>
            </TourTypeProvider>
        </>
    );
};

export default DashboardLayout;
