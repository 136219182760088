import React, { useEffect } from 'react';

const Error404 = () => {
    useEffect(() => {
        //Remove any local storage items that could be affecting us (i.e. a bad focus date)
        localStorage.removeItem('FocusDate');
        localStorage.removeItem('useCore');
        localStorage.removeItem('tour-type-id');
    }, []);

    return (
        <div className="min-h-screen-minus-toolbar bg-blue-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="card p-2 bg-white">
                    <div className="text-lg font-semibold border-b-2 border-blue-700">Error</div>
                    <p className="my-4">Something unexpected has occured. Please can you contact the office and give them as much details as possible about what you did before this happened.</p>
                    <p>Many thanks!</p>
                </div>
            </div>
        </div>
    );
};

export default Error404;
