import axios from 'axios';

export const apiAffiliatesGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Affiliates',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiAffiliateGet = async (tourTypeId, id) => {
    const result = (await apiAffiliatesGet(tourTypeId)).find((x) => x.id === parseInt(id));

    return result;
};

export const apiAffiliateSave = async (tourTypeId, id, affiliateName, affiliateEmail, affiliateEmail2, companyId, sourceId, verificationCode, base64String, isDisabled) => {
    if (!id) {
        id = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/Affiliate',
        data: {
            tourTypeId,
            id,
            affiliateName,
            affiliateEmail,
            affiliateEmail2,
            companyId,
            sourceId,
            verificationCode,
            base64String,
            isDisabled,
        },
    });
};
