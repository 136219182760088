import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiSpecialGet, apiSpecialSave } from '../../api/api-specials';
import { apiTicketTypesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, minuteDefaults } from '../../helpers/constants';
import { toJSDate } from '../../helpers/helpers';
import { ButtonSubmit, FontAwesomeIcon, InputCheckbox, InputDate, InputSelect, InputText, PageTitle } from '../common';
import { editPageActions } from './page-actions';

const SpecialEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { tourTypeId } = useContext(TourTypeContext);
    const [ticketTypes, setTicketTypes] = useState(undefined);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = () => {
        const shape = {
            specialName: Yup.string().required('Required'),
        };

        ticketTypes.forEach((ticketType) => {
            shape[`ticketType_${ticketType.id}`] = Yup.string().matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid');
        });

        return Yup.object().shape(shape);
    };

    useEffect(() => {
        const LoadData = async () => {
            const ticketTypesResult = (await apiTicketTypesGet(tourTypeId)).filter((x) => !x.isDisabled);
            setTicketTypes(ticketTypesResult);

            if (id) {
                //Load the data
                const getResult = await apiSpecialGet(tourTypeId, id);
                getResult.tourFrom = toJSDate(getResult.tourFrom);
                getResult.tourTo = toJSDate(getResult.tourTo);

                ticketTypesResult.forEach((item) => {
                    const found = getResult.specialPrices.find((x) => x.ticketTypeId === item.id);
                    getResult[`ticketType_${item.id}`] = found?.price || '';
                });

                setData(getResult);
            } else {
                const newData = {
                    specialName: '',
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    validBeforeHour: '',
                    validBeforeMinute: '',
                    validAfterHour: '',
                    validAfterMinute: '',
                    isDisabled: false,
                    advertise: false,
                    allowDiscounts: false,
                };

                ticketTypesResult.forEach((item) => {
                    newData[`ticketType_${item.id}`] = '';
                });

                //Defaults
                setData(newData);
            }

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);
    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Special" actions={editPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema()}
                    onSubmit={async (values) => {
                        let validBefore = null;
                        if (values.validBeforeHour && values.validBeforeMinute) {
                            validBefore = `${values.validBeforeHour}:${values.validBeforeMinute}`;
                        }
                        let validAfter = null;
                        if (values.validAfterHour && values.validAfterMinute) {
                            validAfter = `${values.validAfterHour}:${values.validAfterMinute}`;
                        }

                        const specialPrices = [];
                        ticketTypes.forEach((ticketType) => {
                            if (values[`ticketType_${ticketType.id}`]) {
                                specialPrices.push({ ticketTypeId: ticketType.id, price: values[`ticketType_${ticketType.id}`] });
                            }
                        });

                        await apiSpecialSave(
                            tourTypeId,
                            id,
                            values.specialName,
                            values.tourFrom,
                            values.tourTo,
                            values.monday,
                            values.tuesday,
                            values.wednesday,
                            values.thursday,
                            values.friday,
                            values.saturday,
                            values.sunday,
                            validBefore,
                            validAfter,
                            values.advertise,
                            values.allowDiscounts,
                            values.isDisabled,
                            specialPrices
                        );

                        history.push('/specials');
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <InputText name="specialName" label="Name" wrapperClassName="" />
                            <div className="flex gap-2 items-center">
                                <InputCheckbox name="advertise" label="Advertise" horizontal={false} />
                                <InputCheckbox name="allowDiscounts" label="Allow Discounts" horizontal={false} />
                            </div>
                            <div className="">
                                <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Tours Departing Between</div>
                                <div className="flex gap-2 items-center">
                                    <InputDate name="tourFrom" />
                                    <FontAwesomeIcon icon="arrow-right" />
                                    <InputDate name="tourTo" />
                                </div>
                            </div>
                            <div className="max-w-2xl">
                                <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Days</div>

                                <div className="grid grid-cols-4 sm:grid-cols-7 gap-4">
                                    <InputCheckbox name="monday" label="Mo" />
                                    <InputCheckbox name="tuesday" label="Tu" />
                                    <InputCheckbox name="wednesday" label="We" />
                                    <InputCheckbox name="thursday" label="Th" />
                                    <InputCheckbox name="friday" label="Fr" />
                                    <InputCheckbox name="saturday" label="Sa" />
                                    <InputCheckbox name="sunday" label="Su" />
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div>
                                    <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid Before</div>
                                    <div className="flex gap-2">
                                        <InputSelect name="validBeforeHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                        <InputSelect name="validBeforeMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                    </div>
                                </div>
                                <div>
                                    <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid After</div>
                                    <div className="flex gap-2">
                                        <InputSelect name="validAfterHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                        <InputSelect name="validAfterMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                    </div>
                                </div>
                            </div>

                            <div className="font-extrabold text-white bg-blue-600 mt-4 mb-2 py-2 -mx-4 px-6">Prices</div>
                            {ticketTypes.map((item, key) => (
                                <InputText key={key} name={`ticketType_${item.id}`} label={item.fullName} inputClassName="max-w-xxs" />
                            ))}

                            <div className="font-extrabold border-b-2 border-blue-600 my-4 -mx-4"></div>

                            <InputCheckbox name="isDisabled" label="Disabled" right />

                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default SpecialEdit;
