import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { apiSeasonGet, apiSeasonLimitsGet, apiSeasonLimitsSave } from '../../api/api-seasons';
import { apiPriceBandsGet, apiTicketCategoriesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, minuteDefaults } from '../../helpers/constants';
import { Button, ButtonSubmit, FontAwesomeIcon, InputSelect, InputText, PageTitle, Table, TableCell, TableRow } from '../common';
import { seasonAvailabilityPageActions } from './page-actions';

const initialiseData = {
    fromHour: 9,
    fromMinute: 45,
    toHour: 19,
    toMinute: 45,
    every: 30,
};

const SeasonAvailability = () => {
    const { tourTypeId } = useContext(TourTypeContext);
    const [loading, setLoading] = useState(true);
    const [initial, setInitial] = useState(false);
    const [season, setSeason] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [ticketCategories, setTicketCategories] = useState([]);
    const [priceBands, setPriceBands] = useState([]);
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const LoadData = async () => {
            setSeason(await apiSeasonGet(tourTypeId, id));
            setTicketCategories(await apiTicketCategoriesGet(tourTypeId));
            setPriceBands(await apiPriceBandsGet(tourTypeId, true));

            var current = await apiSeasonLimitsGet(tourTypeId, id);

            if (current.length > 0) {
                const newData = [];

                current.forEach((item) => {
                    const m = moment(item.timeSlot, 'HH:mm:ss');
                    const slot = m.format('HH:mm');

                    newData.push({
                        timeslot: slot,
                        weekdaySeats: item.weekdaySeats || 0,
                        weekdayPriceBandId: item.weekdayPriceBandId || '',
                        fridaySeats: item.fridaySeats || 0,
                        fridayPriceBandId: item.fridayPriceBandId || '',
                        saturdaySeats: item.saturdaySeats || 0,
                        saturdayPriceBandId: item.saturdayPriceBandId || '',
                        sundaySeats: item.sundaySeats || 0,
                        sundayPriceBandId: item.sundayPriceBandId || '',
                        ticketCategoryId: item.ticketCategoryId || '',
                    });
                });

                setData(newData);

                setLoading(false);
            } else {
                setInitial(true);
                setLoading(false);
            }
        };
        LoadData();
    }, [tourTypeId, id]);

    const buildData = (fromTime, toTime, every) => {
        const newData = [];

        const running = fromTime;
        const end = toTime;

        while (running <= end) {
            const slot = running.format('HH:mm');

            newData.push({
                timeslot: slot,
                weekdaySeats: 0,
                weekdayPriceBandId: '',
                fridaySeats: 0,
                fridayPriceBandId: '',
                saturdaySeats: 0,
                saturdayPriceBandId: '',
                sundaySeats: 0,
                sundayPriceBandId: '',
                ticketCategoryId: '',
            });

            if (running >= end) {
                break;
            }

            running.add(every, 'minutes');
        }

        setData(newData);
    };

    const findInsertIndex = (data, timeSlot) => {
        for (let i = 0; i < data.length; i++) {
            if (timeSlot <= data[i].timeslot) {
                return i;
            }
        }
        return data.length;
    };

    const schema = Yup.object().shape({
        data: Yup.array().of(
            Yup.object().shape({
                weekdaySeats: Yup.string()
                    .required('Required')
                    .matches(/^[0-9]{1,3}$/, 'Invalid'),
                weekdayPriceBandId: Yup.string().when('weekdaySeats', { is: (value) => /^[0-9]{1,3}$/.test(value) && value !== '0', then: Yup.string().required('Required'), otherwise: Yup.string() }),
                fridaySeats: Yup.string()
                    .required('Required')
                    .matches(/^[0-9]{1,3}$/, 'Invalid'),
                fridayPriceBandId: Yup.string().when('fridaySeats', { is: (value) => /^[0-9]{1,3}$/.test(value) && value !== '0', then: Yup.string().required('Required'), otherwise: Yup.string() }),
                saturdaySeats: Yup.string()
                    .required('Required')
                    .matches(/^[0-9]{1,3}$/, 'Invalid'),
                saturdayPriceBandId: Yup.string().when('saturdaySeats', { is: (value) => /^[0-9]{1,3}$/.test(value) && value !== '0', then: Yup.string().required('Required'), otherwise: Yup.string() }),
                sundaySeats: Yup.string()
                    .required('Required')
                    .matches(/^[0-9]{1,3}$/, 'Invalid'),
                sundayPriceBandId: Yup.string().when('sundaySeats', { is: (value) => /^[0-9]{1,3}$/.test(value) && value !== '0', then: Yup.string().required('Required'), otherwise: Yup.string() }),
            })
        ),
    });

    return (
        <div className="my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4">
            <PageTitle title={`Season Availability: ${season ? season.seasonName : ''}`} actions={seasonAvailabilityPageActions} />

            {!loading && (
                <>
                    {initial && (
                        <div className="p-2">
                            <Formik
                                initialValues={initialiseData}
                                onSubmit={async (values) => {
                                    const start = moment(`${values.fromHour}:${values.fromMinute}`, 'HH:mm');
                                    const end = moment(`${values.toHour}:${values.toMinute}`, 'HH:mm');

                                    buildData(start, end, values.every);
                                    setInitial(false);
                                }}>
                                {() => (
                                    <>
                                        <span className="text-xl font-semibold self-center">Times:</span>
                                        <Form className="sm:flex flex-wrap justify-between px-2">
                                            <span className="grid grid-cols-5 gap-x-2 mb-2">
                                                <span className="self-center">From:</span>
                                                <InputSelect wrapperClassName="col-span-2" name="fromHour" items={hourDefaults} valueField="hour" textField="hour" />
                                                <InputSelect wrapperClassName="col-span-2" name="fromMinute" items={minuteDefaults} valueField="minute" textField="minute" />
                                            </span>
                                            <span className="grid grid-cols-5 gap-x-2 mb-2">
                                                <span className="self-center">To:</span>
                                                <InputSelect wrapperClassName="col-span-2" name="toHour" items={hourDefaults} valueField="hour" textField="hour" />
                                                <InputSelect wrapperClassName="col-span-2" name="toMinute" items={minuteDefaults} valueField="minute" textField="minute" />
                                            </span>
                                            <span className="grid grid-cols-5 gap-x-2 mb-2">
                                                <span className="self-center">Every:</span>
                                                <InputSelect
                                                    wrapperClassName="col-span-4"
                                                    name="every"
                                                    items={[
                                                        { id: 30, name: 'Every 30 minutes' },
                                                        { id: 60, name: 'Every hour' },
                                                    ]}
                                                />
                                            </span>
                                            <ButtonSubmit label="Initialise" />
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    )}

                    {data && (
                        <>
                            <Formik
                                initialValues={{ data, addHour: 7, addMinute: 45 }}
                                validationSchema={schema}
                                validateOnBlur
                                validateOnChange={false}
                                enableReinitialize
                                onSubmit={async (values) => {
                                    //Special "complex" validation
                                    // - Must not have 2 timeslots with the same ticketCategoryId
                                    const groupings = [];
                                    const issues = [];
                                    values.data.forEach((item) => {
                                        const key = `${item.timeslot}:${item.ticketCategoryId}`;

                                        if (groupings.includes(key)) {
                                            if (!issues.includes(item.timeslot)) {
                                                issues.push(item.timeslot);
                                            }
                                        } else {
                                            groupings.push(key);
                                        }
                                    });

                                    if (issues.length > 0) {
                                        swal({
                                            title: 'Validation Error',
                                            text: `Duplicate Time/Limit To Found: ${issues.join(', ')}`,
                                            icon: 'error',
                                        });
                                        return;
                                    }

                                    await apiSeasonLimitsSave(tourTypeId, id, values.data);

                                    history.push('/');
                                }}>
                                {({ values }) => (
                                    <Form>
                                        <FieldArray
                                            name="data"
                                            render={(arrayHelpers) => (
                                                <>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <TableCell text="Time" header />
                                                                <TableCell header>
                                                                    Weekday <div className="text-xs font-light hidden md:block">(Seats / Price Band)</div>
                                                                </TableCell>
                                                                <TableCell header>
                                                                    Friday <div className="text-xs font-light hidden md:block">(Seats / Price Band)</div>
                                                                </TableCell>
                                                                <TableCell header>
                                                                    Saturday <div className="text-xs font-light hidden md:block">(Seats / Price Band)</div>
                                                                </TableCell>
                                                                <TableCell header>
                                                                    Sunday <div className="text-xs hidden font-light md:block">(Seats / Price Band)</div>
                                                                </TableCell>
                                                                <TableCell text="Limit To" header />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.data.map((data, key) => (
                                                                <TableRow key={key} rowNum={key}>
                                                                    <TableCell center>
                                                                        <span className="font-mono block md:inline">{data.timeslot}</span>
                                                                        <FontAwesomeIcon icon="trash-alt" className="md:ml-2 text-red-600 text-sm" onClick={() => arrayHelpers.remove(key)} />
                                                                    </TableCell>
                                                                    <TableCell center>
                                                                        <div className="md:flex items-start gap-1 -my-3 -mx-3">
                                                                            <InputText name={`data.${key}.weekdaySeats`} wrapperClassName="md:w-16" />
                                                                            <InputSelect name={`data.${key}.weekdayPriceBandId`} items={priceBands} textField="priceBandName" optional wrapperClassName="flex-auto" />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell center>
                                                                        <div className="md:flex items-start gap-1 -my-3 -mx-3">
                                                                            <InputText name={`data.${key}.fridaySeats`} wrapperClassName="md:w-16" />
                                                                            <InputSelect name={`data.${key}.fridayPriceBandId`} items={priceBands} textField="priceBandName" optional wrapperClassName="flex-auto" />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell center>
                                                                        <div className="md:flex items-start gap-1 -my-3 -mx-3">
                                                                            <InputText name={`data.${key}.saturdaySeats`} wrapperClassName="md:w-16" />
                                                                            <InputSelect name={`data.${key}.saturdayPriceBandId`} items={priceBands} textField="priceBandName" optional wrapperClassName="flex-auto" />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell center>
                                                                        <div className="md:flex items-start gap-1 -my-3 -mx-3">
                                                                            <InputText name={`data.${key}.sundaySeats`} wrapperClassName="md:w-16" />
                                                                            <InputSelect name={`data.${key}.sundayPriceBandId`} items={priceBands} textField="priceBandName" optional wrapperClassName="flex-auto" />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell center>
                                                                        <InputSelect name={`data.${key}.ticketCategoryId`} items={ticketCategories} textField="ticketCategoryName" optional wrapperClassName="-my-3 -mx-3" />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <div className="py-2 mb-4">
                                                        <div className="sm:flex px-2 gap-2">
                                                            <span className="self-center whitespace-no-wrap sm:ml-auto">Add Slot:</span>
                                                            <InputSelect name="addHour" items={hourDefaults} valueField="hour" textField="hour" />
                                                            <InputSelect name="addMinute" items={minuteDefaults} valueField="minute" textField="minute" />
                                                            <span>
                                                                <Button
                                                                    label="Add"
                                                                    onClick={() => {
                                                                        const timeSlot = moment(`${values.addHour}:${values.addMinute}`, 'HH:mm').format('HH:mm');

                                                                        arrayHelpers.insert(findInsertIndex(values.data, timeSlot), {
                                                                            timeslot: timeSlot,
                                                                            weekdaySeats: 0,
                                                                            weekdayPriceBandId: '',
                                                                            fridaySeats: 0,
                                                                            fridayPriceBandId: '',
                                                                            saturdaySeats: 0,
                                                                            saturdayPriceBandId: '',
                                                                            sundaySeats: 0,
                                                                            sundayPriceBandId: '',
                                                                            ticketCategoryId: '',
                                                                        });
                                                                    }}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                        <ButtonSubmit />
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default SeasonAvailability;
