import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../common';
import moment from 'moment';

const DayList = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date());
    const history = useHistory();

    useEffect(() => {
        const focusDate = localStorage.getItem('FocusDate');
        if (focusDate) {
            setDate(new Date(focusDate));
        }
        setLoading(false);
    }, []);

    const handleChange = (e) => {
        const m = moment(e);
        history.push(`/day-availability/${m.format('YYYY-MM-DD')}`);
    };

    return (
        <>
            {!loading && (
                <div className="max-w-screen-md my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
                    <PageTitle title="Day Availability" />
                    <Calendar onChange={handleChange} minDate={new Date()} value={date} locale="en-GB" className="inline" />
                </div>
            )}
        </>
    );
};

export default DayList;
