import React from 'react';

const TableRow = ({ rowNum, children, ...props }) => {
    return (
        <tr className={rowNum % 2 === 0 ? 'bg-white' : 'bg-gray-50'} {...props}>
            {children}
        </tr>
    );
};

export default TableRow;
