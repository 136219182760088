import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiVoucherTypeGet, apiVoucherTypeSave } from '../../api/api-vouchers';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, InputCheckbox, InputText, PageTitle } from '../common';
import { editPageActions } from './page-actions';

const VoucherTypeEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = Yup.object().shape({
        voucherTypeName: Yup.string().required('Required'),
        warnBelow: Yup.number().typeError('Invalid').integer('Invalid').required('Required'),
    });

    useEffect(() => {
        const LoadData = async () => {
            if (id) {
                setData(await apiVoucherTypeGet(tourTypeId, id));
            } else {
                setData({
                    voucherTypeName: '',
                    warnBelow: 100,
                    isDisabled: false,
                });
            }

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);
    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Voucher Type" actions={editPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        await apiVoucherTypeSave(tourTypeId, id, values.voucherTypeName, values.warnBelow, values.isDisabled);

                        history.push('/vouchers');
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <InputText name="voucherTypeName" label="Name" wrapperClassName="" />
                            <InputText name="warnBelow" label="Warn if below" wrapperClassName="" />

                            <InputCheckbox name="isDisabled" label="Disabled" right />

                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default VoucherTypeEdit;
