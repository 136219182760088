/**********************************
 * Seasons
 **********************************/
const seasonsDefaultPageActions = [
    {
        title: 'Add New',
        to: '/season',
    },
];

export const seasonsPageActions = (displayType, anyDisabled) => {
    switch (displayType) {
        case 'disabled':
            return [
                {
                    title: 'Show Active',
                    to: '/seasons',
                },
                ...seasonsDefaultPageActions,
            ];
        case 'active':
        default:
            if (anyDisabled) {
                return [
                    {
                        title: 'Show Disabled',
                        to: '/seasons/disabled',
                    },
                    ...seasonsDefaultPageActions,
                ];
            } else {
                return seasonsDefaultPageActions;
            }
    }
};

export const seasonEditPageActions = [
    {
        title: 'Back',
        to: '/seasons',
    },
];

/**********************************
 * Season Dates
 **********************************/
export const seasonDatesPageActions = [
    {
        title: 'Add New',
        to: '/season-date',
    },
];

export const seasonDateEditPageActions = [
    {
        title: 'Back',
        to: '/seasons',
    },
];

/**********************************
 * Season Availability
 **********************************/
export const seasonAvailabilityPageActions = [
    {
        title: 'Back',
        to: '/seasons',
    },
];
