const listDefaultPageActions = [
    {
        title: 'Add New',
        to: '/single-use-discount',
    },
];

export const listPageActions = (displayType, anyDisabled, anyUsed) => {
    const actions = [];

    switch (displayType) {
        case 'disabled':
        case 'used':
            actions.push({
                title: 'Show Active',
                to: '/single-use-discounts',
            });
            break;
        case 'active':
        default:
            if (anyDisabled) {
                actions.push({
                    title: 'Show Disabled',
                    to: '/single-use-discounts/disabled',
                });
            }
            if (anyUsed) {
                actions.push({
                    title: 'Show Used',
                    to: '/single-use-discounts/used',
                });
            }
            break;
    }

    actions.push(...listDefaultPageActions);

    return actions;
};

export const editPageActions = [
    {
        title: 'Back',
        to: '/single-use-discounts',
    },
];
