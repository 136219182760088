import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureAxios } from './api/axios-helper';
import Layout from './components/layout/layout';

configureAxios();

function App() {
    return (
        <Router>
            <Layout />
        </Router>
    );
}

export default App;
