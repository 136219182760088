import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { apiTourConfigurationsGet } from '../../api/api-seasons';
import { apiTicketCategorySave } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { tourTypes } from '../../helpers/constants';
import { ButtonSubmit, ErrorDisplay, InputCheckbox, InputSelect, InputSpinner, InputText, PageTitle } from '../common';
import { ticketCategoryEditPageActions } from './page-actions';

const data = {
    ticketCategoryName: '',
    maxTicketsPerBooking: 1,
    isDisabled: false,
    allowSplitAcrossTours: false,
    specifiedSlotsOnly: false,
    covidSeating: false,
    preferredTourConfigurationId: '',
    emailType: '',
};

const TicketCategoryAdd = () => {
    const [tourType, setTourType] = useState();
    const [tourConfigurations, setTourConfigurations] = useState([]);
    const [error, setError] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();

    useEffect(() => {
        const LoadData = async () => {
            setTourType(tourTypes.find((x) => x.id === tourTypeId));
            setTourConfigurations(await apiTourConfigurationsGet(tourTypeId));
        };

        LoadData();
    }, [tourTypeId]);

    const formSchema = () => {
        if (tourConfigurations.length > 0) {
            return Yup.object().shape({
                ticketCategoryName: Yup.string().required('Required'),
                emailType: Yup.string().required('Required'),
                preferredTourConfigurationId: Yup.number().required('Required'),
            });
        }
        return Yup.object().shape({
            ticketCategoryName: Yup.string().required('Required'),
            emailType: Yup.string().required('Required'),
        });
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="New Ticket Category" actions={ticketCategoryEditPageActions} />

            <Formik
                initialValues={data}
                validationSchema={formSchema()}
                onSubmit={async (values) => {
                    setError(undefined);

                    const result = await apiTicketCategorySave(
                        tourTypeId,
                        null,
                        values.ticketCategoryName,
                        values.maxTicketsPerBooking,
                        values.preferredTourConfigurationId,
                        values.allowSplitAcrossTours,
                        values.specifiedSlotsOnly,
                        values.covidSeating,
                        values.isDisabled,
                        values.isPrivate,
                        values.emailType
                    );

                    if (result.success) {
                        history.push('/ticket-categories');
                    } else {
                        setError(result.exception);
                    }
                }}>
                {({ isSubmitting }) => (
                    <Form className="px-2 sm:px-0">
                        <div className="grid grid-cols-1 gap-y-2">
                            <InputText name="ticketCategoryName" label="Name" />
                            <InputSpinner name="maxTicketsPerBooking" min={1} max={tourType?.maxTicketsPerBookingMax || 12} label="Max Tickets Per Booking" />
                            <InputCheckbox name="allowSplitAcrossTours" label="Booking can be split over multiple Tour Groups" />
                            <InputCheckbox name="specifiedSlotsOnly" label="Specified Timeslots Only" />
                            <InputCheckbox name="covidSeating" label="COVID Seating Rules" />
                            {tourConfigurations.length > 0 && <InputSelect name="preferredTourConfigurationId" items={tourConfigurations} textField="tourConfigurationName" optional label="Preferred Tour Configuration" />}
                            <InputText name="emailType" label="Email Template" maxLength={20} />
                            <InputCheckbox name="isDisabled" label="Disabled" />
                        </div>
                        <ButtonSubmit isSubmitting={isSubmitting} className="mt-2" />
                        <ErrorDisplay error={error} />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TicketCategoryAdd;
