import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiTicketCategoriesGet, apiTicketTypesGet } from '../../api/api-ticket-types';
import { apiVoucherTypesGet } from '../../api/api-vouchers';
import { apiBoltOnGet, apiBoltOnSave } from '../../api/api-bolt-ons';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, minuteDefaults, showInUpcomingOptions } from '../../helpers/constants';
import { ButtonSubmit, ErrorDisplay, FontAwesomeIcon, InputCheckbox, InputDate, InputSelect, InputSpinner, InputText, PageTitle, Table, TableCell, TableRow, TextArea } from '../common';
import { editPageActions } from './page-actions';
import { companyList } from '../../helpers/constants';
import { toJSDate } from '../../helpers/helpers';
import DatePicker from 'react-date-picker';
import moment from 'moment';

const BoltOnEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [error, setError] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);
    const [ticketCategories, setTicketCategories] = useState(undefined);
    const [ticketTypes, setTicketTypes] = useState(undefined);
    const [voucherTypes, setVoucherTypes] = useState(undefined);
    const [excludedDates, setExcludedDates] = useState([]);
    const [addExcludedFrom, setAddExcludedFrom] = useState();
    const [addExcludedTo, setAddExcludedTo] = useState();
    const history = useHistory();
    const { id } = useParams();

    const formSchema = Yup.object().shape({
        boltOnName: Yup.string().required('Required'),
        price: Yup.string()
            .matches(/^\d{1,5}(?:[.]\d{0,2})?$/, 'Invalid')
            .required('Required'),
        noticeRequiredHours: Yup.number().typeError('Must be a number').required('Required').positive('Must be a positive number').integer('Must be a whole number'),
        emailSnippet: Yup.string().when('voucherTypeId', { is: (x) => x, then: (s) => s.required('Required') }),
    });

    useEffect(() => {
        const LoadData = async () => {
            const ticketTypesResult = (await apiTicketTypesGet(tourTypeId)).filter((x) => !x.isDisabled);
            setTicketCategories((await apiTicketCategoriesGet(tourTypeId)).filter((x) => !x.isDisabled));
            setTicketTypes(ticketTypesResult);
            setVoucherTypes((await apiVoucherTypesGet(tourTypeId)).filter((x) => !x.isDisabled));

            if (id) {
                //Load the data
                const getResult = await apiBoltOnGet(tourTypeId, id);
                getResult.tourFrom = toJSDate(getResult.tourFrom);
                getResult.tourTo = toJSDate(getResult.tourTo);
                getResult.description = getResult.description || '';
                getResult.emailSnippet = getResult.emailSnippet || '';
                getResult.voucherTypeId = getResult.voucherTypeId || '';
                getResult.question = getResult.question || '';
                getResult.questionMandatory = getResult.questionMandatory || false;
                getResult.minAllowed = getResult.minAllowed || 1;

                ticketTypesResult.forEach((item) => {
                    getResult[`ticketType_${item.id}`] = getResult.ticketTypeIds.includes(item.id);
                });

                setExcludedDates(getResult.excludedDates);

                setData(getResult);
            } else {
                const newData = {
                    companyId: '',
                    ticketCategoryId: '',
                    ticketTypeId: '',
                    boltOnName: '',
                    description: '',
                    emailSnippet: '',
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                    validBeforeHour: '',
                    validBeforeMinute: '',
                    validAfterHour: '',
                    validAfterMinute: '',
                    isDisabled: false,
                    advertise: false,
                    tourFrom: null,
                    tourTo: null,
                    price: '',
                    noticeRequiredHours: '48',
                    maxAllowed: 12,
                    minAllowed: 1,
                    limitToGroupSize: true,
                    voucherTypeId: '',
                    question: '',
                    questionMandatory: false,
                    showInUpcoming: 'A',
                    picnic: false,
                    supplierConfirm: false,
                    alcohol: false,
                    booklet: false,
                };

                ticketTypesResult.forEach((item) => {
                    newData[`ticketType_${item.id}`] = false;
                });

                setData(newData);
            }

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);

    const addExcludedDates = () => {
        if (addExcludedFrom && addExcludedTo) {
            if (addExcludedFrom.getTime() <= addExcludedTo.getTime()) {
                setExcludedDates([...excludedDates, { fromDate: addExcludedFrom, toDate: addExcludedTo }]);
                setAddExcludedFrom();
                setAddExcludedTo();
            }
            return;
        }
        if (addExcludedFrom && !addExcludedTo) {
            setExcludedDates([...excludedDates, { fromDate: addExcludedFrom, toDate: addExcludedFrom }]);
            setAddExcludedFrom();
            setAddExcludedTo();
        }
        if (!addExcludedFrom && addExcludedTo) {
            setExcludedDates([...excludedDates, { fromDate: addExcludedTo, toDate: addExcludedTo }]);
            setAddExcludedFrom();
            setAddExcludedTo();
        }
    };

    const removeExcludedDates = (index) => {
        const temp = [...excludedDates];
        temp.splice(index, 1);
        setExcludedDates(temp);
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Bolt-On" actions={editPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        setError(undefined);

                        let validBefore = null;
                        if (values.validBeforeHour && values.validBeforeMinute) {
                            validBefore = `${values.validBeforeHour}:${values.validBeforeMinute}`;
                        }
                        let validAfter = null;
                        if (values.validAfterHour && values.validAfterMinute) {
                            validAfter = `${values.validAfterHour}:${values.validAfterMinute}`;
                        }

                        const ticketTypeIds = [];
                        ticketTypes.forEach((ticketType) => {
                            if (values[`ticketType_${ticketType.id}`]) {
                                ticketTypeIds.push(ticketType.id);
                            }
                        });

                        const result = await apiBoltOnSave(
                            tourTypeId,
                            id,
                            values.companyId,
                            values.ticketCategoryId,
                            values.ticketTypeId,
                            values.boltOnName,
                            values.tourFrom,
                            values.tourTo,
                            values.monday,
                            values.tuesday,
                            values.wednesday,
                            values.thursday,
                            values.friday,
                            values.saturday,
                            values.sunday,
                            validBefore,
                            validAfter,
                            values.advertise,
                            values.price,
                            values.noticeRequiredHours,
                            values.maxAllowed,
                            values.minAllowed,
                            values.limitToGroupSize,
                            values.voucherTypeId,
                            values.isDisabled,
                            values.description,
                            values.emailSnippet,
                            values.question,
                            values.questionMandatory,
                            values.showInUpcoming,
                            values.picnic,
                            values.supplierConfirm,
                            ticketTypeIds,
                            excludedDates,
                            values.alcohol,
                            values.booklet
                        );

                        if (result.success) {
                            history.push('/bolt-ons');
                        } else {
                            setError(result.exception);
                        }
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <div className="md:grid md:grid-cols-2 md:gap-x-6">
                                <div>
                                    <InputText name="boltOnName" label="Name" maxLength="100" wrapperClassName="" />
                                    <TextArea name="description" rows="3" label="Helper Text / Description (links: #https://www.letsgopunting.co.uk/#)" />
                                    <InputText name="emailSnippet" label="Email Snippet Name" maxLength={20} />
                                    <div className="flex justify-between">
                                        <InputCheckbox name="advertise" label="Advertise" horizontal={false} center />
                                        <InputCheckbox name="picnic" label="Picnic" horizontal={false} center />
                                        <InputCheckbox name="alcohol" label="Alcohol" horizontal={false} center />
                                        <InputCheckbox name="booklet" label="Booklet" horizontal={false} center />
                                        <InputCheckbox name="supplierConfirm" label="Supplier Confirm" horizontal={false} center />
                                    </div>
                                    <InputSelect name="showInUpcoming" label="Show In Upcoming" items={showInUpcomingOptions} optional />
                                    <div className="">
                                        <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Tours Departing Between</div>
                                        <div className="flex gap-2 items-center">
                                            <InputDate name="tourFrom" />
                                            <FontAwesomeIcon icon="arrow-right" />
                                            <InputDate name="tourTo" />
                                        </div>
                                    </div>
                                    <div className="font-extrabold text-white bg-blue-600 mt-4 mb-2 py-2 -mx-4 md:mx-0 px-6">Excluded Dates</div>
                                    <div className="flex justify-between items-center px-4">
                                        <div className="flex gap-x-2 items-center">
                                            <div className="card bg-white shadow-sm border">
                                                <DatePicker name="addExcludedFrom" id="addExcludedFrom" onChange={(e) => setAddExcludedFrom(e)} value={addExcludedFrom} locale="en-GB" className="inline" calendarIcon={null} />
                                            </div>
                                            <FontAwesomeIcon icon="arrow-right" />
                                            <div className="card bg-white shadow-sm border">
                                                <DatePicker name="addExcludedTo" id="addExcludedTo" onChange={(e) => setAddExcludedTo(e)} value={addExcludedTo} locale="en-GB" className="inline" calendarIcon={null} />
                                            </div>
                                        </div>
                                        <span className="text-white bg-blue-600 py-0.5 px-3 cursor-pointer rounded-md" onClick={addExcludedDates}>
                                            +
                                        </span>
                                    </div>
                                    <Table slim>
                                        <tbody>
                                            {excludedDates.map((item, key) => (
                                                <TableRow key={key} rowNum={key}>
                                                    <TableCell>{moment(item.fromDate).format('ddd Do MMM yyyy')}</TableCell>
                                                    <TableCell>
                                                        <FontAwesomeIcon icon="arrow-right" />
                                                    </TableCell>
                                                    <TableCell>{moment(item.toDate).format('ddd Do MMM yyyy')}</TableCell>
                                                    <TableCell right>
                                                        <span className="text-white bg-blue-600 -my-1 py-1 px-3 cursor-pointer rounded-md" onClick={() => removeExcludedDates(key)}>
                                                            X
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <InputSelect name="companyId" label="Company" items={companyList(tourTypeId)} optional />
                                    <div className="max-w-2xl">
                                        <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Days</div>

                                        <div className="grid grid-cols-4 sm:grid-cols-7 gap-4">
                                            <InputCheckbox name="monday" label="Mo" />
                                            <InputCheckbox name="tuesday" label="Tu" />
                                            <InputCheckbox name="wednesday" label="We" />
                                            <InputCheckbox name="thursday" label="Th" />
                                            <InputCheckbox name="friday" label="Fr" />
                                            <InputCheckbox name="saturday" label="Sa" />
                                            <InputCheckbox name="sunday" label="Su" />
                                        </div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid After</div>
                                            <div className="flex gap-2">
                                                <InputSelect name="validAfterHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                                <InputSelect name="validAfterMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid Before</div>
                                            <div className="flex gap-2">
                                                <InputSelect name="validBeforeHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                                <InputSelect name="validBeforeMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                            </div>
                                        </div>
                                    </div>
                                    <InputText name="price" label="Price Each (£)" wrapperClassName="max-w-xs" />
                                    <InputText name="noticeRequiredHours" label="Notice Required (hours)" wrapperClassName="max-w-xs" />

                                    <div className="flex gap-2 items-center">
                                        <InputText name="question" label="Question" maxLength={100} wrapperClassName="flex-1" />
                                        <InputCheckbox name="questionMandatory" label="Mandatory" center horizontal={false} />
                                    </div>

                                    <div className="flex gap-2 items-center">
                                        <InputSpinner name="minAllowed" label="Min Bookable" min={1} max={100} center />
                                        <InputSpinner name="maxAllowed" label="Max Bookable" min={1} max={100} center />
                                        <InputCheckbox name="limitToGroupSize" label="Limit To Group Size" horizontal={false} center />
                                    </div>
                                    <InputSelect name="voucherTypeId" label="Voucher Type" items={voucherTypes} textField="voucherTypeName" optional wrapperClassName="max-w-2xl" />
                                </div>
                                <div>
                                    <InputSelect name="ticketCategoryId" label="Ticket Category" items={ticketCategories} textField="ticketCategoryName" optional optionalText="Any" wrapperClassName="max-w-2xl" />

                                    <div className="font-extrabold text-white bg-blue-600 mt-4 mb-2 py-2 -mx-4 md:mx-0 px-6">Ticket Types</div>
                                    {ticketTypes.map((item, key) => (
                                        <InputCheckbox key={key} name={`ticketType_${item.id}`} label={item.fullName} right />
                                    ))}
                                </div>
                            </div>

                            <div className="font-extrabold border-b-2 border-blue-600 my-4 -mx-4"></div>

                            <InputCheckbox name="isDisabled" label="Disabled" right />

                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                            <ErrorDisplay error={error} />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default BoltOnEdit;
