import Cookies from 'js-cookie';
import moment from 'moment';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { authExpiryCookieName } from '../../config';

export const ProtectedRoute = ({ ...props }) => {
    const now = moment();
    const date = Cookies.get(authExpiryCookieName());
    const expiry = moment(date);

    var allowed = date && expiry.isAfter(now);

    return allowed ? <Route {...props} /> : <Redirect to="/login" />;
};
