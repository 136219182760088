import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiTicketCategoriesGet, apiTicketTypesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';
import Issues from '../common/issues/issues';
import { ticketTypesPageActions } from './page-actions';

const TicketTypeList = () => {
    const [ticketTypes, setTicketTypes] = useState([]);
    const [ticketCategories, setTicketCategories] = useState([]);
    const { displayType } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const [anyDisabledTicketTypes, setAnyDisabledTicketTypes] = useState(false);

    const LoadData = useCallback(async () => {
        setTicketCategories(await apiTicketCategoriesGet(tourTypeId));
        setTicketTypes(await apiTicketTypesGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        setAnyDisabledTicketTypes(ticketTypes.filter((x) => x.isDisabled).length > 0);
    }, [ticketTypes]);

    const ticketTypesToDisplay = () => {
        if (displayType === 'disabled') {
            return ticketTypes.filter((x) => x.isDisabled || x.ticketCategoryIsDisabled);
        } else {
            return ticketTypes.filter((x) => !x.isDisabled && !x.ticketCategoryIsDisabled);
        }
    };

    const ticketCategoryName = (id) => {
        return ticketCategories.find((x) => x.id === id)?.ticketCategoryName || '???';
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <Issues />
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Ticket Types" actions={ticketTypesPageActions(displayType, anyDisabledTicketTypes)} />
                <Table>
                    <thead>
                        <tr>
                            <TableCell text="Category" header />
                            <TableCell text="Affiliate" className="hidden sm:table-cell" header />
                            <TableCell text="Type" header />
                            <TableCell text="Group" className="hidden md:table-cell" header />
                            <TableCell text="Seats" className="hidden md:table-cell" header />
                            <TableCell className="hidden md:table-cell" header>
                                Duration
                                <div className="text-xxs">(mins)</div>
                            </TableCell>
                            <TableCell className="hidden md:table-cell" right header>
                                From
                                <div className="text-xxs">(As At Today)</div>
                            </TableCell>
                            <TableCell className="hidden md:table-cell" header></TableCell>
                            <TableCell text="" header />
                            <TableCell text="" header />
                        </tr>
                    </thead>

                    <tbody>
                        {ticketTypesToDisplay().map((item, key) => (
                            <TableRow key={key} rowNum={key}>
                                <TableCell>
                                    {ticketCategoryName(item.ticketCategoryId)}
                                    {item.ticketCategoryIsDisabled ? ' (Disabled)' : ''}
                                    <div className="text-xs">{item.companyDisplay}</div>
                                    <div className="sm:hidden">{item.affiliateName}</div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell whitespace-normal">{item.affiliateName}</TableCell>
                                <TableCell className="whitespace-normal">
                                    {item.ticketTypeName}
                                    {item.isDisabled ? ' (Disabled)' : ''}
                                    {tourTypeId === 3 && !item.linkedTicketId && <div className="text-red-600">Missing Glide Linked Ticket</div>}
                                    {item.validDisplay && <div>{item.validDisplay}</div>}
                                    {item.excludeDisplay && <div>Exclude: {item.excludeDisplay}</div>}
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                    <FontAwesomeIcon icon="user-friends" className="mr-1" />
                                    {item.groupSize}
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                    <FontAwesomeIcon icon="chair" className="mr-1" />
                                    {item.seatsRequired}
                                </TableCell>
                                <TableCell className="hidden md:table-cell">
                                    <FontAwesomeIcon icon="clock" className="mr-1" />
                                    {item.duration}
                                </TableCell>
                                <TableCell className="hidden md:table-cell" right>
                                    {item.masterId && <FontAwesomeIcon icon="link" title={`${item.masterName} (Discount ${item.masterDiscount}%)`} />}
                                    {!item.masterId && item.fromPrice && <>£{item.fromPrice.toFixed(2)}</>}
                                </TableCell>
                                <TableCell actionLeft>
                                    <Link to={`/ticket-type/${item.id}`}>
                                        <FontAwesomeIcon icon="edit" title="Edit" />
                                    </Link>
                                </TableCell>
                                <TableCell actionMiddle>
                                    {!item.masterId && (
                                        <Link to={`/ticket-type/from/${item.id}`}>
                                            <FontAwesomeIcon icon="copy" title="Copy" />
                                        </Link>
                                    )}
                                </TableCell>
                                <TableCell actionRight>
                                    <Link to={`/ticket-price/${item.id}`}>
                                        <FontAwesomeIcon icon="pound-sign" title="Pricing" />
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default TicketTypeList;
