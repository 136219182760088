import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import { ButtonSubmit, InputText } from '../common';
import { apiLogin } from '../../api/api-auth';
import { useHistory } from 'react-router-dom';

const formSchema = Yup.object().shape({
    email: Yup.string().email('Invalid').required('Required'),
    password: Yup.string().required('Required'),
});

const data = {
    email: '',
    password: '',
};

const Login = () => {
    const [error, setError] = useState(false);
    const history = useHistory();

    return (
        <div className="min-h-screen bg-blue-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center items-end">
                    <img className="h-12 w-auto inline-block -mt-4" src={logo} alt="Glide" />
                    <span className="text-blue-500 font-extrabold text-3xl inline-block">glide</span>
                </div>
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <Formik
                        initialValues={data}
                        validationSchema={formSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            setError(false);
                            if (await apiLogin(values.email, values.password)) {
                                history.push('/');
                                return;
                            }
                            setError(true);
                            setSubmitting(false);
                        }}>
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="font-semibold text-gray-700">Log in</div>
                                <div className="grid gap-y-2">
                                    <InputText name="email" type="email" label="Email address" />
                                    <InputText name="password" type="password" label="Password" />
                                    {error && <div className="validation-error ">Login Failed</div>}
                                </div>
                                <ButtonSubmit label="Log in" isSubmitting={isSubmitting} className="mt-8" />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Login;
