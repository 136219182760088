import { useField } from 'formik';
import React from 'react';
import DatePicker from 'react-date-picker';

const clearIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19" stroke="black" strokeWidth={2} className="react-date-picker}__clear-button__icon react-date-picker__button__icon">
        <line x1="4" x2="15" y1="4" y2="15" />
        <line x1="15" x2="4" y1="4" y2="15" />
    </svg>
);

const InputDate = ({ onChange, showClear = true, ...props }) => {
    const [field, meta, helper] = useField(props);

    const handleChange = (e) => {
        helper.setValue(e);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <div className="card bg-white shadow-sm border">
                <DatePicker name={field.name} id={field.name} onChange={handleChange} value={field.value} locale="en-GB" className="inline" calendarIcon={null} clearIcon={showClear ? clearIcon : null} />
            </div>
            {meta.error && <div className="validation-error">{meta.error}</div>}
        </>
    );
};

export default InputDate;
