import React from 'react';
import Alerts from './alerts';
import BoatLaneIssues from './boat-lane-issues';
import TicketPriceIssues from './ticket-price-issues';
import VoucherIssues from './voucher-issues';

const Issues = () => {
    return (
        <>
            <Alerts />
            <TicketPriceIssues />
            <BoatLaneIssues />
            <VoucherIssues />
        </>
    );
};

export default Issues;
