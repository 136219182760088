import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiVouchersSave } from '../../api/api-vouchers';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, PageTitle, TextArea } from '../common';
import { editPageActions } from './page-actions';

const VouchersUpload = () => {
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = Yup.object().shape({
        data: Yup.string().required('Required'),
    });

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Vouchers Upload" actions={editPageActions} />
            <Formik
                initialValues={{ data: '' }}
                validationSchema={formSchema}
                onSubmit={async (values) => {
                    if (values.data.startsWith(`Code${String.fromCharCode(10)}`)) {
                        values.data = values.data.substring(4);
                    }

                    const codes = values.data.split(String.fromCharCode(10)).filter((x) => x);

                    await apiVouchersSave(tourTypeId, id, codes);

                    history.push('/vouchers');
                }}>
                {({ isSubmitting }) => (
                    <Form className="px-2 sm:px-0">
                        <TextArea name="data" label="Vouchers" rows={10} />

                        <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default VouchersUpload;
