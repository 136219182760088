import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useHistory, useParams } from 'react-router-dom';
import { apiG1BookingsGet } from '../../api/api-bookings';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { formatDate } from '../../helpers/helpers';
import { FontAwesomeIcon, PageTitle } from '../common';
import BoltOnsUpcoming from '../board/bolt-ons-upcoming';
import BookingBoard from '../board/booking-board';
import Issues from '../common/issues/issues';

export default function Home() {
    const [isLoading, setIsLoading] = useState(true);
    const [bookings, setBookings] = useState([]);
    const [date, setDate] = useState(undefined);
    const [showCalendar, setShowCalendar] = useState(false);
    const { dateString, bookingId } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();

    const loadData = useCallback(async () => {
        setIsLoading(true);

        let m = moment(dateString || localStorage.getItem('FocusDate') || undefined).startOf('day');

        if (!m.isValid()) {
            m = moment();
        }

        localStorage.setItem('FocusDate', m.format('YYYY-MM-DD'));

        setDate(m);
        setBookings(await apiG1BookingsGet(tourTypeId, m));

        setIsLoading(false);
    }, [tourTypeId, dateString]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const showCalendarIcon = () => {
        return showCalendar ? 'chevron-up' : 'chevron-down';
    };

    const handleDateChange = (e) => {
        const newDate = moment(e).format('YYYY-MM-DD');

        setIsLoading(true);
        localStorage.setItem('FocusDate', newDate);
        history.push(`/${newDate}`);
    };

    return (
        <>
            {!isLoading && (
                <div className="my-2 sm:m-4 md:mx-auto">
                    <Issues />
                    <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2 relative">
                        <PageTitle title={formatDate(date)} />
                        <div className="absolute top-6 right-4 cursor-pointer" onClick={() => setShowCalendar((x) => !x)}>
                            <FontAwesomeIcon icon={showCalendarIcon()} className="text-blue-700 hover:text-blue-800 fa-2x" />
                        </div>
                        {showCalendar && <Calendar onChange={handleDateChange} defaultValue={date.toDate()} locale="en-GB" className="inline" />}
                    </div>

                    <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                        <PageTitle title="Extras" />
                        <BoltOnsUpcoming tourTypeId={tourTypeId} date={date} />
                    </div>

                    <BookingBoard bookings={bookings} tourTypeId={tourTypeId} date={date} reload={loadData} showBookingId={bookingId} />
                </div>
            )}
        </>
    );
}
