import React from 'react';
import HourPartDisplay from './hour-part-display';

const minutes = ['00', '15', '30', '45'];

const HourDisplay = ({ hour, lanes, tourTypeId, bookings, nowTime, onTourClick, onTimeClick, onBookingEdit, onBookingList }) => {
    return (
        <>
            {minutes.map((minute) => (
                <HourPartDisplay
                    key={`${hour}:${minute}`}
                    hour={hour}
                    minute={minute}
                    lanes={lanes}
                    tourTypeId={tourTypeId}
                    bookings={bookings}
                    onTourClick={onTourClick}
                    onTimeClick={onTimeClick}
                    onBookingEdit={onBookingEdit}
                    onBookingList={onBookingList}
                    nowTime={nowTime}
                />
            ))}
        </>
    );
};

export default HourDisplay;
