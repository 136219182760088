import { useField } from 'formik';
import React from 'react';

const InputSelect = ({ label, items, optional, optionalValue = '', optionalText = '', valueField = 'id', textField = 'name', onChange, disabled, wrapperClassName, ...props }) => {
    const [field, meta] = useField(props);

    const handleChange = (e) => {
        field.onChange(e);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className={wrapperClassName}>
            {label && <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">{label}</div>}
            <div className="w-full rounded-md shadow-sm ">
                <select name={field.name} id={field.name} onBlur={field.onBlur} onChange={handleChange} value={field.value} className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                    {optional && <option value={optionalValue}>{optionalText}</option>}
                    {items &&
                        items.map((item, idx) => (
                            <option value={item[valueField]} key={idx}>
                                {item[textField]}
                            </option>
                        ))}
                </select>
            </div>
            {meta.error && <div className="validation-error text-left">{meta.error}</div>}
        </div>
    );
};

export default InputSelect;
