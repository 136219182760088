export const durations = [{ mins: '30' }, { mins: '45' }, { mins: '50' }, { mins: '60' }, { mins: '90' }, { mins: '120' }];

export const environments = {
    Localhost: 'Localhost',
    Development: 'Development',
    Test: 'Test',
    Production: 'Production',
};

export const authExpiryCookieName = () => {
    const environment = getEnvironment().replace('Production', '').toLowerCase();
    return `api${environment && `.${environment}`}.authexpiry`;
};

export const apiUrl = () => {
    switch (getEnvironment()) {
        case environments.Localhost: //No override required, proxy in package.json will deal
            return undefined;
        case environments.Production:
            return 'https://api2.glide-platform.co.uk';
        case environments.Test:
            return 'https://api2.test.glide-platform.co.uk';
        case environments.Development:
        default:
            return 'https://api2.dev.glide-platform.co.uk';
    }
};

export const getEnvironment = () => {
    const hostname = window && window.location && window.location.hostname;

    switch (hostname) {
        case 'localhost':
            return environments.Localhost;
        case 'office.glide-platform.co.uk':
            return environments.Production;
        case 'office.test.glide-platform.co.uk':
            return environments.Test;
        default:
            return environments.Development;
    }
};

export const glide1Url = () => {
    switch (getEnvironment()) {
        case environments.Localhost:
            return 'http://localhost:3004/';
        case environments.Production:
            return 'https://app2.glide-platform.co.uk/';
        case environments.Test:
            return 'https://app.test.glide-platform.co.uk/';
        case environments.Development:
        default:
            return 'https://app.dev.glide-platform.co.uk/';
    }
};
