import React from 'react';

const Table = ({ children, fullWidth = true, slim = false, fixed = false }) => {
    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className={`${fullWidth ? 'min-w-full' : ''} table-wrapper-outer`}>
                    <div className="table-wrapper-inner sm:rounded-lg">
                        <table className={`${fullWidth ? 'min-w-full' : ''} ${slim ? 'slim' : ''} ${fixed ? 'table-fixed' : ''} divide-y divide-gray-200`}>{children}</table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
