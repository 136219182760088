/**********************************
 * Last Tours
 **********************************/
export const lastToursDefaultPageActions = [
    {
        title: 'Add New',
        to: '/last-tour',
    },
];

export const lastTourEditPageActions = [
    {
        title: 'Back',
        to: '/last-tours',
    },
];