export const reviewsPageActions = (displayType) => {
    const rtn = [];

    if (displayType) {
        rtn.push({
            title: 'Show New',
            to: '/reviews',
        });
    }

    if (displayType !== 'blocked') {
        rtn.push({
            title: 'Show Blocked',
            to: '/reviews/blocked',
        });
    }

    if (displayType !== 'failed') {
        rtn.push({
            title: 'Show Failed',
            to: '/reviews/failed',
        });
    }
    return rtn;
};
