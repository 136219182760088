import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiTicketTypesGet = async (tourTypeId, pricesAsAt = null) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/TicketTypes',
        params: { tourTypeId, pricesAsAt: fixDate(pricesAsAt) },
    });

    return result.data;
};

export const apiTicketTypeGet = async (tourTypeId, id) => {
    const found = (await apiTicketTypesGet(tourTypeId)).find((x) => x.id === parseInt(id));

    if (found && !found.affiliateId) {
        found.affiliateId = '';
    }

    if (!found.excludeBeforeHour) {
        found.excludeBeforeHour = '';
    }

    if (!found.excludeBeforeMinute) {
        found.excludeBeforeMinute = '';
    }

    if (!found.excludeAfterHour) {
        found.excludeAfterHour = '';
    }

    if (!found.excludeAfterMinute) {
        found.excludeAfterMinute = '';
    }

    return found;
};

export const apiTicketTypeSave = async (
    tourTypeId,
    id,
    ticketCategoryId,
    ticketTypeName,
    seatsRequired,
    groupSize,
    duration,
    fromDate,
    toDate,
    affiliateId,
    askHappyToSitOpposite,
    companyIds,
    isDisabled,
    copyId,
    masterId = null,
    masterDiscount = null,
    excludeMonday = false,
    excludeTuesday = false,
    excludeWednesday = false,
    excludeThursday = false,
    excludeFriday = false,
    excludeSaturday = false,
    excludeSunday = false,
    excludeBefore = null,
    excludeAfter = null,
    linkedTicketId = null
) => {
    if (!affiliateId) {
        affiliateId = null;
    }
    if (!masterId) {
        masterId = null;
    }
    if (!masterDiscount) {
        masterDiscount = null;
    }
    if (!linkedTicketId) {
        linkedTicketId = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/TicketType',
        data: {
            tourTypeId,
            id,
            ticketCategoryId,
            ticketTypeName,
            seatsRequired,
            groupSize,
            duration,
            fromDate: fixDate(fromDate),
            toDate: fixDate(toDate),
            affiliateId,
            askHappyToSitOpposite,
            companyIds,
            isDisabled,
            copyId,
            masterId,
            masterDiscount,
            excludeMonday,
            excludeTuesday,
            excludeWednesday,
            excludeThursday,
            excludeFriday,
            excludeSaturday,
            excludeSunday,
            excludeBefore,
            excludeAfter,
            linkedTicketId,
        },
    });
};

export const apiTicketCategoriesGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/TicketCategories',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiTicketCategoryGet = async (tourTypeId, id) => {
    const result = (await apiTicketCategoriesGet(tourTypeId)).find((x) => x.id === parseInt(id));

    if (!result.preferredTourConfigurationId) {
        result.preferredTourConfigurationId = '';
    }

    return result;
};

export const apiTicketCategorySave = async (tourTypeId, id, ticketCategoryName, maxTicketsPerBooking, preferredTourConfigurationId, allowSplitAcrossTours, specifiedSlotsOnly, covidSeating, isDisabled, isPrivate, emailType) => {
    try {
        await axios({
            method: 'post',
            url: '/api/v1/TicketCategory',
            data: { tourTypeId, id, ticketCategoryName, maxTicketsPerBooking, preferredTourConfigurationId, allowSplitAcrossTours, specifiedSlotsOnly, covidSeating, isDisabled, isPrivate, emailType },
        });

        return { success: true };
    } catch (ex) {
        return { success: false, exception: ex };
    }
};

export const apiPriceBandsGet = async (tourTypeId, shortenName = false) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/PriceBands',
        params: { tourTypeId },
    });

    if (shortenName) {
        result.data.forEach((pb) => {
            pb.priceBandName = pb.priceBandName.replace('Weekend', 'W/E').replace('Weekday', 'W/D');
        });
    }

    return result.data;
};

export const apiSeasonTicketTypePricesSave = async (tourTypeId, ticketTypeId, seasonTicketTypePrices) => {
    await axios({
        method: 'post',
        url: '/api/v1/SeasonTicketTypePrices',
        data: { tourTypeId, ticketTypeId, seasonTicketTypePrices },
    });
};

export const apiSeasonTicketTypePricesGet = async (tourTypeId, ticketTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/SeasonTicketTypePrices',
        params: { tourTypeId, ticketTypeId },
    });

    return result.data;
};

export const apiSeasonTicketTypePriceIssues = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/SeasonTicketTypePrices/Issues',
        params: { tourTypeId },
    });

    return result.data;
};
