import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiTourConfigurationsGet } from '../../api/api-seasons';
import { apiTicketCategoryGet, apiTicketCategorySave } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { tourTypes } from '../../helpers/constants';
import { ButtonSubmit, ErrorDisplay, InputCheckbox, InputSelect, InputSpinner, InputText, PageTitle } from '../common';
import { ticketCategoryEditPageActions } from './page-actions';

const TicketCategoryEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [error, setError] = useState(undefined);
    const [tourType, setTourType] = useState();
    const [tourConfigurations, setTourConfigurations] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = () => {
        if (tourConfigurations.length > 0) {
            return Yup.object().shape({
                ticketCategoryName: Yup.string().required('Required'),
                emailType: Yup.string().required('Required'),
                preferredTourConfigurationId: Yup.number().required('Required'),
            });
        }
        return Yup.object().shape({
            ticketCategoryName: Yup.string().required('Required'),
            emailType: Yup.string().required('Required'),
        });
    };

    useEffect(() => {
        const LoadData = async () => {
            setTourType(tourTypes.find((x) => x.id === tourTypeId));
            setTourConfigurations(await apiTourConfigurationsGet(tourTypeId));
            setData(await apiTicketCategoryGet(tourTypeId, id));

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Edit Ticket Category" actions={ticketCategoryEditPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema()}
                    onSubmit={async (values) => {
                        setError(undefined);

                        const result = await apiTicketCategorySave(
                            tourTypeId,
                            id,
                            values.ticketCategoryName,
                            values.maxTicketsPerBooking,
                            values.preferredTourConfigurationId,
                            values.allowSplitAcrossTours,
                            values.specifiedSlotsOnly,
                            values.covidSeating,
                            values.isDisabled,
                            values.isPrivate,
                            values.emailType
                        );

                        if (result.success) {
                            history.push('/ticket-categories');
                        } else {
                            setError(result.exception);
                        }
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <div className="grid grid-cols-1 gap-y-2">
                                <InputText name="ticketCategoryName" label="Name" />
                                <InputCheckbox name="isPrivate" label="Private" />
                                <InputSpinner name="maxTicketsPerBooking" min={1} max={tourType?.maxTicketsPerBookingMax || 12} label="Max Tickets Per Booking" />
                                <InputCheckbox name="allowSplitAcrossTours" label="Booking can be split over multiple Tour Groups" />
                                <InputCheckbox name="specifiedSlotsOnly" label="Specified Timeslots Only" />
                                <InputCheckbox name="covidSeating" label="COVID Seating Rules" />
                                {tourConfigurations.length > 0 && <InputSelect name="preferredTourConfigurationId" items={tourConfigurations} optional textField="tourConfigurationName" label="Preferred Tour Configuration" />}
                                <InputText name="emailType" label="Email Template" maxLength={20} />
                                <InputCheckbox name="isDisabled" label="Disabled" />
                            </div>
                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-2" />
                            <ErrorDisplay error={error} />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default TicketCategoryEdit;
