import React from 'react';

const Sources = ({ matching }) => {
    const sources = [];

    matching
        .filter((x) => x.sourceName)
        .forEach((m) => {
            let tempSource = m.sourceName;

            tempSource = tempSource.replace(/tyrrells/i, 'TYR').replace(/stride/i, 'STR');

            if (tempSource.includes(' ')) {
                tempSource = tempSource.match(/\b\w/g).join('');
            }

            sources.push(tempSource.substring(0, 10));
        });

    return <span>{[...new Set(sources)].sort().join(' ')}</span>;
};

export default Sources;
