import { useField } from 'formik';
import React from 'react';
import Calendar from 'react-calendar';

const InputCalendar = ({ onChange, minDate, ...props }) => {
    const [field, meta, helper] = useField(props);

    const handleChange = (e) => {
        helper.setValue(e);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <div className="card bg-white shadow-sm border">
                <Calendar name={field.name} id={field.name} onBlur={field.onBlur} onChange={handleChange} value={field.value} minDate={minDate} locale="en-GB" className="inline" />
            </div>
            {meta.error && <div className="validation-error">{meta.error}</div>}
        </>
    );
};

export default InputCalendar;
