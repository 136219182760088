import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiSeasonsGet } from '../../api/api-seasons';
import { apiPriceBandsGet, apiSeasonTicketTypePricesGet, apiSeasonTicketTypePricesSave, apiTicketTypeGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { Button, ButtonSubmit, InputText, PageTitle, Table, TableCell, TableRow } from '../common';
import { ticketTypePricesPageActions } from './page-actions';

const TicketPriceEdit = () => {
    const { tourTypeId } = useContext(TourTypeContext);
    const [loading, setLoading] = useState(true);
    const [seasons, setSeaons] = useState(undefined);
    const [ticketType, setTicketType] = useState(undefined);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [priceBands, setPriceBands] = useState(undefined);
    const { id } = useParams();
    const [data, setData] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
        const LoadData = async () => {
            if (id) {
                const ttResult = await apiTicketTypeGet(tourTypeId, id);
                setTicketType(ttResult);
                setIsReadOnly(ttResult.masterId ? true : false);
                const pbResult = await apiPriceBandsGet(tourTypeId);
                setPriceBands(pbResult);
                const sResult = await apiSeasonsGet(tourTypeId);
                setSeaons(sResult);

                const current = await apiSeasonTicketTypePricesGet(tourTypeId, id);

                const d = {};
                //Default to all 0 values, then overlay with what we have
                sResult.forEach((season) => {
                    pbResult.forEach((band) => {
                        d[`${season.id}_${band.id}_price`] = 0;
                    });
                });

                if (current.length > 0) {
                    current.forEach((item) => {
                        d[`${item.seasonId}_${item.priceBandId}_price`] = item.price || 0;
                    });
                }
                d.allPrice = '';
                setData(d);

                setLoading(false);
            }
        };

        LoadData();
    }, [tourTypeId, id]);

    return (
        <div className="my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4">
            <PageTitle title={`Ticket Prices: ${ticketType ? ticketType.fullName : ''}`} actions={ticketTypePricesPageActions} />
            {!loading && (
                <Formik
                    initialValues={data}
                    validate={(values) => {
                        const errors = {};

                        seasons.forEach((season) => {
                            priceBands.forEach((band) => {
                                const fieldId = `${season.id}_${band.id}_price`;
                                const value = values[fieldId];

                                if (value.length === 0) {
                                    errors[fieldId] = 'Required';
                                } else if (!/^\d{1,3}(?:[.]\d{0,2})?$/.test(value)) {
                                    errors[fieldId] = 'Invalid';
                                }
                            });
                        });

                        return errors;
                    }}
                    enableReinitialize
                    onSubmit={async (values) => {
                        const toSubmit = [];

                        seasons.forEach((season) => {
                            priceBands.forEach((band) => {
                                toSubmit.push({
                                    seasonId: season.id,
                                    priceBandId: band.id,
                                    price: values[`${season.id}_${band.id}_price`],
                                });
                            });
                        });

                        await apiSeasonTicketTypePricesSave(tourTypeId, id, toSubmit);
                        history.push('/ticket-types');
                    }}>
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Table>
                                <thead>
                                    <tr>
                                        <TableCell text="Season" header />
                                        {priceBands.sort().map((band, key) => (
                                            <TableCell key={key} text={`${band.priceBandName} £`} header />
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {seasons
                                        .filter((x) => !x.isDisabled)
                                        .sort()
                                        .map((season, key) => (
                                            <TableRow key={key} rowNum={key}>
                                                <TableCell>{season.seasonName}</TableCell>
                                                {priceBands.sort().map((band, bKey) => (
                                                    <TableCell center key={bKey}>
                                                        <div className="-my-3 -mx-2">
                                                            <InputText name={`${season.id}_${band.id}_price`} disabled={isReadOnly} />
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                </tbody>
                            </Table>
                            {!isReadOnly && (
                                <div className="py-2 mb-2">
                                    <div className="sm:flex px-2 gap-2">
                                        <span className="self-center whitespace-no-wrap sm:ml-auto">Set All To:</span>
                                        <InputText name="allPrice" wrapperClassName="max-w-xxs" />
                                        <span>
                                            <Button
                                                label="Set"
                                                onClick={() => {
                                                    Object.keys(values).forEach((key) => {
                                                        if (key.endsWith('_price')) {
                                                            setFieldValue(key, values.allPrice);
                                                        }
                                                    });
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            )}

                            {!isReadOnly && <ButtonSubmit />}
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default TicketPriceEdit;
