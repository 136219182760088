import moment from 'moment';
import React from 'react';
import { ticketCategoryName } from '../../../helpers/reference-data-helpers';
import { PageTitle, Table, TableCell, TableRow } from '../../common';

const ExistingToursDisplay = ({ results, ticketCategories }) => {
    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mt-4">
            <PageTitle title="Existing Tours" />
            <div className="border-b-2 border-blue-700 -mt-2">
                {results.existingTours.length > 0 ? (
                    <Table slim>
                        <thead>
                            <tr>
                                <TableCell text="Time / Lane" header />
                                <TableCell text="Duration" header center />
                                <TableCell text="Category" header />
                                <TableCell text="Groups" header />
                                <TableCell text="Web / All" header center />
                            </tr>
                        </thead>
                        <tbody>
                            {results.existingTours.map((data, key) => (
                                <TableRow key={key} rowNum={key}>
                                    <TableCell>
                                        <span className="font-mono block md:inline">{moment(data.time, 'HH:mm:ss').format('HH:mm')}</span> / {data.lane}
                                    </TableCell>
                                    <TableCell center text={data.duration} />
                                    <TableCell text={ticketCategoryName(ticketCategories, data.ticketCategoryId)} />
                                    <TableCell text={data.groupsSimple} />
                                    <TableCell center text={`${data.webBooked} / ${data.allBooked}`} />
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div>None Found</div>
                )}
            </div>
        </div>
    );
};

export default ExistingToursDisplay;
