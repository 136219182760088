import React from 'react';
import TourDisplay from './tour-display';

const HourPartDisplay = ({ hour, minute, lanes, tourTypeId, bookings, nowTime, onTourClick, onTimeClick, onBookingEdit, onBookingList }) => {
    const borderClass = minute === '00' ? 'border-gray-600' : 'border-gray-300';
    const bgClass = `${hour.toString().padStart(2, '0')}:${minute}` < nowTime ? 'bg-gray-200' : 'bg-white';

    return (
        <div className="grid grid-flow-col auto-cols-fr text-center">
            <div className="font-mono text-right relative">
                {minute === '00' ? (
                    <div className="absolute top-0 right-2 -mt-3">
                        {hour}:{minute}
                    </div>
                ) : (
                    <div className="absolute top-0 right-2 -mt-3 text-gray-400">:{minute}</div>
                )}
            </div>
            {lanes &&
                lanes.map((lane) => (
                    <div
                        key={`${hour}:${lane}:00`}
                        className={`hover:bg-gray-400 cursor-pointer col-span-2 relative border-t ${borderClass} ${bgClass} h-6 min-w-tour`}
                        onClick={() => onTimeClick(`${hour}:${minute}`, lane)}
                        title={`Lane ${lane} - ${hour}:${minute}`}>
                        <TourDisplay tourTypeId={tourTypeId} bookings={bookings} lane={lane} hour={hour} minute={parseInt(minute)} nowTime={nowTime} onTourClick={onTourClick} onBookingEdit={onBookingEdit} onBookingList={onBookingList} />
                    </div>
                ))}
        </div>
    );
};

export default HourPartDisplay;
