import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiSingleUseDiscountsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/SingleUseDiscounts',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiSingleUseDiscountGet = async (tourTypeId, id) => {
    const result = (await apiSingleUseDiscountsGet(tourTypeId)).find((x) => x.id === parseInt(id));

    if (!result.ticketCategoryId) {
        result.ticketCategoryId = '';
    }

    if (!result.validBeforeHour) {
        result.validBeforeHour = '';
    }

    if (!result.validBeforeMinute) {
        result.validBeforeMinute = '';
    }

    if (!result.validAfterHour) {
        result.validAfterHour = '';
    }

    if (!result.validAfterMinute) {
        result.validAfterMinute = '';
    }

    return result;
};

export const apiSingleUseDiscountSave = async (
    tourTypeId,
    id,
    singleUseDiscountName,
    code,
    batchId,
    amount,
    tourFrom,
    tourTo,
    ticketCategoryId,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    validBefore,
    validAfter,
    isDisabled,
    used
) => {
    if (!ticketCategoryId) {
        ticketCategoryId = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/SingleUseDiscount',
        data: {
            tourTypeId,
            id,
            singleUseDiscountName,
            code,
            batchId,
            amount,
            tourFrom: fixDate(tourFrom),
            tourTo: fixDate(tourTo),
            ticketCategoryId,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            validBefore,
            validAfter,
            isDisabled,
            used,
        },
    });
};

export const apiSingleUseDiscountCreate = async (numberToCreate, tourTypeId, singleUseDiscountName, amount, tourFrom, tourTo, ticketCategoryId, monday, tuesday, wednesday, thursday, friday, saturday, sunday, validBefore, validAfter) => {
    if (!ticketCategoryId) {
        ticketCategoryId = null;
    }

    var result = await axios({
        method: 'post',
        url: '/api/v1/SingleUseDiscount/Create',
        data: {
            tourTypeId,
            singleUseDiscountName,
            numberToCreate,
            amount,
            tourFrom: fixDate(tourFrom),
            tourTo: fixDate(tourTo),
            ticketCategoryId,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            validBefore,
            validAfter,
            isDisabled: false,
        },
    });

    return result.data;
};
