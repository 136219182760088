import React from 'react';
import moment from 'moment';

const Sections = ({ firstTour, nowTime }) => {
    const chauffeurInitials = (firstTour) => {
        if (firstTour.chauffeurName) {
            return firstTour.chauffeurName.match(/\b\w/g).join('');
        } else {
            if (moment(firstTour.time, 'HH:mm:ss').format('HH:mm') < nowTime) {
                return <span className="fas fa-user-slash" />;
            }
            return '';
        }
    };

    return (
        <span>
            {chauffeurInitials(firstTour)}|{firstTour.noOfSections}|
        </span>
    );
};

export default Sections;
