import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiVouchersDeleteUnused, apiVoucherTypesGet } from '../../api/api-vouchers';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';
import { listPageActions } from './page-actions';
import swal from 'sweetalert';

const VoucherTypeList = () => {
    const [data, setData] = useState([]);
    const { displayType } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const [anyDisabled, setAnyDisabled] = useState(false);

    const LoadData = useCallback(async () => {
        setData(await apiVoucherTypesGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        setAnyDisabled(data.filter((x) => x.isDisabled).length > 0);
    }, [data]);

    const toDisplay = () => {
        if (displayType === 'disabled') {
            return data.filter((x) => x.isDisabled);
        } else {
            return data.filter((x) => !x.isDisabled);
        }
    };

    const deletedUnused = async (id) => {
        swal({
            title: 'Are You Sure?',
            icon: 'warning',
            buttons: {
                no: { text: 'No', value: false },
                danger: { text: 'Yes', value: true },
            },
        }).then(async (value) => {
            if (value) {
                await apiVouchersDeleteUnused(tourTypeId, id);
                await LoadData();
            }
        });
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Voucher Types" actions={listPageActions(displayType, anyDisabled)} />
            <Table>
                <thead>
                    <tr>
                        <TableCell text="Name" header />
                        <TableCell text="Used" header center />
                        <TableCell text="Remaining" header center />
                        <TableCell text="" header />
                        <TableCell text="" header />
                        <TableCell text="" header />
                    </tr>
                </thead>

                <tbody>
                    {toDisplay().map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell>
                                {item.voucherTypeName}
                                {item.isDisabled ? ' (Disabled)' : ''}
                            </TableCell>
                            <TableCell text={item.used} center />
                            <TableCell center>
                                {item.remaining}
                                {item.remaining < item.warnBelow && <span className="fad fa-exclamation ml-2 text-red-700" />}
                            </TableCell>
                            <TableCell actionLeft>
                                <Link to={`/vouchers-upload/${item.id}`}>
                                    <FontAwesomeIcon icon="upload" title="Upload" />
                                </Link>
                            </TableCell>
                            <TableCell actionMiddle>
                                <FontAwesomeIcon icon="broom" title="Delete Unused" onClick={() => deletedUnused(item.id)} />
                            </TableCell>
                            <TableCell actionRight>
                                <Link to={`/voucher-type/${item.id}`}>
                                    <FontAwesomeIcon icon="edit" title="Edit" />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default VoucherTypeList;
