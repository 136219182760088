import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const Context = createContext({});

export const Provider = ({ children }) => {
    const [tourTypeId, setTourTypeId] = useState(localStorage.getItem('tour-type-id') || 1);
    const history = useHistory();

    useEffect(() => {
        setTourTypeId(parseInt(localStorage.getItem('tour-type-id') || 1));
    }, []);

    const selectTourTypeId = useCallback(
        (e) => {
            const newTourTypeId = e.target ? e.target.value : e;

            setTourTypeId(newTourTypeId);
            localStorage.setItem('tour-type-id', newTourTypeId);

            history.push('/');
        },
        [history]
    );

    const tourTypeContext = {
        tourTypeId,
        selectTourTypeId,
    };

    return <Context.Provider value={tourTypeContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
