import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { apiG1TourEdit, apiG1TourDelete } from '../../api/api-bookings';
import { apiChauffeursGet } from '../../api/api-sources';
import { durations } from '../../config';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, lanesGet, minuteDefaults, noOfSectionsGet } from '../../helpers/constants';
import { roundToNearestXXMinutes } from '../../helpers/helpers';
import { ButtonSubmit, InputCheckbox, InputSelect, TextArea, ErrorDisplay, Button } from '../common';
import Modal from '../common/modal';

export default function TourEdit({ show, id, date, time, matching, lane, close }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const [lanes, setLanes] = useState(undefined);
    const [chauffeurs, setChauffeurs] = useState([]);
    const [error, setError] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);
    const [deleting, setDeleting] = useState(false);

    const doDeleteTour = async () => {
        setError(undefined);
        const result = await apiG1TourDelete(tourTypeId, id);

        if (result.successful) {
            close(true);
            return;
        }

        setError(result.error);
    };

    useEffect(() => {
        const loadData = async () => {
            if (!show) {
                return;
            }

            setIsLoading(true);

            const lanesData = lanesGet(tourTypeId);
            setLanes(lanesData);

            if (id) {
                const firstTour = matching[0];

                const m = moment(firstTour.time, 'HH:mm:ss');

                setData({
                    hour: parseInt(m.format('H')),
                    minute: parseInt(m.format('mm')),
                    lane: firstTour.lane,
                    duration: firstTour.duration,
                    noOfSections: firstTour.noOfSections,
                    chauffeurId: firstTour.chauffeurId || '',
                    notes: firstTour.notes,
                    smsHold: firstTour.smsHold,
                    smsHoldOriginal: firstTour.smsHold,
                    createdById: firstTour.createdById,
                });
            } else {
                const mNow = roundToNearestXXMinutes(moment(time, 'HH:mm'), 15);

                setData({
                    hour: parseInt(mNow.format('H')),
                    minute: parseInt(mNow.format('mm')),
                    lane: lane || lanesData[0]?.lane || '',
                    duration: 60,
                    noOfSections: noOfSectionsGet(tourTypeId)[0]?.value || '',
                    chauffeurId: '',
                    notes: '',
                    smsHold: false,
                    smsHoldOriginal: false,
                });
            }

            setChauffeurs(await apiChauffeursGet(tourTypeId));

            setIsLoading(false);
        };

        loadData();
    }, [tourTypeId, id, time, lane, matching, show]);

    return (
        <Modal show={show} hide={close} showClose title={id ? 'Edit Tour' : 'Add Tour'} modalStyle="max-w-screen-sm">
            {!isLoading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    onSubmit={async (values) => {
                        setError(undefined);

                        time = `${values.hour}:${values.minute}`;

                        var result = await apiG1TourEdit(tourTypeId, id, date, time, values.duration, values.lane, values.noOfSections, values.createdById, values.chauffeurId, values.notes, values.smsHold, values.smsHoldOriginal);

                        if (result.successful) {
                            close(true);
                            return;
                        }

                        setError(result.error);
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <div className="sm:grid sm:grid-cols-2 sm:gap-2">
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Tour Time</div>
                                        <div className="flex gap-2">
                                            <InputSelect name="hour" items={hourDefaults} valueField="hour" textField="hour" />
                                            <InputSelect name="minute" items={minuteDefaults} valueField="minute" textField="minute" />
                                        </div>
                                    </div>
                                </div>

                                <InputSelect name="lane" label="Lane" items={lanes} valueField="lane" textField="lane" wrapperClassName="max-w-xxs" />
                                <InputSelect name="duration" items={durations} textField="mins" valueField="mins" label="Duration (Mins)" wrapperClassName="max-w-xxs" />
                                <InputSelect name="noOfSections" items={noOfSectionsGet(tourTypeId)} textField="value" valueField="value" label="No of Sections" wrapperClassName="max-w-xxs" />
                            </div>

                            <InputSelect name="chauffeurId" items={chauffeurs} textField="displayName" valueField="id" optional label="Chauffeur" />
                            <TextArea name="notes" label="Notes" rows="3" maxLength="1000" />
                            <InputCheckbox name="smsHold" label="Hold SMS" horizontal={false} />

                            <ErrorDisplay error={error} />
                            <div className="flex mt-4 gap-x-2">
                                {deleting ? (
                                    <>
                                        <div className="w-1/3">
                                            <Button label="Yes, Delete" secondary onClick={doDeleteTour} />
                                        </div>
                                        <Button label="Cancel" onClick={() => setDeleting(false)} />
                                    </>
                                ) : (
                                    <>
                                        <ButtonSubmit isSubmitting={isSubmitting} />
                                        {id && matching.filter((x) => x.bookingId).length === 0 && (
                                            <div className="w-1/3">
                                                <Button label="Delete" onClick={() => setDeleting(true)} secondary />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
}
