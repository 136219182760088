import React from 'react';
import { Link } from 'react-router-dom';

const PageTitle = ({ title, actions }) => {
    return (
        <div className="sm:flex sm:items-end sm:justify-between px-2 sm:px-0 mb-2 border-b-2 border-blue-700 pb-2">
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-blue-700 sm:text-3xl sm:leading-9 sm:truncate">{title}</h2>
            </div>
            {actions && (
                <div className="flex sm:ml-4 gap-2 justify-end">
                    {actions.map((action, key) => (
                        <span className="shadow-sm rounded-md" key={key}>
                            <Link
                                to={action.to}
                                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-3 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out">
                                {action.title}
                            </Link>
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PageTitle;
