export const ticketTypePricesPageActions = [
    {
        title: 'Back',
        to: '/ticket-types',
    },
];

const ticketTypesDefaultPageActions = [
    {
        title: 'Add New',
        to: '/ticket-type',
    },
];

export const ticketTypesPageActions = (displayType, anyDisabled) => {
    switch (displayType) {
        case 'disabled':
            return [
                {
                    title: 'Show Active',
                    to: '/ticket-types',
                },
                ...ticketTypesDefaultPageActions,
            ];
        case 'active':
        default:
            if (anyDisabled) {
                return [
                    {
                        title: 'Show Disabled',
                        to: '/ticket-types/disabled',
                    },
                    ...ticketTypesDefaultPageActions,
                ];
            } else {
                return ticketTypesDefaultPageActions;
            }
    }
};

export const ticketTypeEditPageActions = [
    {
        title: 'Back',
        to: '/ticket-types',
    },
];
