import axios from 'axios';

export const apiLogin = async (emailAddress, password) => {
    try {
        await axios({
            method: 'post',
            url: '/api/v1/Login',
            data: {
                emailAddress,
                password,
            },
        });

        return true;
    } catch {
        return false;
    }
};

export const apiLogout = async () => {
    await axios({
        method: 'post',
        url: '/api/v1/Logout',
    });
};
