import axios from 'axios';

export const apiVoucherTypesGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/VoucherTypes',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiVouchersMissing = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Vouchers/Missing',
        params: { tourTypeId },
    });

    return result.data.rows;
};

export const apiVoucherTypeGet = async (tourTypeId, id) => {
    const result = (await apiVoucherTypesGet(tourTypeId)).find((x) => x.id === parseInt(id));

    return result;
};

export const apiVoucherTypeSave = async (tourTypeId, id, voucherTypeName, warnBelow, isDisabled) => {
    if (!id) {
        id = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/VoucherType',
        data: { tourTypeId, id, voucherTypeName, warnBelow, isDisabled },
    });
};

export const apiVouchersSave = async (tourTypeId, id, voucherCodes) => {
    if (!id) {
        id = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/Vouchers',
        data: { tourTypeId, id, voucherCodes },
    });
};

export const apiVouchersDeleteUnused = async (tourTypeId, id) => {
    if (!id) {
        id = null;
    }

    await axios({
        method: 'delete',
        url: '/api/v1/Vouchers',
        data: { tourTypeId, id },
    });
};
