import React from 'react';

const Icons = ({ matching, firstTour }) => {
    const Unpaid = ({ matching }) => {
        return matching.filter((x) => x.bookingId && !x.isPaid).length > 0 ? <span className="fas fa-pound-sign text-white mr-1" title="Unpaid" /> : null;
    };

    const Notes = ({ firstTour, matching }) => {
        const notes = [];
        if (firstTour.tourNotes) {
            notes.push(firstTour.tourNotes);
        }

        matching.filter((x) => x.bookingNotes).forEach((m) => notes.push(m.bookingNotes));

        return notes.length > 0 ? <span title={notes.join('\n\n')} className="fas fa-sticky-note text-white mr-1" /> : null;
    };

    const Picnic = ({ matching }) => {
        return matching.filter((x) => x.bookingId && x.picnic).length > 0 ? <span className="fas fa-utensils text-white mr-1" title="Picnic" /> : null;
    };

    const Alcohol = ({ matching }) => {
        return matching.filter((x) => x.bookingId && x.alcohol).length > 0 ? <span className="fas fa-wine-bottle text-white mr-1" title="Alcohol" /> : null;
    };

    const Booklet = ({ matching }) => {
        return matching.filter((x) => x.bookingId && x.booklet).length > 0 ? <span className="fas fa-book-open text-white mr-1" title="Booklet" /> : null;
    };

    return (
        <div className="ml-0.5">
            <Notes matching={matching} firstTour={firstTour} />
            <Unpaid matching={matching} />
            <Picnic matching={matching} />
            <Alcohol matching={matching} />
            <Booklet matching={matching} />
            {firstTour.smsHold && <span className="fad fa-comment-alt-times text-white mr-1" title="SMS Held" />}
            {firstTour.smsSent && <span className="fad fa-comment-alt-check text-white mr-1" title="SMS Send" />}
        </div>
    );
};

export default Icons;
