import React from 'react';
import Groups from './groups';
import Icons from './icons';
import Sections from './sections';
import Sources from './sources';
import moment from 'moment';
import { calcIsFull, tourStyle } from './helpers';

const TourDisplay = ({ tourTypeId, bookings, lane, hour, minute, nowTime, onTourClick, onBookingEdit, onBookingList }) => {
    const mTime = moment(`${hour}:${minute}`, 'H:m');

    const matching = bookings.filter((x) => x.lane === lane && moment(x.timeNearest15Minutes, 'HH:mm:ss').isSame(mTime));

    if (matching.length > 0) {
        const firstTour = matching[0];

        const totalSeatsBooked = matching.reduce((a, b) => a + (b.seatsRequired || b.seatsBooked), 0);

        const isFull = calcIsFull(tourTypeId, totalSeatsBooked, firstTour.noOfSections);
        const isPrivate = matching.filter((x) => x.bookingIsPrivate || x.tourPrivate || x.toutPrivate).length > 0;
        const isHistoric = moment(firstTour.time, 'HH:mm:ss').format('HH:mm') < nowTime;
        const hasChineseGroup = matching.filter((x) => x.sourceName && x.sourceName.toLowerCase().includes('chinese')).length > 0;
        const hasProvisional = matching.filter((x) => x.provisional).length > 0;

        const styles = tourStyle(firstTour, isFull, isPrivate, hasChineseGroup, hasProvisional);

        return (
            <div
                className={`absolute left-2 w-32 border ${styles.colours.border} rounded-lg z-30 ${styles.colours.background} text-white h-full flex place-content-center place-items-center rounded-lg relative`}
                style={styles.cssStyle}
                onClick={(e) => onTourClick(e, firstTour.tourId, firstTour.time, firstTour.lane)}>
                <div className="absolute inset-x-0 top-0 rounded-t-lg text-left min-h-6 text-sm overflow-hidden -ml-0.5">
                    <Groups tourId={firstTour.tourId} matching={matching} isFull={isFull} isPrivate={isPrivate} isHistoric={isHistoric} onBookingEdit={onBookingEdit} onBookingList={onBookingList} />
                </div>
                <div className="inset-0 text-xs whitespace-no-wrap overflow-hidden px-1">
                    <Sources matching={matching} />
                </div>
                <div className={`absolute left-0 bottom-0 rounded-b-lg min-h-6 text-sm py-0 pl-0.5 space-x-1`}>
                    <Icons matching={matching} firstTour={firstTour} />
                </div>
                <div className={`absolute right-0 bottom-0 rounded-b-lg min-h-6 text-xs py-0.5 px-1`}>
                    <Sections firstTour={firstTour} nowTime={nowTime} />
                </div>
            </div>
        );
    }
    return null;
};

export default TourDisplay;
