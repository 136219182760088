import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { apiG1BookingsGet } from '../../api/api-bookings';
import { tourTypes } from '../../helpers/constants';
import { getLocalStorageBool } from '../../helpers/helpers';
import { tourTypeTitle } from '../../helpers/tour-type-helpers';
import { FontAwesomeIcon, PageTitle } from '../common';
import { getHours, getLanes, getNowTime } from './board-components/helpers';
import HourDisplay from './board-components/hour-display';
import BookingEdit from './booking-edit';
import BookingList from './booking-list';
import TourEdit from './tour-edit';

export default function BookingBoard({ bookings, tourTypeId, date, reload, showBookingId }) {
    const [useCore, setUseCore] = useState(getLocalStorageBool('useCore', false));
    const [lanes, setLanes] = useState(undefined);
    const [hours, setHours] = useState(undefined);
    const [clicked, setClicked] = useState(undefined);
    const [showTourEdit, setShowTourEdit] = useState(false);
    const [showBookingEdit, setShowBookingEdit] = useState(false);
    const [showBookingList, setShowBookingList] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const tourType = tourTypes.find((x) => x.id === tourTypeId);

        setLanes(getLanes(tourType, bookings, useCore));
        setHours(getHours(tourType, bookings, useCore));
    }, [bookings, tourTypeId, useCore]);

    useEffect(() => {
        localStorage.setItem('useCore', useCore);
    }, [useCore]);

    const onTourClick = async (e, id, time, lane) => {
        e.stopPropagation();

        const refreshedBookings = await apiG1BookingsGet(tourTypeId, date);

        const matching = refreshedBookings.filter((x) => x.tourId === id);

        if (matching.length > 0) {
            setClicked({ time, lane, id, matching });
            setShowTourEdit(true);
        }
    };

    const onBookingList = async (e, tourId) => {
        e.stopPropagation();

        const refreshedBookings = await apiG1BookingsGet(tourTypeId, date);

        const matches = refreshedBookings.filter((x) => x.tourId === tourId);

        if (matches.length === 1) {
            onBookingEdit(null, null, matches[0].g2BookingId);
        } else {
            setClicked({ tourId, id: null, matches });
            setShowBookingList(true);
        }
    };

    const onBookingEdit = useCallback(
        async (e, tourId, g2BookingId = null) => {
            if (e) {
                e.stopPropagation();
            }

            setShowTourEdit(false);
            setShowBookingEdit(false);
            setShowBookingList(false);

            const refreshedBookings = await apiG1BookingsGet(tourTypeId, date);

            if (tourId) {
                const matches = refreshedBookings.filter((x) => x.tourId === tourId);

                setClicked({ tourId, g2BookingId, matches });
            } else {
                if (!g2BookingId) {
                    swal({
                        title: 'Glide 1 Booking',
                        text: 'Please Edit in Glide 1',
                        icon: 'warning',
                    });
                    return;
                }

                const matches = refreshedBookings.filter((x) => x.g2BookingId === g2BookingId);

                setClicked({ tourId, g2BookingId, matches });
            }
            setShowBookingEdit(true);
        },
        [date, tourTypeId]
    );

    useEffect(() => {
        if (showBookingId) {
            onBookingEdit(null, null, showBookingId);
        }
    }, [showBookingId, onBookingEdit]);

    const onTimeClick = (time, lane) => {
        setClicked({ time, lane, id: undefined, matching: undefined });
        setShowTourEdit(true);
    };

    const closeEdit = (andReload) => {
        setShowTourEdit(false);
        setShowBookingEdit(false);
        setShowBookingList(false);
        setClicked(undefined);

        if (andReload && reload) {
            if (showBookingId) {
                history.push('/');
            } else {
                reload();
            }
        }
    };

    const toggleIcon = () => {
        return useCore ? 'compress-arrows-alt' : 'expand-arrows-alt';
    };

    const toggleFull = () => {
        setUseCore((x) => !x);
    };

    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2 relative">
            <PageTitle title={`${tourTypeTitle(tourTypeId)} Board`} />
            <div className="absolute top-6 right-4 cursor-pointer" onClick={toggleFull}>
                <FontAwesomeIcon icon={toggleIcon()} className="text-blue-700 hover:text-blue-800 fa-2x" />
            </div>

            {hours && lanes && (
                <div className="overflow-x-auto">
                    <div className="w-max">
                        <div className="grid grid-flow-col auto-cols-fr text-center">
                            <div className="min-w-tour-time"></div>
                            {lanes.map((lane) => (
                                <div key={lane} className="col-span-2 min-w-tour">
                                    {lane}
                                </div>
                            ))}
                        </div>

                        {hours.map((hour) => (
                            <HourDisplay key={hour} {...{ hour, lanes, tourTypeId, bookings, onTourClick, onTimeClick, onBookingEdit, onBookingList, nowTime: getNowTime(date) }} />
                        ))}
                    </div>
                </div>
            )}

            <TourEdit show={showTourEdit} date={date} {...clicked} close={closeEdit} />
            <BookingEdit show={showBookingEdit} {...clicked} close={closeEdit} bookings={bookings} />
            <BookingList show={showBookingList} {...clicked} close={closeEdit} onBookingEdit={onBookingEdit} />
        </div>
    );
}
