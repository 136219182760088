import React from 'react';

const FontAwesomeIcon = ({ icon, onClick, title, className = '', danger = false, primaryColour, secondaryColour, secondaryOpacity }) => {
    const colourClass = danger ? 'text-red-600 hover:text-red-700' : 'text-gray-600 hover:text-gray-900';

    const styles = {};
    if (primaryColour) {
        styles['--fa-primary-color'] = primaryColour;
    }
    if (secondaryColour) {
        styles['--fa-secondary-color'] = secondaryColour;
    }
    if (secondaryOpacity) {
        styles['--fa-secondary-opacity'] = secondaryOpacity;
    }

    return <span className={`fad fa-${icon} fa-lg ${colourClass}  ${onClick ? 'cursor-pointer' : ''} ${className}`} onClick={onClick} title={title} style={styles} />;
};

export default FontAwesomeIcon;
