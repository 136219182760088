const listDefaultPageActions = [
    {
        title: 'Add New',
        to: '/affiliate',
    },
];

export const listPageActions = (displayType, anyDisabled) => {
    switch (displayType) {
        case 'disabled':
            return [
                {
                    title: 'Show Active',
                    to: '/affiliates',
                },
                ...listDefaultPageActions,
            ];
        case 'active':
        default:
            if (anyDisabled) {
                return [
                    {
                        title: 'Show Disabled',
                        to: '/affiliates/disabled',
                    },
                    ...listDefaultPageActions,
                ];
            } else {
                return listDefaultPageActions;
            }
    }
};

export const editPageActions = [
    {
        title: 'Back',
        to: '/affiliates',
    },
];
