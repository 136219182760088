import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiAvailabilityGet } from '../../api/api-availability';
import { apiTicketCategoriesGet, apiTicketTypesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, InputDate, InputSelect, InputSpinner, PageTitle, Table, TableCell, TableRow } from '../common';
import ErrorDisplay from '../common/error-display';
import AvailabilityDisplay from './availability/availability-display';
import DayDisplay from './availability/day-display';
import ExistingToursDisplay from './availability/existing-tours-display';
import { hourDefaults, minuteDefaults } from '../../helpers/constants';

const AvailabilityTester = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [ticketCategories, setTicketCategories] = useState([]);
    const [results, setResults] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);
    const [error, setError] = useState(undefined);
    const [maxTickets, setMaxTickets] = useState(1);

    const LoadData = useCallback(async () => {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        setData({
            ticketCategoryId: '',
            tickets: [],
            tourDate: tomorrow,
            happyToSitOpposite: true,
        });

        const ticketTypesResult = await apiTicketTypesGet(tourTypeId);
        setTicketTypes(ticketTypesResult.filter((x) => !x.isDisabled));

        const ticketCategoriesResult = await apiTicketCategoriesGet(tourTypeId);
        setTicketCategories(ticketCategoriesResult.filter((x) => !x.isDisabled));

        setLoading(false);
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    const formSchema = Yup.object().shape({
        ticketCategoryId: Yup.string().required('Required'),
        tourDate: Yup.string().required('Required'),
    });

    const ticketCategoryOnChange = (e, values, remove, push) => {
        for (let i = 0; i < values.tickets.length; ++i) {
            remove(0);
        }

        if (e.target.value) {
            const ticketCategoryId = parseInt(e.target.value);

            const ticketCategory = ticketCategories.find((x) => x.id === ticketCategoryId);
            setMaxTickets(ticketCategory?.maxTicketsPerBooking || 1);

            ticketTypes.forEach((tt) => {
                if (tt.ticketCategoryId === ticketCategoryId) {
                    push({ ...tt, ticketTypeId: tt.id, amount: 0 });
                }
            });
        }
    };

    const calcTotal = (values) => {
        return values.tickets.reduce((a, b) => a + b.amount, 0);
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Availability Tester" />
                {!loading && (
                    <Formik
                        initialValues={data}
                        enableReinitialize
                        validationSchema={formSchema}
                        onSubmit={async (values) => {
                            setResults(undefined);
                            try {
                                setResults(await apiAvailabilityGet(tourTypeId, values.tourDate, values.ticketCategoryId, values.tickets, true, values.beforeHour, values.beforeMinute, values.afterHour, values.afterMinute));
                                setError(undefined);
                            } catch (ex) {
                                setError(ex);
                                setResults(undefined);
                            }
                        }}>
                        {({ values, isSubmitting }) => (
                            <Form className="px-2 sm:px-0">
                                <div className="gap-y-2">
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <div className="ml-2 mb-2 mt-4 leading-3 text-sm font-semibold">Tour Date</div>
                                            <div className="flex gap-2 items-center">
                                                <InputDate name="tourDate" label="Date" showClear={false} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Before</div>
                                            <div className="flex gap-2">
                                                <InputSelect name="beforeHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                                <InputSelect name="beforeMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">After</div>
                                            <div className="flex gap-2">
                                                <InputSelect name="afterHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                                <InputSelect name="afterMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray
                                        name="tickets"
                                        render={({ remove, push }) => (
                                            <>
                                                <InputSelect
                                                    name="ticketCategoryId"
                                                    items={ticketCategories}
                                                    textField="ticketCategoryName"
                                                    label="Ticket Category"
                                                    optional
                                                    wrapperClassName="sm:col-span-3"
                                                    onChange={(e) => ticketCategoryOnChange(e, values, remove, push)}
                                                />
                                                <Table slim>
                                                    <thead>
                                                        <tr>
                                                            <TableCell text="Amount" header />
                                                            <TableCell text="Ticket" header />
                                                            <TableCell text="Affiliate" header />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.tickets.map((data, key) => (
                                                            <TableRow key={key} rowNum={key}>
                                                                <TableCell>
                                                                    <InputSpinner name={`tickets.${key}.amount`} min={0} max={maxTickets} total={calcTotal(values)} />
                                                                </TableCell>
                                                                <TableCell text={data.ticketTypeName} />
                                                                <TableCell text={data.affiliateName} />
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </>
                                        )}
                                    />
                                </div>
                                <ButtonSubmit isSubmitting={isSubmitting} className="mt-2" label="Test" />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
            {error && <ErrorDisplay error={error} />}
            {results && (
                <>
                    <AvailabilityDisplay results={results} />
                    <div className="grid grid-cols-5 gap-x-2">
                        <div className="col-span-2">
                            <DayDisplay results={results} ticketCategories={ticketCategories} />
                        </div>
                        <div className="col-span-3">
                            <ExistingToursDisplay results={results} ticketCategories={ticketCategories} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AvailabilityTester;
