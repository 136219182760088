import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiSeasonsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Seasons',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiSeasonGet = async (tourTypeId, id) => {
    return (await apiSeasonsGet(tourTypeId)).find((x) => x.id === parseInt(id));
};

export const apiSeasonSave = async (tourTypeId, id, seasonName, maxGroups, tourConfigurations, isDisabled) => {
    await axios({
        method: 'post',
        url: '/api/v1/Season',
        data: { tourTypeId, id, seasonName, maxGroups, tourConfigurations, isDisabled },
    });
};

export const apiSeasonDatesGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/SeasonDates',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiSeasonDateSave = async (tourTypeId, id, seasonId, fromDate) => {
    await axios({
        method: 'post',
        url: '/api/v1/SeasonDate',
        data: { tourTypeId, id, seasonId, fromDate: fixDate(fromDate) },
    });
};

export const apiSeasonDateDelete = async (tourTypeId, id) => {
    await axios({
        method: 'delete',
        url: '/api/v1/SeasonDate',
        data: { tourTypeId, id },
    });
};

export const apiSeasonDateGet = async (tourTypeId, id) => {
    return (await apiSeasonDatesGet(tourTypeId)).find((x) => x.id === parseInt(id));
};

export const apiSeasonLimitsSave = async (tourTypeId, seasonId, seasonLimits) => {
    await axios({
        method: 'post',
        url: '/api/v1/SeasonLimits',
        data: { tourTypeId, seasonId, seasonLimits },
    });
};

export const apiSeasonLimitsGet = async (tourTypeId, seasonId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/SeasonLimits',
        params: { tourTypeId, seasonId },
    });

    return result.data;
};

export const apiTourConfigurationsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/TourConfigurations',
        params: { tourTypeId },
    });

    return result.data;
};
