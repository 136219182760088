const alphas = [
    'Alpha',
    'Bravo',
    'Charlie',
    'Delta',
    'Echo',
    'Foxtrot',
    'Golf',
    'Hotel',
    'India',
    'Juliett',
    'Kilo',
    'Lima',
    'Mike',
    'November',
    'Oscar',
    'Papa',
    'Quebec',
    'Romeo',
    'Sierra',
    'Tango',
    'Uniform',
    'Victor',
    'Whiskey',
    'Xray',
    'Yankee',
    'Zulu',
];
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const pickAlpha = () => {
    return alphas[Math.floor(Math.random() * alphas.length)].toLowerCase();
};

const pickNumber = () => {
    return numbers[Math.floor(Math.random() * numbers.length)];
};

export const generateCode = () => {
    return `${pickAlpha()} ${pickNumber()} ${pickAlpha()} ${pickNumber()}`;
};
