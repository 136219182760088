import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '..';
import { apiAlertsGet, apiAlertDismiss } from '../../../api/api-alerts';
import { TourTypeContext } from '../../../contexts/tour-type-context';
import { formatDate } from '../../../helpers/helpers';

const Alerts = () => {
    const [alerts, setAlerts] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        setAlerts(await apiAlertsGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    if (!alerts || alerts.length === 0) {
        return null;
    }

    const dismiss = async (id) => {
        setAlerts(alerts.filter((x) => x.id !== id));

        await apiAlertDismiss(tourTypeId, id);
    };

    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 my-2">
            <PageTitle title="! Alerts !" />
            <Table slim>
                <tbody>
                    {alerts.map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell>{item.alertType}</TableCell>
                            <TableCell>{formatDate(item.date)}</TableCell>
                            <TableCell>{item.keys}</TableCell>
                            <TableCell>
                                <FontAwesomeIcon icon="broom" title="Delete Unused" onClick={() => dismiss(item.id)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default Alerts;
