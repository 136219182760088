import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useHistory, useParams } from 'react-router-dom';
import { apiG1BookingsGet } from '../../api/api-bookings';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { formatDate, formatDuration, formatTime } from '../../helpers/helpers';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';

export default function BookingList() {
    const [isLoading, setIsLoading] = useState(true);
    const [date, setDate] = useState(undefined);
    const [bookings, setBookings] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const { dateString } = useParams();
    const history = useHistory();
    const { tourTypeId } = useContext(TourTypeContext);

    useEffect(() => {
        const loadData = async () => {
            if (dateString) {
                localStorage.setItem('FocusDate', dateString);
            }

            const m = moment(dateString || localStorage.getItem('FocusDate') || undefined).startOf('day');
            setDate(m);
            setBookings(await apiG1BookingsGet(tourTypeId, m));

            setIsLoading(false);
        };

        loadData();
    }, [tourTypeId, dateString]);

    const PrivateDisplay = ({ tourIsPrivate, bookingIsPrivate, toutPrivate }) => {
        if (tourIsPrivate || bookingIsPrivate || toutPrivate) {
            const title = tourIsPrivate ? 'Private Tour' : bookingIsPrivate ? 'Private Booking' : toutPrivate ? 'Tout Private' : '';

            return <FontAwesomeIcon icon="lock-alt" className="mx-1" title={title} />;
        }
        return null;
    };

    const NotesDisplay = ({ tourNotes, bookingNotes }) => {
        if (tourNotes && bookingNotes) {
            return (
                <>
                    <FontAwesomeIcon icon="sticky-note" className="mx-1" title={bookingNotes} />
                    <FontAwesomeIcon icon="sticky-note" className="mx-1" title={tourNotes} />
                </>
            );
        }

        if (tourNotes || bookingNotes) {
            return <FontAwesomeIcon icon="sticky-note" className="mx-1" title={bookingNotes || tourNotes} />;
        }
        return null;
    };

    const ContactNumberIssueDisplay = ({ contactNumberIssue }) => {
        return contactNumberIssue ? <FontAwesomeIcon icon="comment-alt-exclamation" className="mx-1" danger title="Contact Number Issue" /> : null;
    };

    const SmsDisplay = ({ smsHold }) => {
        return smsHold ? <FontAwesomeIcon icon="comment-alt-times" className="mx-1" title="SMS Held" /> : null;
    };

    const PaidDisplay = ({ isPaid }) => {
        return isPaid ? null : <FontAwesomeIcon icon="pound-sign" className="mx-1" danger title="Unpaid" />;
    };

    const toursGet = () => {
        const tourIds = [...new Set(bookings.map((x) => x.tourId))];

        return tourIds.map((id) => {
            return bookings.find((x) => x.tourId === id);
        });
    };

    const tourSeats = (tourId) => {
        const seatsBooked = [];

        bookings
            .filter((x) => x.tourId === tourId)
            .forEach((b) => {
                if (b.seatsBooked) {
                    if (!b.bookingIsPrivate && !b.tourIsPrivate && !b.toutPrivate && b.seatsRequired && b.seatsRequired !== b.seatsBooked) {
                        seatsBooked.push(`${b.seatsBooked}/${b.seatsRequired}`);
                    } else {
                        seatsBooked.push(b.seatsBooked);
                    }
                }
            });

        return seatsBooked.join(' ');
    };

    const showCalendarIcon = () => {
        return showCalendar ? 'chevron-up' : 'chevron-down';
    };

    const handleDateChange = (e) => {
        const newDate = moment(e).format('YYYY-MM-DD');

        localStorage.setItem('FocusDate', newDate);
        history.push(`/bookings/${newDate}`);
    };

    return (
        <>
            {!isLoading && (
                <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
                    <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2 relative">
                        <PageTitle title={formatDate(date)} />
                        <div className="absolute top-6 right-4 cursor-pointer" onClick={() => setShowCalendar((x) => !x)}>
                            <FontAwesomeIcon icon={showCalendarIcon()} className="text-blue-700 hover:text-blue-800 fa-2x" />
                        </div>
                        {showCalendar && <Calendar onChange={handleDateChange} defaultValue={date.toDate()} locale="en-GB" className="inline" />}
                    </div>
                    <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                        <PageTitle title="Tours" />
                        <Table fixed>
                            <thead>
                                <tr>
                                    <TableCell text="Time" header className="w-1/12" />
                                    <TableCell text="Lane" header center className="w-1/12" />
                                    <TableCell text="Type" header className="w-1/12" />
                                    <TableCell text="Duration" header center className="w-1/12" />
                                    <TableCell text="Seats" header center className="w-1/12" />
                                    <TableCell text="Chauffeur" header />
                                    <TableCell text="" header className="w-2/12" />
                                </tr>
                            </thead>
                            <tbody>
                                {toursGet().map((item, key) => (
                                    <TableRow key={key} rowNum={key}>
                                        <TableCell text={formatTime(item.time)} />
                                        <TableCell center text={item.lane} />
                                        <TableCell>|{item.noOfSections}|</TableCell>
                                        <TableCell center text={formatDuration(item.duration)} />
                                        <TableCell center>
                                            <PrivateDisplay {...item} />
                                            {tourSeats(item.tourId)}
                                        </TableCell>
                                        <TableCell text={item.chauffeurName} />
                                        <TableCell right>
                                            <SmsDisplay {...item} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                        <PageTitle title="Bookings" />
                        <Table fixed>
                            <thead>
                                <tr>
                                    <TableCell text="Time" header className="w-1/12" />
                                    <TableCell text="Lane" header center className="w-1/12" />
                                    <TableCell text="Type" header className="w-1/12" />
                                    <TableCell text="Duration" header center className="w-1/12" />
                                    <TableCell text="Seats" header center className="w-1/12" />
                                    <TableCell text="Order Id" header />
                                    <TableCell text="Name" header />
                                    <TableCell text="Source" header className="hidden md:table-cell" />
                                    <TableCell text="" header className="w-2/12" />
                                </tr>
                            </thead>
                            <tbody>
                                {bookings
                                    .filter((x) => x.bookingId)
                                    .map((item, key) => (
                                        <TableRow key={key} rowNum={key}>
                                            <TableCell text={formatTime(item.time)} />
                                            <TableCell center text={item.lane} />
                                            <TableCell>|{item.noOfSections}|</TableCell>
                                            <TableCell center text={formatDuration(item.duration)} />
                                            <TableCell center>
                                                <PrivateDisplay {...item} />
                                                {item.seatsBooked}
                                                {!item.bookingIsPrivate && !item.toutPrivate && !item.tourIsPrivate && item.seatsRequired && item.seatsRequired !== item.seatsBooked && <span>/{item.seatsRequired}</span>}
                                            </TableCell>
                                            <TableCell>{item.orderId}</TableCell>
                                            <TableCell>
                                                {item.name}
                                                <NotesDisplay {...item} />
                                            </TableCell>
                                            <TableCell text={item.sourceName || item.sourceUserName} />
                                            <TableCell right>
                                                <ContactNumberIssueDisplay {...item} />
                                                <SmsDisplay {...item} />
                                                <PaidDisplay {...item} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </>
    );
}
