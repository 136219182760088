import React from 'react';
import { FontAwesomeIcon } from '.';

const Modal = ({ title, subtitle, children, show, hide, modalStyle = '', showClose = true }) => {
    return (
        <>
            {show && (
                <div className="fixed pin z-1000 overflow-auto bg-modal flex animated fadeIn">
                    <div className={`relative bg-white max-w-full m-auto flex-col flex animated fadeIn shadow-2xl sm:rounded-lg w-full md:w-4/5 ${modalStyle}`}>
                        <div className="p-2 relative mb-2 border-b-2 border-blue-700 pb-2">
                            {showClose && (
                                <div className="absolute top-3 right-2">
                                    <FontAwesomeIcon icon="times" onClick={hide} />
                                </div>
                            )}
                            <div className="text-xl text-blue-700 mr-20 uppercase tracking-tight font-extrabold whitespace-no-wrap">{title}</div>
                            {subtitle && <div className="text-xs text-blue-700 mr-20 uppercase tracking-tight font-extrabold whitespace-no-wrap">{subtitle}</div>}
                        </div>
                        <div className="px-2 mb-2">{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
