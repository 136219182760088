import axios from 'axios';

export const apiSourcesGet = async (tourTypeId, includeUsers = false) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Sources',
        params: { tourTypeId, includeUsers },
    });

    return result.data.sources.filter((x) => x.enabled);
};

export const apiChauffeursGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Glide1/Chauffeurs',
        params: { tourTypeId },
    });

    return result.data;
};
