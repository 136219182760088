import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiPriceGet = async (tourTypeId, tourDate, boughtDate, ticketCategoryId, ticketTypeId, discountCode) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/CalculatePrice',
        data: { tourTypeId, boughtDate: fixDate(boughtDate), tourDate: fixDate(tourDate), ticketCategoryId, discountCode, tickets: [{ ticketTypeId: ticketTypeId, amount: 1 }], theme: 'LGP' },
    });

    return result.data;
};
