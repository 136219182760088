import { useEffect } from 'react';
import { glide1Url } from '../../config';

const Glide1 = () => {
    useEffect(() => {
        const dateString = localStorage.getItem('FocusDate');

        if (dateString) {
            window.location.href = `${glide1Url()}glide1/${dateString}`;
        } else {
            window.location.href = glide1Url();
        }
    }, []);

    return null;
};

export default Glide1;
