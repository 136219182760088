import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiLastTourGet, apiLastTourSave } from '../../api/api-last-tours';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, minuteDefaults, lastTourTimeDefault } from '../../helpers/constants';
import { ButtonSubmit, InputCalendar, InputSelect, PageTitle } from '../common';
import { lastTourEditPageActions } from './page-actions';

const LastTourEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = Yup.object().shape({
        fromDate: Yup.string().required('Required'),
        lastTourTimeHour: Yup.string().required('Required'),
        lastTourTimeMinute: Yup.string().required('Required'),
    });

    useEffect(() => {
        const LoadData = async () => {
            if (id) {
                //Load the data
                var result = await apiLastTourGet(tourTypeId, id);
                result.fromDate = new Date(result.fromDate);
                setData(result);
            } else {
                //Defaults
                setData({
                    fromDate: new Date(),
                    lastTourTimeHour: lastTourTimeDefault.hour,
                    lastTourTimeMinute: lastTourTimeDefault.minute,
                });
            }
            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title={`${id ? 'Edit ' : 'New '}Last Tour Time`} actions={lastTourEditPageActions} />
            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        let lastTourTime = null;
                        if (values.lastTourTimeHour && values.lastTourTimeMinute) {
                            lastTourTime = `${values.lastTourTimeHour}:${values.lastTourTimeMinute}`;
                        }
                        await apiLastTourSave(tourTypeId, null, values.fromDate, lastTourTime);
                        history.push('/last-tours');
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <div>
                                <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">From Date</div>
                                <InputCalendar name="fromDate" minDate={new Date()} />
                            </div>
                            <div>
                                <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Last Tour Time</div>
                                <div className="flex gap-2">
                                    <InputSelect name="lastTourTimeHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                    <InputSelect name="lastTourTimeMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                </div>
                            </div>
                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};
export default LastTourEdit;
