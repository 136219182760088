import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiLastToursGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/LastTours',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiLastTourGet = async (tourTypeId, id) => {
    return (await apiLastToursGet(tourTypeId)).find((x) => x.id === parseInt(id));
};

export const apiLastTourSave = async (tourTypeId, id, fromDate, lastTourTime) => {
    await axios({
        method: 'post',
        url: '/api/v1/LastTour',
        data: { tourTypeId, id, fromDate: fixDate(fromDate), lastTourTime },
    });
};

export const apiLastTourDelete = async (tourTypeId, id) => {
    await axios({
        method: 'delete',
        url: '/api/v1/LastTour',
        data: { tourTypeId, id },
    });
};
