import React from 'react';

const ButtonSubmit = ({ isSubmitting, label = 'Save', className }) => {
    return (
        <button
            type="submit"
            disabled={isSubmitting}
            className={`disabled:opacity-50 w-full inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out ${className}`}>
            {label}
        </button>
    );
};

export default ButtonSubmit;
