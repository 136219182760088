import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiDiscountsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Discounts',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiDiscountGet = async (tourTypeId, id) => {
    const result = (await apiDiscountsGet(tourTypeId)).find((x) => x.id === parseInt(id));

    if (!result.ticketCategoryId) {
        result.ticketCategoryId = '';
    }

    if (!result.ticketTypeId) {
        result.ticketTypeId = '';
    }

    if (!result.validBeforeHour) {
        result.validBeforeHour = '';
    }

    if (!result.validBeforeMinute) {
        result.validBeforeMinute = '';
    }

    if (!result.validAfterHour) {
        result.validAfterHour = '';
    }

    if (!result.validAfterMinute) {
        result.validAfterMinute = '';
    }

    return result;
};

export const apiDiscountSave = async (
    tourTypeId,
    id,
    discountCode,
    discountAmount,
    boughtFrom,
    boughtTo,
    tourFrom,
    tourTo,
    ticketCategoryId,
    ticketTypeId,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    validBefore,
    validAfter,
    isDisabled
) => {
    if (!ticketTypeId) {
        ticketTypeId = null;
    }

    if (!ticketCategoryId) {
        ticketCategoryId = null;
    }

    if (!id) {
        id = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/Discount',
        data: {
            tourTypeId,
            id,
            discountCode,
            discountAmount,
            boughtFrom: fixDate(boughtFrom),
            boughtTo: fixDate(boughtTo),
            tourFrom: fixDate(tourFrom),
            tourTo: fixDate(tourTo),
            ticketCategoryId,
            ticketTypeId,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            validBefore,
            validAfter,
            isDisabled,
        },
    });
};
