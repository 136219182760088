import { apiUrl } from '../config';
import axios from 'axios';
import { apiLogout } from './api-auth';

export const configureAxios = () => {
    const UNAUTHORIZED = 401;
    const FORBIDDEN = 403;
    // const INTERNAL_SERVER_ERROR = 500;
    // const GATEWAY_TIMEOUT = 504;
    // const OK = 200;

    axios.interceptors.request.use((config) => {
        config.headers['Content-Type'] = 'application/json';
        config.baseURL = apiUrl();
        config.withCredentials = true;
        return config;
    });

    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (!error.response) {
                console.error(error);
                return Promise.reject(error);
            }

            const { status } = error.response;

            if (status === FORBIDDEN) {
                await apiLogout();

                if (window.location.pathname !== '/login') {
                    window.location.href = '/login';
                }
                return Promise.reject(error);
            }

            if (status === UNAUTHORIZED) {
                if (window.location.pathname !== '/login') {
                    window.location.href = '/login';
                }
                return Promise.reject(error);
            }

            if (status === 400) {
                console.warn(error.response);
                return Promise.reject(error.response);
            }

            console.error({ status, response: error.response });

            window.location.href = '/500';
            return Promise.reject(error);
        }
    );
};
