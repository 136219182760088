import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiBoltOnsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/BoltOns',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiBoltOnsGetUpcoming = async (tourTypeId, fromDate) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/BoltOns/Upcoming',
        params: { tourTypeId, fromDate: fixDate(fromDate) },
    });

    return result.data;
};

export const apiBoltOnsGetSupplierConfirmed = async (tourTypeId, fromDate) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/BoltOns/SupplierConfirmed',
        params: { tourTypeId, fromDate: fixDate(fromDate) },
    });

    return result.data;
};

export const apiBoltOnsSetSupplierConfirmed = async (tourTypeId, g2BookingId, boltOnId, amount, answer, confirmed) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/BoltOns/SupplierConfirmed',
        data: { tourTypeId, g2BookingId, boltOnId, amount, answer, confirmed },
    });

    return result.data;
};

export const apiBoltOnGet = async (tourTypeId, id) => {
    const result = (await apiBoltOnsGet(tourTypeId)).find((x) => x.id === parseInt(id));

    if (!result.validBeforeHour) {
        result.validBeforeHour = '';
    }

    if (!result.validBeforeMinute) {
        result.validBeforeMinute = '';
    }

    if (!result.validAfterHour) {
        result.validAfterHour = '';
    }

    if (!result.validAfterMinute) {
        result.validAfterMinute = '';
    }

    if (!result.ticketCategoryId) {
        result.ticketCategoryId = '';
    }

    if (!result.ticketTypeId) {
        result.ticketTypeId = '';
    }

    if (!result.companyId) {
        result.companyId = '';
    }

    return result;
};

export const apiBoltOnSave = async (
    tourTypeId,
    id,
    companyId,
    ticketCategoryId,
    ticketTypeId,
    boltOnName,
    tourFrom,
    tourTo,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    validBefore,
    validAfter,
    advertise,
    price,
    noticeRequiredHours,
    maxAllowed,
    minAllowed,
    limitToGroupSize,
    voucherTypeId,
    isDisabled,
    description,
    emailSnippet,
    question,
    questionMandatory,
    showInUpcoming,
    picnic,
    supplierConfirm,
    ticketTypeIds,
    excludedDates,
    alcohol,
    booklet
) => {
    if (!id) {
        id = null;
    }

    if (!companyId) {
        companyId = null;
    }

    if (excludedDates) {
        for (const idx in excludedDates) {
            excludedDates[idx].fromDate = fixDate(excludedDates[idx].fromDate);
            excludedDates[idx].toDate = fixDate(excludedDates[idx].toDate);
        }
    }

    try {
        await axios({
            method: 'post',
            url: '/api/v1/BoltOn',
            data: {
                tourTypeId,
                id,
                companyId,
                ticketCategoryId,
                ticketTypeId,
                boltOnName,
                tourFrom: fixDate(tourFrom),
                tourTo: fixDate(tourTo),
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                sunday,
                validBefore,
                validAfter,
                advertise,
                price,
                noticeRequiredHours,
                maxAllowed,
                minAllowed,
                limitToGroupSize,
                voucherTypeId,
                isDisabled,
                description,
                emailSnippet,
                question,
                questionMandatory,
                showInUpcoming,
                picnic,
                supplierConfirm,
                ticketTypeIds,
                excludedDates,
                alcohol,
                booklet,
            },
        });
        return { success: true };
    } catch (ex) {
        return { success: false, exception: ex };
    }
};
