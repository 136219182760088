import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { apiSeasonDateSave, apiSeasonsGet } from '../../api/api-seasons';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, InputCalendar, InputSelect, PageTitle } from '../common';
import { seasonDateEditPageActions } from './page-actions';

const SeasonDateAdd = () => {
    const [data, setData] = useState({});
    const { tourTypeId } = useContext(TourTypeContext);
    const [seasons, setSeasons] = useState([]);
    const history = useHistory();

    const formSchema = Yup.object().shape({
        fromSeasonId: Yup.string().required('Required'),
    });

    useEffect(() => {
        const LoadData = async () => {
            const seasonsResult = (await apiSeasonsGet(tourTypeId)).filter((x) => !x.isDisabled);

            setSeasons(seasonsResult);

            setData({
                fromSeasonId: undefined,
                fromDate: new Date(),
                toSeasonId: undefined,
                toDate: undefined,
            });
        };

        LoadData();
    }, [tourTypeId]);

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="New Season Date" actions={seasonDateEditPageActions} />

            <Formik
                initialValues={data}
                enableReinitialize
                validationSchema={formSchema}
                onSubmit={async (values) => {
                    await apiSeasonDateSave(tourTypeId, null, values.fromSeasonId, values.fromDate);

                    if (values.toSeasonId) {
                        await apiSeasonDateSave(tourTypeId, null, values.toSeasonId, values.toDate);
                    }

                    history.push('/');
                }}>
                {({ isSubmitting }) => (
                    <Form className="px-2 sm:px-0">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-2 mt-4">
                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="font-extrabold text-xl">Set To:</div>
                                <InputSelect name="fromSeasonId" items={seasons} textField="seasonName" optional />
                                <InputCalendar name="fromDate" />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="font-extrabold text-xl">After Set To:</div>
                                <InputSelect name="toSeasonId" items={seasons} textField="seasonName" optional />
                                <InputCalendar name="toDate" />
                            </div>
                            <ButtonSubmit isSubmitting={isSubmitting} className="sm:col-span-2" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SeasonDateAdd;
