import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiReviewsGet = async (tourTypeId, dateFrom, dateTo, reviewSmsStatus) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Reviews',
        params: { tourTypeId, dateFrom: fixDate(dateFrom), dateTo: fixDate(dateTo), reviewSmsStatus },
    });

    return result.data;
};

export const apiReviewsSave = async (tourTypeId, reviewsPostItems) => {
    await axios({
        method: 'post',
        url: '/api/v1/Reviews',
        data: { tourTypeId, reviewsPostItems },
    });
};
