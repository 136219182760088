import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiSpecialsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Specials',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiSpecialGet = async (tourTypeId, id) => {
    const result = (await apiSpecialsGet(tourTypeId)).find((x) => x.id === parseInt(id));

    if (!result.validBeforeHour) {
        result.validBeforeHour = '';
    }

    if (!result.validBeforeMinute) {
        result.validBeforeMinute = '';
    }

    if (!result.validAfterHour) {
        result.validAfterHour = '';
    }

    if (!result.validAfterMinute) {
        result.validAfterMinute = '';
    }

    return result;
};

export const apiSpecialSave = async (tourTypeId, id, specialName, tourFrom, tourTo, monday, tuesday, wednesday, thursday, friday, saturday, sunday, validBefore, validAfter, advertise, allowDiscounts, isDisabled, specialTicketPrices) => {
    if (!id) {
        id = null;
    }

    await axios({
        method: 'post',
        url: '/api/v1/Special',
        data: { tourTypeId, id, specialName, tourFrom: fixDate(tourFrom), tourTo: fixDate(tourTo), monday, tuesday, wednesday, thursday, friday, saturday, sunday, validBefore, validAfter, advertise, allowDiscounts, isDisabled, specialTicketPrices },
    });
};
