import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageTitle, Table, TableCell, TableRow } from '..';
import { apiSeasonTicketTypePriceIssues } from '../../../api/api-ticket-types';
import { TourTypeContext } from '../../../contexts/tour-type-context';

const TicketPriceIssues = () => {
    const [issues, setIssues] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        setIssues(await apiSeasonTicketTypePriceIssues(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    if (!issues || issues.length === 0) {
        return null;
    }

    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 my-2">
            <PageTitle title="! Ticket Price Issues !" />
            <Table slim>
                <tbody>
                    {issues.map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell>{item}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default TicketPriceIssues;
