import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiAffiliatesGet } from '../../api/api-affiliates';
import { apiTicketCategoriesGet, apiTicketTypeGet, apiTicketTypeSave, apiTicketTypesGet } from '../../api/api-ticket-types';
import { durations } from '../../config';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { companyList, hourDefaults, minuteDefaults } from '../../helpers/constants';
import { toJSDate } from '../../helpers/helpers';
import { ButtonSubmit, FontAwesomeIcon, InputCheckbox, InputDate, InputSelect, InputText, PageTitle } from '../common';
import { ticketTypeEditPageActions } from './page-actions';

const TicketTypeEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [ticketCategories, setTicketCategories] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [linkedTicketTypes, setLinkedTicketTypes] = useState([]);
    const [affiliates, setAffiliates] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();
    const { id, copyId } = useParams();

    const formSchema = Yup.object().shape({
        ticketTypeName: Yup.string().required('Required'),
        groupSize: Yup.string()
            .required('Required')
            .matches(/^[1-9][0-9]?$/, 'Invalid'),
        seatsRequired: Yup.string()
            .required('Required')
            .matches(/^[1-9][0-9]?$/, 'Invalid'),
        duration: Yup.string()
            .required('Required')
            .matches(/^[1-9][0-9][0-9]?$/, 'Invalid'),
        masterDiscount: Yup.string().when('masterId', {
            is: (x) => x,
            then: Yup.string()
                .matches(/^\d{1,2}(?:[.]\d{0,2})?$/, 'Invalid')
                .required('Required'),
            otherwise: Yup.string().length(0, 'Must Be Empty'),
        }),
    });

    useEffect(() => {
        const LoadData = async () => {
            setTicketCategories(await apiTicketCategoriesGet(tourTypeId));
            setAffiliates((await apiAffiliatesGet(tourTypeId)).filter((x) => !x.isDisabled));
            setTicketTypes((await apiTicketTypesGet(tourTypeId)).filter((x) => !x.isDisabled));
            if (tourTypeId === 3) {
                setLinkedTicketTypes((await apiTicketTypesGet(1)).filter((x) => !x.isDisabled));
            }

            const result = await apiTicketTypeGet(tourTypeId, id || copyId);
            result.fromDate = toJSDate(result.fromDate);
            result.toDate = toJSDate(result.toDate);
            result.masterId = result.masterId || '';
            result.masterDiscount = result.masterDiscount || '';

            companyList(tourTypeId).forEach((item) => {
                result[`company_${item.id}`] = result.companyIds?.includes(item.id);
            });

            setData(result);

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id, copyId]);

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title={copyId ? 'Copy Ticket Type' : 'Edit Ticket Type'} actions={ticketTypeEditPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        const companyIds = [];
                        companyList(tourTypeId).forEach((company) => {
                            if (values[`company_${company.id}`]) {
                                companyIds.push(company.id);
                            }
                        });
                        let excludeBefore = null;
                        if (values.excludeBeforeHour && values.excludeBeforeMinute) {
                            excludeBefore = `${values.excludeBeforeHour}:${values.excludeBeforeMinute}`;
                        }
                        let excludeAfter = null;
                        if (values.excludeAfterHour && values.excludeAfterMinute) {
                            excludeAfter = `${values.excludeAfterHour}:${values.excludeAfterMinute}`;
                        }

                        await apiTicketTypeSave(
                            tourTypeId,
                            id,
                            values.ticketCategoryId,
                            values.ticketTypeName,
                            values.seatsRequired,
                            values.groupSize,
                            values.duration,
                            values.fromDate,
                            values.toDate,
                            values.affiliateId,
                            false,
                            companyIds,
                            values.isDisabled,
                            copyId,
                            values.masterId,
                            values.masterDiscount,
                            values.excludeMonday,
                            values.excludeTuesday,
                            values.excludeWednesday,
                            values.excludeThursday,
                            values.excludeFriday,
                            values.excludeSaturday,
                            values.excludeSunday,
                            excludeBefore,
                            excludeAfter,
                            values.linkedTicketId
                        );

                        history.push('/ticket-types');
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <div className="md:grid md:grid-cols-2 md:gap-x-6">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <InputText name="ticketTypeName" label="Name" />
                                    <InputSelect name="ticketCategoryId" items={ticketCategories} textField="ticketCategoryName" optional label="Category" />
                                    <InputSelect name="affiliateId" items={affiliates} textField="affiliateName" optional label="Affiliate" />
                                    <InputSelect name="masterId" items={ticketTypes} textField="fullName" optional label="Master Price Ticket" />
                                    <InputText name="masterDiscount" label="Master Discount %" wrapperClassName="max-w-xxs" />
                                    <InputText name="groupSize" label="Group Size" wrapperClassName="max-w-xxs" />
                                    <InputText name="seatsRequired" label="Seats Required" wrapperClassName="max-w-xxs" />
                                    <InputSelect name="duration" items={durations} textField="mins" valueField="mins" optional label="Duration (Mins)" wrapperClassName="max-w-xxs" />
                                    <div className="">
                                        <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Valid Between</div>
                                        <div className="flex gap-2 items-center">
                                            <InputDate name="fromDate" />
                                            <FontAwesomeIcon icon="arrow-right" />
                                            <InputDate name="toDate" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="font-extrabold text-white bg-blue-600 mt-4 mb-2 py-2 -mx-4 md:mx-0 px-6">Companies</div>
                                    {companyList(tourTypeId).map((item, key) => (
                                        <InputCheckbox key={key} name={`company_${item.id}`} label={item.name} right />
                                    ))}
                                    <div className="font-extrabold text-white bg-blue-600 mt-4 mb-2 py-2 -mx-4 md:mx-0 px-6">Exclude</div>
                                    <div className="max-w-2xl">
                                        <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Days</div>

                                        <div className="grid grid-cols-4 sm:grid-cols-7 gap-4">
                                            <InputCheckbox name="excludeMonday" label="Mo" />
                                            <InputCheckbox name="excludeTuesday" label="Tu" />
                                            <InputCheckbox name="excludeWednesday" label="We" />
                                            <InputCheckbox name="excludeThursday" label="Th" />
                                            <InputCheckbox name="excludeFriday" label="Fr" />
                                            <InputCheckbox name="excludeSaturday" label="Sa" />
                                            <InputCheckbox name="excludeSunday" label="Su" />
                                        </div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="">
                                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">After</div>
                                            <div className="flex gap-2">
                                                <InputSelect name="excludeAfterHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                                <InputSelect name="excludeAfterMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Before</div>
                                            <div className="flex gap-2">
                                                <InputSelect name="excludeBeforeHour" items={hourDefaults} valueField="hour" textField="hour" optional />
                                                <InputSelect name="excludeBeforeMinute" items={minuteDefaults} valueField="minute" textField="minute" optional />
                                            </div>
                                        </div>
                                    </div>
                                    {tourTypeId === 3 && (
                                        <>
                                            <div className="font-extrabold text-white bg-blue-600 mt-4 mb-2 py-2 -mx-4 md:mx-0 px-6">Glide Linked Ticket Type</div>
                                            <InputSelect name="linkedTicketId" items={linkedTicketTypes} textField="fullName" optional />
                                        </>
                                    )}{' '}
                                </div>
                            </div>
                            <div className="font-extrabold border-b-2 border-blue-600 my-4 -mx-4"></div>
                            <InputCheckbox name="isDisabled" label="Disabled" />
                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-2" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default TicketTypeEdit;
