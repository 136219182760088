import moment from 'moment';
import 'moment/locale/en-gb';

export const getLocalStorageBool = (key, defaultValue) => {
    const value = localStorage.getItem(key);

    return value ? value === 'true' : defaultValue;
};

export const toJSDate = (date) => {
    if (date) {
        return new Date(date);
    }
    return date;
};

export const formatDate = (date) => {
    return moment(date).format('ddd Do MMM yyyy');
};

export const fixDate = (date) => {
    if (!date) {
        return null;
    }

    if (moment.isMoment(date)) {
        return date.format('YYYY-MM-DD');
    }

    if (moment(date).isValid()) {
        return moment(date).format('YYYY-MM-DD');
    }

    const m = moment([date.getFullYear(), date.getMonth(), date.getDate()]);

    return m.format('YYYY-MM-DD');
};

export const closestPriorDate = (dates) => {
    let foundDate = undefined;

    dates.forEach((date) => {
        const mDate = moment(date);

        if (mDate.isSameOrBefore()) {
            if (!foundDate) {
                foundDate = mDate;
            } else {
                if (mDate.isAfter(foundDate)) {
                    foundDate = mDate;
                }
            }
        }
    });

    return foundDate;
};

export const formatTime = (time) => {
    if (!time) {
        return null;
    }

    if (typeof time === 'string') {
        const m = moment(time, 'HH:mm:ss');

        return m.format('HH:mm');
    }

    if (moment.isMoment(time)) {
        return time.format('HH:mm');
    }

    if (moment(time).isValid()) {
        return moment(time).format('HH:mm');
    }

    return time;
};

export const formatDuration = (duration) => {
    switch (parseInt(duration)) {
        case 120:
            return '2h';
        case 60:
            return '1h';
        default:
            return `${duration}m`;
    }
};

export const roundToNearestXXMinutes = (start, roundTo) => {
    let remainder = roundTo - ((start.minute() + start.second() / 60) % roundTo);

    remainder = remainder > roundTo / 2 ? (remainder = -roundTo + remainder) : remainder;
    return moment(start).add(remainder, 'minutes').seconds(0);
};

export const range = (start, stop) => {
    return Array(Math.ceil(stop - start + 1))
        .fill(start)
        .map((x, y) => x + y);
};
