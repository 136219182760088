import React, { useCallback, useContext, useEffect, useState } from 'react';
import { apiBoltOnsGetSupplierConfirmed, apiBoltOnsSetSupplierConfirmed } from '../../api/api-bolt-ons';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';

const BoltOnSupplierConfirmed = () => {
    const [data, setData] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        setData(await apiBoltOnsGetSupplierConfirmed(tourTypeId, null));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    const setConfirmed = async (g2BookingId, boltOnId, amount, answer, confirmed) => {
        await apiBoltOnsSetSupplierConfirmed(tourTypeId, g2BookingId, boltOnId, amount, answer, confirmed);

        await LoadData();
    };

    const getStatus = (item) => {
        if (item.bookingDeleted) {
            return 'BOOKINGDELETED';
        }

        if (!item.g2BookingBoltOnId) {
            return 'BOLTONDELETED';
        }

        if (!item.supplierConfirmed) {
            return 'NOTCONFIRMED';
        }

        if ((item.amount || -99) !== (item.supplierConfirmedAmount || -99) || (item.answer || '') !== (item.supplierConfirmedAnswer || '')) {
            return 'OUTOFDATE';
        }

        return 'CONFIRMED';
    };

    const ActionButtons = ({ item }) => {
        switch (getStatus(item)) {
            case 'NOTCONFIRMED':
                return (
                    <FontAwesomeIcon icon="check" title="Confirm?" className=" cursor-pointer px-2 py-1 bg-white border rounded border-gray-200 w-full" onClick={() => setConfirmed(item.g2BookingId, item.boltOnId, item.amount, item.answer, true)} />
                );
            case 'OUTOFDATE':
                return (
                    <FontAwesomeIcon
                        icon="sliders-h"
                        title="Update Confirmation?"
                        className=" cursor-pointer px-2 py-1 bg-white border rounded border-gray-200 w-full"
                        onClick={() => setConfirmed(item.g2BookingId, item.boltOnId, item.amount, item.answer, true)}
                    />
                );
            case 'CONFIRMED':
            case 'BOOKINGDELETED':
            case 'BOLTONDELETED':
            default:
                return (
                    <FontAwesomeIcon
                        icon="times"
                        title="Unconfirm?"
                        className=" cursor-pointer px-2 py-1 bg-white border rounded border-gray-200 w-full"
                        onClick={() => setConfirmed(item.g2BookingId, item.boltOnId, item.amount, item.answer, false)}
                    />
                );
        }
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Bolt-Ons: Supplier Confirmed" />
            <Table>
                <thead>
                    <tr>
                        <TableCell text="Date" header />
                        <TableCell text="Time" header />
                        <TableCell text="Lanes" header />
                        <TableCell text="Source" header />
                        <TableCell text="Name" header />
                        <TableCell text="Bolt-On" header />
                        <TableCell text="Amount" right header />
                        <TableCell center header>
                            Action
                        </TableCell>
                    </tr>
                </thead>

                <tbody>
                    {data.map((item, key) => (
                        <TableRow key={key} rowNum={key} className={`${getStatus(item) === 'CONFIRMED' ? 'bg-green-200' : 'bg-red-200'}`}>
                            <TableCell className="tablecell-wrap-normal">{item.dateDisplay}</TableCell>
                            <TableCell className="tablecell-wrap-normal">{item.timeDisplay}</TableCell>
                            <TableCell className="tablecell-wrap-normal">{item.lanes}</TableCell>
                            <TableCell className="tablecell-wrap-normal">{item.companyId}</TableCell>
                            <TableCell className="tablecell-wrap-normal">{item.name}</TableCell>
                            <TableCell className="tablecell-wrap-normal">
                                {item.boltOnName}
                                {item.bookingDeleted && <div className="text-xs whitespace-no-wrap font-extrabold">Booking Deleted</div>}
                                {item.answer && <div className="text-xs">{item.answer}</div>}
                                {item.supplierConfirmedAnswer && item.answer !== item.supplierConfirmedAnswer && <div className="text-xs whitespace-no-wrap font-extrabold">Confirmed: {item.supplierConfirmedAnswer}</div>}
                            </TableCell>
                            <TableCell className="tablecell-wrap-normal" right>
                                {item.amount}
                                {item.supplierConfirmedAmount && item.amount !== item.supplierConfirmedAmount && <div className="text-xs whitespace-no-wrap font-extrabold">Confirmed: {item.supplierConfirmedAmount}</div>}
                            </TableCell>
                            <TableCell className="tablecell-wrap-normal" center action>
                                <ActionButtons item={item} />
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default BoltOnSupplierConfirmed;
