import { Form, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiPriceGet } from '../../api/api-pricing';
import { apiTicketTypesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, InputDate, InputSelect, InputText, PageTitle, Table, TableCell, TableRow } from '../common';
import moment from 'moment';

const PriceTester = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const [ticketTypes, setTicketTypes] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);
    const [results, setResults] = useState(undefined);
    const [timeslot, setTimeslot] = useState(undefined);

    const formSchema = Yup.object().shape({
        ticketTypeId: Yup.string().required('Required'),
        tourDate: Yup.string().required('Required'),
        boughtDate: Yup.string().required('Required'),
    });

    const LoadData = useCallback(async () => {
        const focusDate = localStorage.getItem('FocusDate');
        if (focusDate) {
            setData({
                ticketTypeId: '',
                discountCode: '',
                boughtDate: new Date(),
                tourDate: new Date(focusDate),
            });
        } else {
            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);

            setData({
                ticketTypeId: '',
                discountCode: '',
                boughtDate: new Date(),
                tourDate: tomorrow,
            });
        }

        const ticketTypesResult = await apiTicketTypesGet(tourTypeId);

        setTicketTypes(ticketTypesResult.filter((x) => !x.isDisabled && !x.ticketCategoryIsDisabled));

        setLoading(false);
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    const getTicketCategoryId = (ticketTypeId) => {
        return ticketTypes.find((x) => x.id === parseInt(ticketTypeId))?.ticketCategoryId;
    };

    const sortByPrice = (a, b) => {
        if (a.price > b.price) {
            return 1;
        }
        return -1;
    };

    const timeslotData = () => {
        return results.find((x) => x.timeSlot === timeslot)?.allTicketPrices.sort(sortByPrice);
    };

    const timeSlotClass = (itemTimeslot) => {
        if (timeslot === itemTimeslot) {
            return 'text-gray-50 bg-gray-600';
        }
        return 'text-gray-600 bg-gray-50 hover:bg-gray-300 hover:text-white';
    };

    const ticketTypeName = (ticketTypeId) => {
        return ticketTypes.find((x) => x.id === ticketTypeId)?.ticketTypeName;
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Price Tester" />
                {!loading && (
                    <Formik
                        initialValues={data}
                        enableReinitialize
                        validationSchema={formSchema}
                        onSubmit={async (values) => {
                            const priceGetResult = await apiPriceGet(tourTypeId, values.tourDate, values.boughtDate, getTicketCategoryId(values.ticketTypeId), values.ticketTypeId, values.discountCode);

                            if (priceGetResult.length > 0) {
                                setTimeslot(priceGetResult[0].timeSlot);
                            }

                            setResults(priceGetResult);
                        }}>
                        {({ isSubmitting }) => (
                            <Form className="px-2 sm:px-0">
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-2 gap-x-2 ">
                                    <InputSelect name="ticketTypeId" items={ticketTypes} textField="fullName" label="Ticket" optional wrapperClassName="sm:col-span-3" />
                                    <div>
                                        <div className="ml-2 mb-2 mt-4 leading-3 text-sm font-semibold">Tour Date</div>
                                        <div className="flex gap-2 items-center">
                                            <InputDate name="tourDate" label="Date" showClear={false} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ml-2 mb-2 mt-4 leading-3 text-sm font-semibold">Bought</div>
                                        <div className="flex gap-2 items-center">
                                            <InputDate name="boughtDate" label="Date" showClear={false} />
                                        </div>
                                    </div>
                                    <InputText name="discountCode" label="Promo Code" />
                                </div>
                                <ButtonSubmit isSubmitting={isSubmitting} className="mt-2" label="Test" />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
            {results && (
                <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mt-4">
                    <PageTitle title="Results" />
                    <div className="border-b-2 border-blue-700 -mt-2">
                        {results.length === 0 ? (
                            <div>No Matches Found</div>
                        ) : (
                            <>
                                <div className="border-b-2 border-blue-700 -mt-2">
                                    <div className="sm:hidden">
                                        <select aria-label="Selected tab" className="form-select block w-full" value={timeslot} onChange={(e) => setTimeslot(e.target.value)}>
                                            {results.map((item, key) => (
                                                <option key={key} value={item.timeSlot}>
                                                    {moment(item.timeSlot, 'HH:mm:ss').format('HH:mm')} - £{item.total.toFixed(2)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="hidden sm:block">
                                        <nav className="flex overflow-x-auto gap-x-2 justify-between">
                                            {results.map((item, key) => (
                                                <div onClick={() => setTimeslot(item.timeSlot)} key={key} className={`cursor-pointer px-3 py-2 font-medium text-sm leading-5 border  ${timeSlotClass(item.timeSlot)} focus:outline-none`}>
                                                    <div>{moment(item.timeSlot, 'HH:mm:ss').format('HH:mm')}</div>
                                                    <div>£{item.total.toFixed(2)}</div>
                                                </div>
                                            ))}
                                        </nav>
                                    </div>
                                </div>
                                <Table>
                                    <tbody>
                                        {timeslotData() &&
                                            timeslotData().map((item, key) => (
                                                <TableRow key={key} rowNum={key}>
                                                    <TableCell>{ticketTypeName(item.ticketTypeId)}</TableCell>
                                                    <TableCell>{item.description}</TableCell>
                                                    <TableCell className="text-right">£{item.price.toFixed(2)}</TableCell>
                                                </TableRow>
                                            ))}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PriceTester;
