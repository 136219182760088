import React from 'react';
import { PageTitle } from '../../common';

const AvailabilityDisplay = ({ results }) => {
    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mt-4">
            <PageTitle title="Availability" />
            <div className="border-b-2 border-blue-700 -mt-2">
                <div className="grid grid-cols-12 gap-2">
                    {results.day.dayLimits.map((dl, key) => (
                        <div className={`p-2 text-center flex-1 ${results.availability.find((x) => x.includes(`T${dl.timeSlot}`)) ? 'bg-green-200' : 'bg-red-200'}`} key={key}>
                            {dl.timeSlot.substring(0, 5)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AvailabilityDisplay;
