import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiSeasonDateGet, apiSeasonDateSave, apiSeasonsGet } from '../../api/api-seasons';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, InputCalendar, InputSelect, PageTitle } from '../common';
import { seasonDateEditPageActions } from './page-actions';

const SeasonDateEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { id } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const [seasons, setSeasons] = useState([]);
    const history = useHistory();

    const formSchema = Yup.object().shape({
        seasonId: Yup.string().required('Required'),
    });

    useEffect(() => {
        const LoadData = async () => {
            const seasonDate = await apiSeasonDateGet(tourTypeId, id);
            seasonDate.fromDate = new Date(seasonDate.fromDate);

            const seasonsResult = await apiSeasonsGet(tourTypeId);
            setSeasons(seasonsResult.filter((x) => !x.isDisabled || x.id === seasonDate?.seasonId));

            setData(seasonDate);

            setLoading(false);
        };

        LoadData();
    }, [id, tourTypeId]);

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Edit Season Date" actions={seasonDateEditPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        await apiSeasonDateSave(tourTypeId, id, values.seasonId, values.fromDate);

                        history.push('/');
                    }}>
                    {({ isSubmitting }) => (
                        <Form className="px-2 sm:px-0">
                            <div className="grid grid-cols-1 gap-y-2 gap-x-2 mt-4">
                                <InputSelect name="seasonId" items={seasons} textField="seasonName" />
                                <InputCalendar name="fromDate" />
                                <ButtonSubmit isSubmitting={isSubmitting} />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default SeasonDateEdit;
