import moment from 'moment';
import 'moment/locale/en-gb';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { apiSeasonDateDelete, apiSeasonDatesGet, apiSeasonsGet } from '../../api/api-seasons';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { closestPriorDate, formatDate } from '../../helpers/helpers';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';
import Issues from '../common/issues/issues';
import { seasonDatesPageActions, seasonsPageActions } from './page-actions';

const SeasonList = () => {
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);
    const { displayType } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const [anyDisabled, setAnyDisabled] = useState(false);

    const LoadData = useCallback(async () => {
        setData(await apiSeasonsGet(tourTypeId));

        setDates(await apiSeasonDatesGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        setAnyDisabled(data.filter((x) => x.isDisabled).length > 0);
    }, [data]);

    const seasonName = (id) => {
        return data.find((x) => x.id === id)?.seasonName ?? '';
    };

    const deleteSeasonDate = (id) => {
        swal({
            title: 'Are You Sure?',
            icon: 'warning',
            buttons: {
                no: { text: 'No', value: false },
                danger: { text: 'Yes', value: true },
            },
        }).then(async (value) => {
            if (value) {
                await apiSeasonDateDelete(tourTypeId, id);
                await LoadData();
            }
        });
    };

    const seasonsToDisplay = () => {
        if (displayType === 'disabled') {
            return data.filter((x) => x.isDisabled);
        } else {
            return data.filter((x) => !x.isDisabled);
        }
    };

    const seasonDatesToDisplay = () => {
        if (dates.length === 0) {
            return dates;
        }

        const displayAfter = closestPriorDate(dates.map((x) => x.fromDate));

        if (!displayAfter) {
            return dates;
        }

        return dates.filter((x) => moment(x.fromDate).isSameOrAfter(displayAfter));
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <Issues />
            <div className="md:grid md:grid-cols-2 md:gap-4">
                <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                    <PageTitle title="Seasons" actions={seasonsPageActions(displayType, anyDisabled)} />
                    <Table>
                        <tbody>
                            {seasonsToDisplay().map((item, key) => (
                                <TableRow key={key} rowNum={key}>
                                    <TableCell>
                                        {item.seasonName}
                                        {item.isDisabled ? ' (Disabled)' : ''}
                                    </TableCell>
                                    <TableCell actionLeft>
                                        <Link to={`/season/${item.id}`}>
                                            <FontAwesomeIcon icon="edit" title="Edit" />
                                        </Link>
                                    </TableCell>
                                    <TableCell actionRight>
                                        <Link to={`/season-availability/${item.id}`}>
                                            <FontAwesomeIcon icon="th" title="Availability" />
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                    <PageTitle title="Dates" actions={seasonDatesPageActions} />
                    <Table>
                        <tbody>
                            {seasonDatesToDisplay().map((item, key) => (
                                <TableRow key={key} rowNum={key}>
                                    <TableCell text={formatDate(item.fromDate)} />
                                    <TableCell text={seasonName(item.seasonId)} />
                                    <TableCell actionLeft>
                                        <Link to={`/season-date/${item.id}`}>
                                            <FontAwesomeIcon icon="edit" title="Edit" />
                                        </Link>
                                    </TableCell>
                                    <TableCell actionRight>
                                        <button onClick={() => deleteSeasonDate(item.id)}>
                                            <FontAwesomeIcon icon="trash-alt" title="Delete" />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default SeasonList;
