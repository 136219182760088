import React, { useEffect, useState } from 'react';
import { formatDuration, formatTime } from '../../helpers/helpers';
import { FontAwesomeIcon, Table, TableCell, TableRow } from '../common';
import Modal from '../common/modal';
import swal from 'sweetalert';

const BookingList = ({ show, tourId, matches, onBookingEdit, close }) => {
    const [tour, setTour] = useState(undefined);

    useEffect(() => {
        if (matches && tourId) {
            setTour(matches.find((x) => x.tourId === tourId));
        } else {
            setTour(undefined);
        }
    }, [tourId, matches]);

    const onEdit = (e, g2BookingId) => {
        if (g2BookingId) {
            onBookingEdit(e, null, g2BookingId);
        } else {
            swal({
                title: 'Glide 1 Booking',
                text: 'Please Edit in Glide 1',
                icon: 'warning',
            });
        }
    };

    return (
        <Modal show={show} hide={close} showClose title="Tour Bookings">
            {tour && (
                <>
                    <div className="sm:flex sm:justify-around">
                        <div>Lane: {tour.lane}</div>
                        <div>Time: {formatTime(tour.time)}</div>
                        <div>Duration: {formatDuration(tour.duration)}</div>
                    </div>
                    <div className="-mx-2 -mb-2">
                        <Table>
                            <thead>
                                <tr>
                                    <TableCell header text="Order Id" />
                                    <TableCell header text="Name" />
                                    <TableCell header text="Contact Number" className="hidden sm:table-cell" />
                                    <TableCell header center text="Seats" />
                                    <TableCell header center>
                                        <span className="text-brightpink fad fa-question-circle fa-lg" title="Provisional" />
                                    </TableCell>
                                    <TableCell header text="Paid" />
                                    <TableCell header text="" />
                                    <TableCell header text="" />
                                </tr>
                            </thead>
                            <tbody>
                                {matches &&
                                    matches
                                        .filter((x) => x.bookingId)
                                        .map((b, k) => (
                                            <TableRow rowNum={0} key={k}>
                                                <TableCell>{b.orderId}</TableCell>
                                                <TableCell>{b.name}</TableCell>
                                                <TableCell className="hidden sm:table-cell">{b.contactNumber}</TableCell>
                                                <TableCell center>
                                                    {b.seatsBooked}
                                                    {!tour.bookingIsPrivate && !tour.toutPrivate && !tour.tourIsPrivate && b.seatsRequired && b.seatsRequired !== b.seatsBooked && <span>/{b.seatsRequired}</span>}
                                                </TableCell>
                                                <TableCell center>{b.provisional && <span className="text-brightpink fad fa-question-circle fa-lg" title="Provisional" />}</TableCell>
                                                <TableCell>{b.isPaid ? 'Yes' : `Outstanding £${b.pricePaid.toFixed(2)}`}</TableCell>
                                                <TableCell center>
                                                    {!b.g2BookingId && <span className="rounded-full bg-gray-600 p-1 text-white">G1</span>}
                                                    {b.bookingNotes && <span className="fad fa-sticky-note fa-lg" title={b.bookingNotes}></span>}
                                                </TableCell>
                                                <TableCell actionRight>
                                                    <button onClick={(e) => onEdit(e, b.g2BookingId)}>
                                                        <FontAwesomeIcon icon="edit" title="Edit" />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default BookingList;
