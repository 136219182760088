import { FieldArray, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiBoltOnsGet } from '../../api/api-bolt-ons';
import { apiBookingSummaryContactNumberValidator, apiG2BookingDelete, apiG2BookingEdit, apiG2BookingGet } from '../../api/api-bookings';
import { apiSourcesGet } from '../../api/api-sources';
import { apiTicketCategoriesGet, apiTicketTypesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { companyList, paymentMethods, tourTypeIdPunting } from '../../helpers/constants';
import { Button, ButtonSubmit, ErrorDisplay, InputCheckbox, InputSelect, InputSpinner, InputText, Table, TableCell, TableRow, TextArea } from '../common';
import Modal from '../common/modal';

export default function BookingEdit({ show, tourId, g2BookingId, bookings, matches, close }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const [formattedContactNumber, setFormattedContactNumber] = useState(undefined);
    const [contactNumberValidated, setContactNumberValidated] = useState(false);
    const [tours, setTours] = useState(undefined);
    const [sources, setSources] = useState([]);
    const [ticketCategories, setTicketCategories] = useState(undefined);
    const [ticketTypes, setTicketTypes] = useState(undefined);
    const [boltOns, setBoltOns] = useState(undefined);
    const [error, setError] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            if (!show) {
                return;
            }

            const focusDate = localStorage.getItem('FocusDate') || '';
            const ticketTypesResult = (await apiTicketTypesGet(tourTypeId, new Date(focusDate))).filter((x) => !x.isDisabled);
            setTicketTypes(ticketTypesResult);

            const boltOnsResult = (await apiBoltOnsGet(tourTypeId)).filter((x) => !x.isDisabled);
            setBoltOns(boltOnsResult);

            let booking = {};

            if (g2BookingId) {
                if (!matches) {
                    console.error('No Matches');
                    return;
                }

                const g2Booking = await apiG2BookingGet(tourTypeId, g2BookingId);

                booking = {
                    g1Booking1: {
                        boatId: matches[0].tourId,
                        pricePaid: matches[0].pricePaid,
                        seatsBooked: matches[0].seatsBooked,
                        seatsRequired: matches[0].seatsRequired || '',
                        id: matches[0].bookingId,
                    },
                    g1Booking2: {
                        boatId: '',
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    g1Booking3: {
                        boatId: '',
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    g1Booking4: {
                        boatId: '',
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    affiliateId: g2Booking.affiliateId,
                    orderId: g2Booking.orderId,
                    name: matches[0].name,
                    ticketNumber: matches[0].ticketNumber,
                    source: `${matches[0].sourceId || 0}_${matches[0].sourceUserId || ''}`,
                    contactNumber: matches[0].contactNumber,
                    email: matches[0].email || '',
                    depositPaid: g2Booking.depositPaid || 0,
                    paymentMethod: matches[0].paymentMethod,
                    isPaid: matches[0].isPaid,
                    provisional: g2Booking.provisional,
                    ticketCategoryId: matches[0].ticketCategoryId,
                    companyId: g2Booking.companyId,
                    invoiceId: g2Booking.invoiceId || '',
                    happyToSitOpposite: true,
                    isPrivate: matches[0].bookingIsPrivate,
                    notes: matches[0].bookingNotes || '',
                    tickets: [],
                    boltOns: [],
                    sendEmail: false,
                    customBoltOnName: '',
                    customBoltOnPriceEach: '',
                    customBoltOnAmount: 0,
                    discountCode: g2Booking.discountCode || '',
                    discountAmount: g2Booking.discountAmount || '',
                };

                if (matches.length > 1) {
                    booking.g1Booking2.boatId = matches[1].tourId;
                    booking.g1Booking2.pricePaid = matches[1].pricePaid;
                    booking.g1Booking2.seatsBooked = matches[1].seatsBooked;
                    booking.g1Booking2.seatsRequired = matches[1].seatsRequired || '';
                    booking.g1Booking2.id = matches[1].bookingId;
                }

                if (matches.length > 2) {
                    booking.g1Booking3.boatId = matches[2].tourId;
                    booking.g1Booking3.pricePaid = matches[2].pricePaid;
                    booking.g1Booking3.seatsBooked = matches[2].seatsBooked;
                    booking.g1Booking3.seatsRequired = matches[2].seatsRequired || '';
                    booking.g1Booking3.id = matches[2].bookingId;
                }

                if (matches.length > 3) {
                    booking.g1Booking4.boatId = matches[3].tourId;
                    booking.g1Booking4.pricePaid = matches[3].pricePaid;
                    booking.g1Booking4.seatsBooked = matches[3].seatsBooked;
                    booking.g1Booking4.seatsRequired = matches[3].seatsRequired || '';
                    booking.g1Booking4.id = matches[3].bookingId;
                }

                booking.tickets = getTicketTypes(
                    ticketTypesResult,
                    matches[0].ticketCategoryId,
                    g2Booking.tickets.map((x) => x.ticketTypeId)
                );

                g2Booking.tickets
                    .filter((x) => x.amount > 0)
                    .forEach((t) => {
                        const found = booking.tickets.find((x) => x.ticketTypeId === t.ticketTypeId);

                        if (found) {
                            found.amount = t.amount;
                            found.priceEach = t.priceEach.toFixed(2);
                        } else {
                            booking.tickets.push({
                                ticketTypeId: t.ticketTypeId,
                                amount: t.amount,
                                priceEach: t.priceEach.toFixed(2),
                                ticketTypeName: 'Unknown',
                            });
                        }
                    });

                booking.boltOns = getBoltOns(boltOnsResult, matches[0].ticketCategoryId, g2Booking.companyId);

                g2Booking.boltOns
                    .filter((x) => x.amount > 0)
                    .forEach((b) => {
                        if (b.boltOnId) {
                            const found = booking.boltOns.find((x) => x.boltOnId === b.boltOnId);

                            if (found) {
                                found.amount = b.amount;
                                found.priceEach = b.priceEach.toFixed(2);
                                found.answer = b.answer;
                            } else {
                                booking.boltOns.push({
                                    boltOnId: b.boltOnId,
                                    amount: b.amount,
                                    priceEach: b.priceEach.toFixed(2),
                                    BoltOnName: 'Unknown',
                                    answer: b.answer,
                                });
                            }
                        } else {
                            booking.customBoltOnAmount = b.amount;
                            booking.customBoltOnPriceEach = b.priceEach;
                            booking.customBoltOnName = b.boltOnName;
                        }
                    });

                setData(booking);
            } else {
                booking = {
                    g1Booking1: {
                        boatId: tourId,
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    g1Booking2: {
                        boatId: '',
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    g1Booking3: {
                        boatId: '',
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    g1Booking4: {
                        boatId: '',
                        pricePaid: '',
                        seatsBooked: '',
                        seatsRequired: '',
                        id: '',
                    },
                    name: '',
                    ticketNumber: '',
                    source: '',
                    contactNumber: '',
                    email: '',
                    paymentMethod: '',
                    depositPaid: 0,
                    isPaid: false,
                    provisional: false,
                    ticketCategoryId: '',
                    companyId: '',
                    invoiceId: '',
                    happyToSitOpposite: true,
                    isPrivate: false,
                    tickets: [],
                    boltOns: [],
                    sendEmail: true,
                    customBoltOnName: '',
                    customBoltOnPriceEach: '',
                    customBoltOnAmount: 0,
                };
            }
            setData(booking);

            const availableTours = [];
            [...new Set(bookings.map((x) => x.tourId))].forEach((tourId) => {
                const matching = bookings.filter((x) => x.tourId === tourId);

                const isCurrent = matching.filter((x) => x.tourId === booking.g1Booking1.boatId || x.tourId === booking.g1Booking2.boatId || x.tourId === booking.g1Booking3.boatId || x.tourId === booking.g1Booking4.boatId).length > 0;

                const booked = matching.reduce(function (a, b) {
                    return a + b.seatsBooked;
                }, 0);

                let spaces = '';
                let allowOnSpace = true;
                if (tourTypeId === tourTypeIdPunting) {
                    if (booked >= 12) {
                        allowOnSpace = false;
                    }
                    if (!isCurrent) {
                        spaces = ` - Spaces: ${12 - booked}`;
                    }
                } else {
                    if (booked >= 20) {
                        allowOnSpace = false;
                    }
                    if (!isCurrent || !g2BookingId) {
                        spaces = ` - Spaces: ${20 - booked}`;
                    }
                }

                const isPrivate = matching.filter((x) => x.bookingIsPrivate || x.tourPrivate).length > 0;

                if ((!isPrivate && allowOnSpace) || isCurrent) {
                    availableTours.push({ tourId, tourName: `${matching[0].tourName}${spaces}` });
                }
            });
            setTours(availableTours);

            setSources((await apiSourcesGet(tourTypeId, true)).filter((x) => x.enabled));
            setTicketCategories((await apiTicketCategoriesGet(tourTypeId)).filter((x) => !x.isDisabled));

            setIsLoading(false);
        };

        loadData();
    }, [tourTypeId, tourId, g2BookingId, bookings, matches, show]);

    const doDeleteBooking = async () => {
        await apiG2BookingDelete(tourTypeId, g2BookingId);
        close(true);
    };

    const doDeleteBookingWithEmail = async () => {
        await apiG2BookingDelete(tourTypeId, g2BookingId, true);
        close(true);
    };

    const formSchema = Yup.object().shape({
        g1Booking1: Yup.object().shape({
            boatId: Yup.string().required('Required'),
            seatsBooked: Yup.number().typeError('Invalid').integer('Invalid').min(1, 'Min 1').required('Required'),
            seatsRequired: Yup.number().typeError('Invalid').integer('Invalid').min(1, 'Min 1'),
            pricePaid: Yup.string()
                .required('Required')
                .matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'),
        }),
        g1Booking2: Yup.object().shape({
            boatId: Yup.string(),
            seatsBooked: Yup.number()
                .typeError('Invalid')
                .integer('Invalid')
                .when('boatId', { is: (x) => x, then: (s) => s.required('Required').min(1, 'Min 1'), otherwise: (s) => s.test({ name: 'empty seats number', test: (y) => !y, message: 'Invalid' }) }),
            seatsRequired: Yup.number().typeError('Invalid').integer('Invalid').min(1, 'Min 1'),
            pricePaid: Yup.string().when('boatId', { is: (x) => x, then: (s) => s.required('Required').matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'), otherwise: (s) => s.matches(/^$/, 'Invalid') }),
        }),
        g1Booking3: Yup.object().shape({
            boatId: Yup.string(),
            seatsBooked: Yup.number()
                .typeError('Invalid')
                .integer('Invalid')
                .when('boatId', { is: (x) => x, then: (s) => s.required('Required').min(1, 'Min 1'), otherwise: (s) => s.test({ name: 'empty seats number', test: (y) => !y, message: 'Invalid' }) }),
            seatsRequired: Yup.number().typeError('Invalid').integer('Invalid').min(1, 'Min 1'),
            pricePaid: Yup.string().when('boatId', { is: (x) => x, then: (s) => s.required('Required').matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'), otherwise: (s) => s.matches(/^$/, 'Invalid') }),
        }),
        g1Booking4: Yup.object().shape({
            boatId: Yup.string(),
            seatsBooked: Yup.number()
                .typeError('Invalid')
                .integer('Invalid')
                .when('boatId', { is: (x) => x, then: (s) => s.required('Required').min(1, 'Min 1'), otherwise: (s) => s.test({ name: 'empty seats number', test: (y) => !y, message: 'Invalid' }) }),
            seatsRequired: Yup.number().typeError('Invalid').integer('Invalid').min(1, 'Min 1'),
            pricePaid: Yup.string().when('boatId', { is: (x) => x, then: (s) => s.required('Required').matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'), otherwise: (s) => s.matches(/^$/, 'Invalid') }),
        }),
        name: Yup.string().required('Required'),
        source: Yup.string().required('Required'),
        contactNumber: Yup.string()
            .required('Required')
            .test('contactNumberIsValid', 'Invalid mobile number', async (value) => {
                if (contactNumberValidated) {
                    return true;
                } else {
                    var result = await apiBookingSummaryContactNumberValidator(value);
                    setFormattedContactNumber(result.formattedContactNumber);
                    setContactNumberValidated(result.isValid);
                    return result.isValid;
                }
            }),
        email: Yup.string().email('Invalid'),
        depositPaid: Yup.string()
            .required('Required')
            .matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'),
        paymentMethod: Yup.string().required('Required'),
        ticketCategoryId: Yup.string().required('Required'),
        companyId: Yup.string().required('Required'),
        tickets: Yup.array().of(
            Yup.object().shape({
                priceEach: Yup.string()
                    .required('Required')
                    .matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'),
            })
        ),
        boltOns: Yup.array().of(
            Yup.object().shape({
                priceEach: Yup.string()
                    .required('Required')
                    .matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid'),
            })
        ),
        customBoltOnName: Yup.string().when('customBoltOnAmount', { is: (x) => x, then: (s) => s.required('Required') }),
        customBoltOnPriceEach: Yup.string().when('customBoltOnAmount', { is: (x) => x, then: (s) => s.required('Required').matches(/^\d{1,3}(?:[.]\d{0,2})?$/, 'Invalid') }),
    });

    const allowPrivate = (values) => {
        if (values.isPrivate) {
            return true;
        }

        let allow = true;

        if (values.g1Booking1.boatId) {
            if (bookings.filter((y) => y.tourId === parseInt(values.g1Booking1.boatId) && y.bookingId).length > 0) {
                allow = false;
            }
        }

        if (values.g1Booking2.boatId) {
            if (bookings.filter((y) => y.tourId === parseInt(values.g1Booking2.boatId) && y.bookingId).length > 0) {
                allow = false;
            }
        }

        if (values.g1Booking3.boatId) {
            if (bookings.filter((y) => y.tourId === parseInt(values.g1Booking3.boatId) && y.bookingId).length > 0) {
                allow = false;
            }
        }

        if (values.g1Booking4.boatId) {
            if (bookings.filter((y) => y.tourId === parseInt(values.g1Booking4.boatId) && y.bookingId).length > 0) {
                allow = false;
            }
        }

        return allow;
    };

    const getTicketTypes = (tt, ticketCategoryId, forceInclude = []) => {
        if (ticketCategoryId) {
            return tt
                .filter((x) => x.ticketCategoryId === ticketCategoryId && (!x.affiliateId || forceInclude.includes(x.id)))
                .map((x) => {
                    return {
                        ...x,
                        ticketTypeId: x.id,
                        priceEach: (x.fromPrice || 0).toFixed(2),
                        amount: 0,
                    };
                });
        } else {
            return [];
        }
    };

    const updateTicketTypes = (ticketCategoryId, setFieldValue) => {
        setFieldValue('tickets', getTicketTypes(ticketTypes, ticketCategoryId));
    };

    const getBoltOns = (b, ticketCategoryId, companyId) => {
        if (b) {
            return b
                .filter((x) => (!x.ticketCategoryId || x.ticketCategoryId === ticketCategoryId) && (!x.companyId || x.companyId === companyId))
                .map((x) => {
                    return {
                        ...x,
                        boltOnId: x.id,
                        priceEach: x.price.toFixed(2),
                        amount: 0,
                    };
                });
        } else {
            return [];
        }
    };

    const updateBoltOns = (ticketCategoryId, companyId, setFieldValue) => {
        setFieldValue('boltOns', getBoltOns(boltOns, ticketCategoryId, companyId));
    };

    const onTicketCategoryChange = (e, values, setFieldValue) => {
        updateTicketTypes(e.target.value ? parseInt(e.target.value) : undefined, setFieldValue);
        updateBoltOns(e.target.value ? parseInt(e.target.value) : undefined, values.companyId, setFieldValue);
    };

    const onCompanyChange = (e, values, setFieldValue) => {
        updateBoltOns(parseInt(values.ticketCategoryId), e.target.value ? e.target.value : undefined, setFieldValue);
    };

    const BoltOnRow = ({ item, keyNum }) => {
        if (item.question && item.amount > 0) {
            return (
                <>
                    <TableRow rowNum={keyNum + 1}>
                        <TableCell text={item.boltOnName} className="py-2 whitespace-normal" />
                        <TableCell className="py-2" center>
                            <InputText name={`boltOns.${keyNum}.priceEach`} inputClassName="text-right" />
                        </TableCell>
                        <TableCell className="py-2">
                            <InputSpinner name={`boltOns.${keyNum}.amount`} right max={99} />
                        </TableCell>
                    </TableRow>
                    <TableRow rowNum={keyNum + 1}>
                        <TableCell className="py-2" colSpan={3}>
                            {item.question}
                            <InputText name={`boltOns.${keyNum}.answer`} />
                        </TableCell>
                    </TableRow>
                </>
            );
        }

        return (
            <TableRow rowNum={keyNum + 1}>
                <TableCell text={item.boltOnName} className="py-2 whitespace-normal" />
                <TableCell className="py-2" center>
                    <InputText name={`boltOns.${keyNum}.priceEach`} inputClassName="text-right" />
                </TableCell>
                <TableCell className="py-2">
                    <InputSpinner name={`boltOns.${keyNum}.amount`} right max={99} />
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Modal show={show} hide={close} showClose title={g2BookingId ? 'Edit Booking' : 'Add Booking'}>
            {!isLoading && (
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={formSchema}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={async (values) => {
                        setError(undefined);

                        const boat1 = bookings.find((x) => x.tourId === parseInt(values.g1Booking1.boatId));

                        // If > 1 boat then they must be different and at the same time
                        if (values.g1Booking2.boatId) {
                            if (parseInt(values.g1Booking2.boatId) === parseInt(values.g1Booking1.boatId)) {
                                setError('Same Tour on multiple bookings!');
                                return;
                            }

                            const boat2 = bookings.find((x) => x.tourId === parseInt(values.g1Booking2.boatId));

                            if (boat1.time !== boat2.time) {
                                setError('Tours must be at the same time');
                                return;
                            }
                        }
                        if (values.g1Booking3.boatId) {
                            if (parseInt(values.g1Booking3.boatId) === parseInt(values.g1Booking1.boatId)) {
                                setError('Same Tour on multiple bookings!');
                                return;
                            }

                            const boat3 = bookings.find((x) => x.tourId === parseInt(values.g1Booking3.boatId));

                            if (boat1.time !== boat3.time) {
                                setError('Tours must be at the same time');
                                return;
                            }
                        }
                        if (values.g1Booking4.boatId) {
                            if (parseInt(values.g1Booking4.boatId) === parseInt(values.g1Booking1.boatId)) {
                                setError('Same Tour on multiple bookings!');
                                return;
                            }

                            const boat4 = bookings.find((x) => x.tourId === parseInt(values.g1Booking4.boatId));

                            if (boat1.time !== boat4.time) {
                                setError('Tours must be at the same time');
                                return;
                            }
                        }

                        if (!allowPrivate(values) && ticketCategories.find((x) => x.id === parseInt(values.ticketCategoryId))?.isPrivate) {
                            setError('Private Ticket Category Not Allowed');
                            return;
                        }

                        //Seats on tours and Seats on bookings must match
                        //Price on tours and ticket price must match
                        const bookingSeats = parseInt(values.g1Booking1.seatsBooked) + parseInt(values.g1Booking2?.seatsBooked || 0) + parseInt(values.g1Booking3?.seatsBooked || 0) + parseInt(values.g1Booking4?.seatsBooked || 0);
                        const bookingPrice = parseFloat(values.g1Booking1.pricePaid) + parseFloat(values.g1Booking2?.pricePaid || 0) + parseFloat(values.g1Booking3?.pricePaid || 0) + parseFloat(values.g1Booking4?.pricePaid || 0);
                        let ticketSeats = 0;
                        let ticketPrice = 0;
                        values.tickets.forEach((t) => {
                            const tAmount = parseInt(t.amount);
                            if (tAmount > 0) {
                                ticketSeats += tAmount * parseInt(t.groupSize);
                                ticketPrice += tAmount * parseFloat(t.priceEach);
                            }
                        });
                        if (bookingSeats !== ticketSeats) {
                            setError('Tours seats do not match ticket Seats');
                            return;
                        }
                        if (bookingPrice !== ticketPrice) {
                            setError('Tours price does not match ticket price');
                            return;
                        }

                        const isPrivate = allowPrivate(values) && values.isPrivate;

                        const boltOns = values.boltOns.filter((x) => x.boltOnId);
                        if (values.customBoltOnAmount) {
                            boltOns.push({ boltOnId: null, boltOnName: values.customBoltOnName, amount: values.customBoltOnAmount, priceEach: values.customBoltOnPriceEach });
                        }

                        const result = await apiG2BookingEdit(
                            tourTypeId,
                            g2BookingId,
                            boat1.date,
                            boat1.time,
                            values.g1Booking1,
                            values.g1Booking2,
                            values.g1Booking3,
                            values.g1Booking4,
                            values.source,
                            values.name,
                            values.ticketNumber,
                            formattedContactNumber,
                            values.email,
                            values.depositPaid,
                            values.paymentMethod,
                            isPrivate,
                            values.isPaid,
                            values.ticketCategoryId,
                            values.companyId,
                            values.invoiceId,
                            true,
                            values.notes,
                            values.tickets,
                            boltOns,
                            values.sendEmail,
                            values.provisional
                        );

                        if (result.successful) {
                            close(true);
                            return;
                        }

                        setError(result.error);
                    }}>
                    {({ values, isSubmitting, setFieldValue }) => (
                        <Form className="px-0 sm:px-2">
                            <div className="text-sm leading-3 font-semibold">Order Id: {data?.orderId && <span>{data.orderId}</span>}</div>
                            <div className="border-b border-gray-300 -mx-4">
                                <Table slim>
                                    <thead>
                                        <tr>
                                            <TableCell header text="Tour" />
                                            <TableCell header text="Seats" />
                                            <TableCell header>
                                                Spaces <div className="text-xs font-light hidden md:block">Only: DS and differs</div>
                                            </TableCell>
                                            <TableCell header text="Paid (£)" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableRow rowNum={0}>
                                            <TableCell className="py-1">
                                                <InputSelect name="g1Booking1.boatId" items={tours} textField="tourName" valueField="tourId" optional />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking1.seatsBooked" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking1.seatsRequired" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking1.pricePaid" inputClassName="text-right" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow rowNum={0}>
                                            <TableCell className="py-1">
                                                <InputSelect name="g1Booking2.boatId" items={tours} textField="tourName" valueField="tourId" optional />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking2.seatsBooked" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking2.seatsRequired" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking2.pricePaid" inputClassName="text-right" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow rowNum={0}>
                                            <TableCell className="py-1">
                                                <InputSelect name="g1Booking3.boatId" items={tours} textField="tourName" valueField="tourId" optional />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking3.seatsBooked" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking3.seatsRequired" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking3.pricePaid" inputClassName="text-right" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow rowNum={0}>
                                            <TableCell className="py-1">
                                                <InputSelect name="g1Booking4.boatId" items={tours} textField="tourName" valueField="tourId" optional />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking4.seatsBooked" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking4.seatsRequired" />
                                            </TableCell>
                                            <TableCell className="py-1">
                                                <InputText name="g1Booking4.pricePaid" inputClassName="text-right" />
                                            </TableCell>
                                        </TableRow>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="sm:grid sm:grid-cols-4 sm:gap-2">
                                <InputText name="name" label="Booking Name / Reference" />
                                <InputText name="contactNumber" label="Contact Number" onChange={() => setContactNumberValidated(false)} />
                                <InputText name="email" label="Email" />
                                <InputText name="invoiceId" label="Invoice Id" />
                                <InputSelect name="paymentMethod" items={paymentMethods} textField="method" valueField="method" optional label="Payment Method" />
                                <InputText name="depositPaid" inputClassName="text-right" label="Deposit Paid" />
                                {values.discountCode && <InputText name="discountCode" label="Discount Code" disabled />}
                                {values.discountAmount && <InputText name="discountAmount" label="Discount Amount (£)" disabled />}
                                <div className="flex justify-around sm:col-span-4">
                                    <div>{allowPrivate(values) && <InputCheckbox name="isPrivate" label="Private" horizontal={false} center />}</div>
                                    <InputCheckbox name="isPaid" label="Paid" horizontal={false} center />
                                    <InputCheckbox name="provisional" label="Provisional" horizontal={false} center colour="text-brightpink" className="text-brightpink" />
                                </div>
                                <InputSelect name="source" items={sources} textField="sourceName" valueField="sourceId" optional label="Source" />
                                <InputSelect
                                    name="ticketCategoryId"
                                    label="Ticket Category"
                                    items={ticketCategories}
                                    textField="ticketCategoryName"
                                    optional
                                    wrapperClassName="flex-grow"
                                    onChange={(e) => onTicketCategoryChange(e, values, setFieldValue)}
                                />
                                <InputSelect name="companyId" label="Company" items={companyList(tourTypeId)} textField="id" optional onChange={(e) => onCompanyChange(e, values, setFieldValue)} />
                            </div>
                            <div className="md:grid md:grid-cols-2 sm:mt-2">
                                <div className="m:pr-2">
                                    <FieldArray
                                        name="tickets"
                                        render={() => (
                                            <>
                                                {values.tickets.length > 0 && (
                                                    <Table>
                                                        <thead>
                                                            <TableRow>
                                                                <TableCell text="Ticket" header />
                                                                <TableCell text="£/ea" header center />
                                                                <TableCell header />
                                                            </TableRow>
                                                        </thead>
                                                        <tbody>
                                                            {values.tickets.map((item, key) => (
                                                                <TableRow key={key} rowNum={key}>
                                                                    <TableCell className="py-2 whitespace-normal">
                                                                        {item.ticketTypeName}
                                                                        {item.affiliateName && <span> - {item.affiliateName}</span>}
                                                                    </TableCell>
                                                                    <TableCell className="py-2" center>
                                                                        <InputText name={`tickets.${key}.priceEach`} inputClassName="text-right" />
                                                                    </TableCell>
                                                                    <TableCell className="py-2">
                                                                        <InputSpinner name={`tickets.${key}.amount`} right max={99} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="sm:pl-2">
                                    <FieldArray
                                        name="boltOns"
                                        render={() => (
                                            <>
                                                {values.boltOns.length > 0 && (
                                                    <Table>
                                                        <thead>
                                                            <TableRow>
                                                                <TableCell text="Extra" header />
                                                                <TableCell text="£/ea" header center />
                                                                <TableCell header />
                                                            </TableRow>
                                                        </thead>
                                                        <tbody>
                                                            <TableRow rowNum={0}>
                                                                <TableCell className="py-2 whitespace-normal">
                                                                    <InputText name="customBoltOnName" />
                                                                </TableCell>
                                                                <TableCell className="py-2" center>
                                                                    <InputText name="customBoltOnPriceEach" inputClassName="text-right" />
                                                                </TableCell>
                                                                <TableCell className="py-2">
                                                                    <InputSpinner name="customBoltOnAmount" right max={99} />
                                                                </TableCell>
                                                            </TableRow>
                                                            {values.boltOns.map((item, key) => (
                                                                <BoltOnRow item={item} keyNum={key} key={key} />
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <TextArea name="notes" label="Notes" rows="3" maxLength="1000" />
                            <InputCheckbox name="sendEmail" label="Send Email" horizontal />
                            <ErrorDisplay error={error} />
                            <div className="flex mt-2 gap-x-2">
                                {deleting ? (
                                    <>
                                        {data.affiliateId ? (
                                            <>
                                                <Button label="Yes, Delete" secondary onClick={doDeleteBooking} />
                                                <Button label="Yes, Delete & Email Affiliate" secondary onClick={doDeleteBookingWithEmail} />
                                            </>
                                        ) : (
                                            <div className="w-1/3">
                                                <Button label="Yes, Delete" secondary onClick={doDeleteBooking} />
                                            </div>
                                        )}
                                        <Button label="Cancel" onClick={() => setDeleting(false)} />
                                    </>
                                ) : (
                                    <>
                                        <ButtonSubmit isSubmitting={isSubmitting} />
                                        {g2BookingId && (
                                            <div className="w-1/3">
                                                <Button label="Delete" onClick={() => setDeleting(true)} secondary />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
}
