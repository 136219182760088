import axios from 'axios';

export const apiAlertsGet = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Alerts',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiAlertDismiss = async (tourTypeId, id) => {
    await axios({
        method: 'delete',
        url: '/api/v1/Alert',
        params: { tourTypeId, id },
    });
};
