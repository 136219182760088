import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiAvailabilityGet = async (tourTypeId, date, ticketCategoryId, tickets, happyToSitOpposite, beforeHour, beforeMinute, afterHour, afterMinute) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/Availability/Tester',
        data: { tourTypeId, Date: fixDate(date), ticketCategoryId, tickets, happyToSitOpposite, beforeHour, beforeMinute, afterHour, afterMinute },
    });

    return result.data;
};
