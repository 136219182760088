import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { apiDayGet, apiDaySave } from '../../api/api-day-availability';
import { apiPriceBandsGet, apiTicketCategoriesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { hourDefaults, minuteDefaults, maxGroupsList } from '../../helpers/constants';
import { formatDate } from '../../helpers/helpers';
import { Button, ButtonSubmit, FontAwesomeIcon, InputSelect, InputSpinner, InputText, PageTitle, Table, TableCell, TableRow } from '../common';
import { dayPageActions } from './page-actions';

const DayAvailability = () => {
    const [loading, setLoading] = useState(true);
    const { tourTypeId } = useContext(TourTypeContext);
    const [date, setDate] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [ticketCategories, setTicketCategories] = useState([]);
    const [priceBands, setPriceBands] = useState([]);
    const { dateString } = useParams();
    const history = useHistory();

    useEffect(() => {
        const LoadData = async () => {
            setDate(moment(dateString));
            setTicketCategories(await apiTicketCategoriesGet(tourTypeId));
            setPriceBands(await apiPriceBandsGet(tourTypeId, true));

            const current = await apiDayGet(tourTypeId, moment(dateString));

            current.addHour = 7;
            current.addMinute = 45;

            current.dayLimits.forEach((item) => {
                const m = moment(item.timeSlot, 'HH:mm:ss');
                const slot = m.format('HH:mm');

                item.timeSlot = slot;
                item.ticketCategoryId = item.ticketCategoryId || '';
                item.overrideMaxGroups = item.overrideMaxGroups || '';
            });

            current.tourConfigurations.forEach((config) => {
                const value = config?.max || 0;

                current[`configuration_${config.tourConfigurationId}`] = value;
            });

            setData(current);

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, dateString]);

    const findInsertIndex = (data, timeSlot) => {
        for (let i = 0; i < data.length; i++) {
            if (timeSlot <= data[i].timeSlot) {
                return i;
            }
        }
        return data.length;
    };

    const schema = Yup.object().shape({
        dayLimits: Yup.array().of(
            Yup.object().shape({
                seats: Yup.string()
                    .required('Required')
                    .matches(/^[0-9]{1,3}$/, 'Invalid'),
                priceBandId: Yup.number().required('Required'),
            })
        ),
    });

    return (
        <div className="my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4">
            {date && (
                <>
                    <PageTitle title={formatDate(date)} actions={dayPageActions} />

                    {!loading && (
                        <>
                            {data && (
                                <>
                                    <Formik
                                        initialValues={data}
                                        validationSchema={schema}
                                        enableReinitialize
                                        onSubmit={async (values) => {
                                            //Special "complex" validation
                                            // - Must not have 2 timeslots with the same ticketCategoryId
                                            const groupings = [];
                                            const issues = [];
                                            values.dayLimits.forEach((item) => {
                                                const key = `${item.timeSlot}:${item.ticketCategoryId}`;

                                                if (groupings.includes(key)) {
                                                    if (!issues.includes(item.timeSlot)) {
                                                        issues.push(item.timeSlot);
                                                    }
                                                } else {
                                                    groupings.push(key);
                                                }
                                            });

                                            if (issues.length > 0) {
                                                swal({
                                                    title: 'Validation Error',
                                                    text: `Duplicate Time/Limit To Found: ${issues.join(', ')}`,
                                                    icon: 'error',
                                                });
                                                return;
                                            }

                                            const tourConfigurations = [];
                                            data.tourConfigurations.forEach((config) => {
                                                tourConfigurations.push({ tourConfigurationId: config.tourConfigurationId, max: values[`configuration_${config.tourConfigurationId}`] });
                                            });

                                            await apiDaySave(tourTypeId, date, values.maxGroups, tourConfigurations, values.dayLimits);

                                            history.push(`/${dateString}`);
                                        }}>
                                        {({ values }) => (
                                            <Form>
                                                <div className="grid grid-cols-3 mb-4">
                                                    <InputSpinner name="maxGroups" min={0} max={99} label="Max Concurrent Tours" center />
                                                    {data.tourConfigurations.map((config, key) => (
                                                        <InputSpinner key={key} name={`configuration_${config.tourConfigurationId}`} min={0} max={99} label={`${config.tourConfigurationName} Max`} center />
                                                    ))}
                                                </div>
                                                <FieldArray
                                                    name="dayLimits"
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            <Table slim>
                                                                <thead>
                                                                    <tr>
                                                                        <TableCell text="Time" header center />
                                                                        <TableCell text="Seats" header center />
                                                                        <TableCell text="Price Band" header center />
                                                                        <TableCell text="Limit To" header />
                                                                        <TableCell header center>
                                                                            Max Tours <div className="text-xs hidden font-light md:block">Override</div>
                                                                        </TableCell>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {values.dayLimits.map((data, key) => (
                                                                        <TableRow key={key} rowNum={key}>
                                                                            <TableCell center>
                                                                                <span className="font-mono block md:inline">{data.timeSlot}</span>
                                                                                <FontAwesomeIcon icon="trash-alt" className="md:ml-2 text-red-600 text-sm" onClick={() => arrayHelpers.remove(key)} />
                                                                            </TableCell>
                                                                            <TableCell center>
                                                                                <InputText name={`dayLimits.${key}.seats`} wrapperClassName="md:w-20 mx-auto" />
                                                                            </TableCell>
                                                                            <TableCell center>
                                                                                <InputSelect name={`dayLimits.${key}.priceBandId`} items={priceBands} textField="priceBandName" optional />
                                                                            </TableCell>
                                                                            <TableCell center>
                                                                                <InputSelect name={`dayLimits.${key}.ticketCategoryId`} items={ticketCategories} textField="ticketCategoryName" optional />
                                                                            </TableCell>
                                                                            <TableCell center>
                                                                                <InputSelect name={`dayLimits.${key}.overrideMaxGroups`} items={maxGroupsList()} textField="value" valueField="value" optional />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                            <div className="py-2 mb-4">
                                                                <div className="sm:flex px-2 gap-2">
                                                                    <span className="self-center whitespace-no-wrap sm:ml-auto">Add Slot:</span>
                                                                    <InputSelect name="addHour" items={hourDefaults} valueField="hour" textField="hour" />
                                                                    <InputSelect name="addMinute" items={minuteDefaults} valueField="minute" textField="minute" />
                                                                    <span>
                                                                        <Button
                                                                            label="Add"
                                                                            onClick={() => {
                                                                                const timeSlot = moment(`${values.addHour}:${values.addMinute}`, 'HH:mm').format('HH:mm');

                                                                                arrayHelpers.insert(findInsertIndex(values.dayLimits, timeSlot), {
                                                                                    timeSlot: timeSlot,
                                                                                    seats: 0,
                                                                                    priceBandId: '',
                                                                                    ticketCategoryId: '',
                                                                                });
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                                <ButtonSubmit />
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default DayAvailability;
