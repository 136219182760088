import ReactExport from 'react-export-excel';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useContext, useState } from 'react';
import { Button, ButtonSubmit, ErrorDisplay, InputCheckbox, InputDate, PageTitle } from '../common';
import { apiReport } from '../../api/api-reports';
import { TourTypeContext } from '../../contexts/tour-type-context';
import ReportBoltOn from './report-bolt-on';
import ReportDashboard from './report-dasboard';
import ReportSingleUseDiscounts from './report-single-use-discounts';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Report = () => {
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);

    const formSchema = Yup.object().shape({
        purchasedFrom: Yup.date().nullable().typeError('Invalid'),
        tourFrom: Yup.date().nullable().typeError('Invalid'),
    });

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Sales Report" />

                <Formik
                    initialValues={{ puchasedFrom: '', tourFrom: '', includeDeleted: true, affiliateOnly: false }}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        setData(undefined);
                        setError(undefined);
                        if (!values.purchasedFrom && !values.tourFrom) {
                            setError('Please enter one of the dates');
                            return;
                        }

                        const result = await apiReport(tourTypeId, values.purchasedFrom, values.tourFrom, values.includeDeleted, values.affiliateOnly);

                        setData(result);
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="flex gap-2 items-center mb-1">
                                <div className="leading-3 text-sm font-semibold w-36">Purchased From:</div>
                                <InputDate name="purchasedFrom" />
                            </div>
                            <div className="flex gap-2 items-center mb-1">
                                <div className="leading-3 text-sm font-semibold w-36">Tour From:</div>
                                <InputDate name="tourFrom" />
                            </div>
                            <div>
                                <InputCheckbox label="Include Deleted" name="includeDeleted" />
                                <InputCheckbox label="Affiliates Only" name="affiliateOnly" />
                            </div>
                            <div className="mt-4">
                                <span className="font-semibold">Note:</span> Report runs for a month from the selected date.
                            </div>

                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" label="Run" />
                            <ErrorDisplay error={error} />
                        </Form>
                    )}
                </Formik>

                {data && (
                    <ExcelFile element={<Button label="Download" className="mt-2" />} filename="Glide Report">
                        <ExcelSheet data={data.reportRows} name="Data">
                            <ExcelColumn label="Tour Date" value={(col) => col.tourDate.substring(0, 10)} />
                            <ExcelColumn label="Tour Time" value="tourTime" />
                            <ExcelColumn label="Duration" value="duration" />
                            <ExcelColumn label="Lane" value="lane" />
                            <ExcelColumn label="Glide 1/2" value="glideVersion" />
                            <ExcelColumn label="Private" value={(col) => (col.isPrivate ? 'Yes' : 'No')} />
                            <ExcelColumn label="Chauffeur" value="chauffeurName" />
                            <ExcelColumn label="Created Date" value={(col) => col.createdDate.substring(0, 10)} />
                            <ExcelColumn label="Deleted By" value="deletedBy" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Ticket Number" value="ticketNumber" />
                            <ExcelColumn label="Contact Number" value="contactNumber" />
                            <ExcelColumn label="Email" value="email" />
                            <ExcelColumn label="Seats Booked" value="seatsBooked" />
                            <ExcelColumn label="Price Paid" value="pricePaid" />
                            <ExcelColumn label="Payment Method" value="paymentMethod" />
                            <ExcelColumn label="IsPaid" value={(col) => (col.isPaid ? 'Yes' : 'No')} />
                            <ExcelColumn label="Source" value="sourceName" />
                            <ExcelColumn label="Company" value="companyId" />
                            <ExcelColumn label="G2 Booking Id" value="g2BookingId" />
                            <ExcelColumn label="Order Id" value="orderId" />
                            <ExcelColumn label="Invoice Id" value="invoiceId" />
                            <ExcelColumn label="Affiliate" value="affiliateName" />
                            <ExcelColumn label="Total Ticket Price" value="totalTicketPrice" />
                            <ExcelColumn label="Total BoltOn Price" value="totalBoltOnPrice" />
                            <ExcelColumn label="Deposit Paid" value="depositPaid" />
                            <ExcelColumn label="Discount Code" value="discountCode" />
                            <ExcelColumn label="Tickets" value="tickets" />
                            <ExcelColumn label="Happy To Sit Opposite" value="happyToSitOpposite" />
                            <ExcelColumn label="BoltOns" value="boltOns" />
                            <ExcelColumn label="Vouchers" value="vouchers" />
                            <ExcelColumn label="Notes" value="notes" />
                        </ExcelSheet>
                    </ExcelFile>
                )}
            </div>
            <ReportBoltOn />
            <ReportDashboard />
            <ReportSingleUseDiscounts />
        </div>
    );
};

export default Report;
