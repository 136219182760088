import moment from 'moment';
import React from 'react';
import { ticketCategoryName } from '../../../helpers/reference-data-helpers';
import { PageTitle, Table, TableCell, TableRow } from '../../common';

const DayDisplay = ({ results, ticketCategories }) => {
    return (
        <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mt-4">
            <PageTitle title="Day" />
            <div className="border-b-2 border-blue-700 -mt-2">
                {results.day ? (
                    <div className="border-b-2 border-blue-700 -mt-2">
                        <div className="grid grid-cols-3">
                            <div className="bg-white overflow-hidden">
                                <div className="p-2">
                                    <dl className="text-center">
                                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate">Max Concurrent</dt>
                                        <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">{results.day.maxGroups}</dd>
                                    </dl>
                                </div>
                            </div>
                            {results.day.tourConfigurations.map((config, key) => (
                                <div key={key} className="bg-white overflow-hidden">
                                    <div className="p-2">
                                        <dl className="text-center">
                                            <dt className="text-sm leading-5 font-medium text-gray-500 truncate">Max {config.tourConfigurationName}</dt>
                                            <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">{config.max}</dd>
                                        </dl>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Table slim>
                            <thead>
                                <tr>
                                    <TableCell text="Time" header />
                                    <TableCell text="Seats" header center />
                                    <TableCell text="Limit To" header />
                                    <TableCell text="Max" header center />
                                </tr>
                            </thead>
                            <tbody>
                                {results.day.dayLimits.map((data, key) => (
                                    <TableRow key={key} rowNum={key}>
                                        <TableCell>
                                            <span className="font-mono block md:inline">{moment(data.timeSlot, 'HH:mm:ss').format('HH:mm')}</span>
                                        </TableCell>
                                        <TableCell center text={data.seats} />
                                        <TableCell text={ticketCategoryName(ticketCategories, data.ticketCategoryId)} />
                                        <TableCell center text={data.overrideMaxGroups} />
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div>No Day Found</div>
                )}
            </div>
        </div>
    );
};

export default DayDisplay;
