import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiSingleUseDiscountsGet } from '../../api/api-single-use-discounts';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';
import { listPageActions } from './page-actions';

const SingleUseDiscountList = () => {
    const [data, setData] = useState([]);
    const { displayType } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const [anyDisabled, setAnyDisabled] = useState(false);
    const [anyUsed, setAnyUsed] = useState(false);

    const LoadData = useCallback(async () => {
        setData(await apiSingleUseDiscountsGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        setAnyDisabled(data.filter((x) => x.isDisabled).length > 0);
        setAnyUsed(data.filter((x) => x.used).length > 0);
    }, [data]);

    const discountsToDisplay = () => {
        switch (displayType) {
            case 'disabled':
                return data.filter((x) => x.isDisabled);
            case 'used':
                return data.filter((x) => x.used);
            default:
                return data.filter((x) => !x.isDisabled && !x.used);
        }
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Single Use Promo Codes" actions={listPageActions(displayType, anyDisabled, anyUsed)} />
            <Table>
                <thead>
                    <tr>
                        <TableCell text="Description" header />
                        <TableCell text="Code" header />
                        <TableCell text="Discount" header right />
                        <TableCell text="When" header className="md:hidden" />
                        <TableCell text="Departing" header className="hidden md:table-cell" />
                        <TableCell text="Valid" header className="hidden md:table-cell" />
                        <TableCell text="" header />
                    </tr>
                </thead>

                <tbody>
                    {discountsToDisplay().map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell>
                                {item.singleUseDiscountName}
                                {item.isDisabled ? ' (Disabled)' : ''}
                            </TableCell>
                            <TableCell>{item.code}</TableCell>
                            <TableCell right>£{item.amount}</TableCell>
                            <TableCell className="md:hidden">
                                {item.tourDisplay && (
                                    <div className="my-1">
                                        <FontAwesomeIcon icon="calendar-day fa-fw" />
                                        <span className="ml-2">{item.tourDisplay}</span>
                                    </div>
                                )}
                                {item.validDisplay && (
                                    <div className="my-1">
                                        <FontAwesomeIcon icon="th fa-fw" />
                                        <span className="ml-2">{item.validDisplay}</span>
                                    </div>
                                )}
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                                {item.tourDisplay && (
                                    <div className="my-1">
                                        <FontAwesomeIcon icon="calendar-day" />
                                        <span className="ml-2">{item.tourDisplay}</span>
                                    </div>
                                )}
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                                {item.validDisplay && (
                                    <div className="my-1">
                                        <FontAwesomeIcon icon="th" />
                                        <span className="ml-2">{item.validDisplay}</span>
                                    </div>
                                )}
                            </TableCell>
                            <TableCell action>
                                <Link to={`/single-use-discount/${item.id}`}>
                                    <FontAwesomeIcon icon="edit" title="Edit" />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default SingleUseDiscountList;
