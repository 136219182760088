import React from 'react';
import { groupColours } from '../../../helpers/constants';

const Groups = ({ tourId, matching, isFull, isPrivate, isHistoric, onBookingEdit, onBookingList }) => {
    return (
        <>
            {matching
                .filter((x) => x.seatsBooked)
                .map((m, k) => {
                    let groupColour = groupColours.other;
                    if (m.sourceName) {
                        if (m.sourceName.toLowerCase().includes('chinese')) {
                            groupColour = groupColours.chinese;
                        } else if (m.sourceName === 'TPC') {
                            groupColour = groupColours.tpc;
                        } else if (m.sourceName === 'LGP') {
                            groupColour = groupColours.lgp;
                        } else if (m.sourceName === 'STRIDE') {
                            groupColour = groupColours.stride;
                        }
                    }

                    return (
                        <span key={k} className={`${groupColour} px-0.5 my-0.5 ml-0.5 border border-black text-sm first:rounded-tl-lg`} title="View Groups" onClick={(e) => onBookingList(e, tourId)}>
                            {m.seatsBooked}
                            {!isPrivate && m.seatsRequired && m.seatsRequired !== m.seatsBooked && <span>/{m.seatsRequired}</span>}
                        </span>
                    );
                })}
            {!isFull && !isPrivate && !isHistoric && (
                <div className={`${groupColours.other} text-center my-0 p-0 w-6 ml-0.5 border border-black text-sm first:rounded-tl-lg cursor-pointer`} title="Add Group" onClick={(e) => onBookingEdit(e, tourId)}>
                    +
                </div>
            )}
        </>
    );
};

export default Groups;
