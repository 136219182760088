export const tourTypeIdPunting = 1;
export const tourTypeIdMillPond = 3;

export const tourTypes = [
    { id: 1, tourTypeName: 'Punting', fromLane: 1, toLane: 12, coreFromLane: 1, coreToLane: 8, coreFromHour: 10, coreToHour: 20, maxTicketsPerBookingMax: 12 },
    { id: 2, tourTypeName: 'Walking', fromLane: 100, toLane: 110, coreFromLane: 100, coreToLane: 102, coreFromHour: 10, coreToHour: 18, maxTicketsPerBookingMax: 20 },
    { id: 3, tourTypeName: 'Mill Pond', fromLane: 50, toLane: 60, coreFromLane: 50, coreToLane: 54, coreFromHour: 10, coreToHour: 20, maxTicketsPerBookingMax: 12 },
];

export const lanesGet = (tourTypeId) => {
    const tt = tourTypes.find((x) => x.id === tourTypeId);

    const lanes = [];

    for (var j = tt.fromLane; j <= tt.toLane; j++) {
        lanes.push({ lane: j });
    }

    return lanes;
};

export const noOfSectionsGet = (tourTypeId) => {
    switch (tourTypeId) {
        case tourTypeIdPunting:
        case tourTypeIdMillPond: 
            return [{ value: 2 }, { value: 3 }];
        default:
            return [{ value: 1 }];
    }
};

export const companyList = (tourTypeId) => {
    if (tourTypeId === 2) {
        return [
            { id: 'STRIDE', name: 'Stride' },
            { id: 'GLIDE', name: 'Glide' },
        ];
    }
    return [
        { id: 'LGP', name: "Let's Go Punting" },
        { id: 'TPC', name: 'Traditional Punting Company' },
        { id: 'STRIDE', name: 'Stride' },
        { id: 'GLIDE', name: 'Glide' },
        { id: 'HEN', name: 'Cambridge Hen Party' },
        { id: 'TYRRELLS', name: `Tyrrell's` },
    ];
};

export const paymentMethods = [{ method: 'Cash' }, { method: 'Card' }, { method: 'Cash and Card' }, { method: 'Alipay' }, { method: 'Website' }, { method: 'Phone' }, { method: 'Invoice' }, { method: '3rd Party' }, { method: 'Reschedule' }];

export const hourDefaults = [
    { hour: 0, amPm: '0am' },
    { hour: 1, amPm: '1am' },
    { hour: 2, amPm: '2am' },
    { hour: 3, amPm: '3am' },
    { hour: 4, amPm: '4am' },
    { hour: 5, amPm: '5am' },
    { hour: 6, amPm: '6am' },
    { hour: 7, amPm: '7am' },
    { hour: 8, amPm: '8am' },
    { hour: 9, amPm: '9am' },
    { hour: 10, amPm: '10am' },
    { hour: 11, amPm: '11am' },
    { hour: 12, amPm: '12noon' },
    { hour: 13, amPm: '1pm' },
    { hour: 14, amPm: '2pm' },
    { hour: 15, amPm: '3pm' },
    { hour: 16, amPm: '4pm' },
    { hour: 17, amPm: '5pm' },
    { hour: 18, amPm: '6pm' },
    { hour: 19, amPm: '7pm' },
    { hour: 20, amPm: '8pm' },
    { hour: 21, amPm: '9pm' },
    { hour: 22, amPm: '10pm' },
    { hour: 23, amPm: '11pm' },
];

export const minuteDefaults = [{ minute: 0 }, { minute: 15 }, { minute: 30 }, { minute: 45 }];

export const lastTourTimeDefault = { hour: 17, minute: 0 };

export const groupColours = {
    chinese: 'bg-yellow-300 text-black',
    tpc: 'bg-gray-600 text-gray-50',
    lgp: 'bg-blue-600 text-blue-50',
    stride: 'bg-orange-500 text-orange-50',
    other: 'bg-white text-black',
};

export const colours = {
    provisional: {
        background: 'bg-brightpink',
        border: 'border-pink-600',
    },
    chinese: {
        background: 'bg-yellow-400',
        border: 'border-yellow-500',
    },
    private: {
        background: 'bg-purple-500',
        border: 'border-purple-600',
    },
    full: {
        background: 'bg-red-500',
        border: 'border-red-600',
    },
    open: {
        background: 'bg-green-400',
        border: 'border-green-500',
    },
};

export const maxGroupsList = () => {
    return Array(8)
        .fill()
        .map((_, i) => {
            return { value: i + 1 };
        });
};

export const showInUpcomingOptions = [
    { id: 'A', name: 'All' },
    { id: '2', name: 'Glide 2' },
    { id: 'N', name: 'None' },
];
