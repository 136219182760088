import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { apiAffiliateGet, apiAffiliateSave } from '../../api/api-affiliates';
import { apiSourcesGet } from '../../api/api-sources';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { companyList } from '../../helpers/constants';
import { generateCode } from '../../helpers/verification-code-helpers';
import { ButtonSubmit, InputCheckbox, InputSelect, InputText, PageTitle } from '../common';
import { editPageActions } from './page-actions';

const AffiliateEdit = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [editorRef, setEditorRef] = useState(null);
    const [image, setImage] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [uploaded, setUploaded] = useState(false);
    const [sources, setSources] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();
    const { id } = useParams();

    const formSchema = Yup.object().shape({
        affiliateName: Yup.string().required('Required'),
        affiliateEmail: Yup.string().email('Invalid').required('Required'),
        affiliateEmail2: Yup.string().nullable().email('Invalid'),
        companyId: Yup.string().required('Required'),
        sourceId: Yup.string().required('Required'),
        verificationCode: Yup.string().required('Required'),
    });

    useEffect(() => {
        const LoadData = async () => {
            setSources(await apiSourcesGet(tourTypeId));

            if (id) {
                setData(await apiAffiliateGet(tourTypeId, id));
            } else {
                //Defaults
                setData({
                    affiliateName: '',
                    affiliateEmail: '',
                    affiliateEmail2: '',
                    companyId: '',
                    verificationCode: generateCode(),
                    isDisabled: false,
                });
            }

            setLoading(false);
        };

        LoadData();
    }, [tourTypeId, id]);

    const regenCode = (setFieldValue) => {
        setFieldValue('verificationCode', generateCode());
    };

    const handleNewImage = (e) => {
        setImage(e.target.files[0]);
        setUploaded(true);
    };

    const handleScale = (e) => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };

    const rotateLeft = (e) => {
        e.preventDefault();

        setRotate(rotate - 90);
    };

    const rotateRight = (e) => {
        e.preventDefault();

        setRotate(rotate + 90);
    };

    const setRef = (editor) => {
        if (editor) setEditorRef(editor);
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Affiliates" actions={editPageActions} />

            {!loading && (
                <Formik
                    initialValues={data}
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        const base64String = uploaded ? editorRef.getImageScaledToCanvas().toDataURL() : null;

                        await apiAffiliateSave(tourTypeId, id, values.affiliateName, values.affiliateEmail, values.affiliateEmail2, values.companyId, values.sourceId, values.verificationCode, base64String, values.isDisabled);

                        history.push('/affiliates');
                    }}>
                    {({ setFieldValue, isSubmitting }) => (
                        <Form>
                            <InputText name="affiliateName" label="Name" />
                            <InputText name="affiliateEmail" label="Email" />
                            <InputText name="affiliateEmail2" label="Email (Secondary)" />
                            <InputSelect name="companyId" label="Company" items={companyList(-1)} optional />
                            <InputSelect name="sourceId" label="Source" items={sources} textField="sourceName" optional />
                            <InputText name="verificationCode" label="Verification Code (enter without the spaces)" disabled postIcon="sync-alt" postClick={() => regenCode(setFieldValue)} />

                            {data.photoUrl && (
                                <div>
                                    <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Logo</div>
                                    <AvatarEditor image={`${data.photoUrl}?q=${new Date().getTime()}`} width={200} height={200} border={1} scale={1} rotate={0} />
                                </div>
                            )}

                            <div>
                                <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">Change Logo</div>
                                <div className="flex flex-row gap-x-4 items-center">
                                    <AvatarEditor ref={setRef} image={image} width={200} height={200} border={1} scale={scale} rotate={rotate} />
                                    <div>
                                        <div>Zoom:</div>
                                        <div>
                                            <input name="scale" type="range" onChange={handleScale} min="0.2" max="3" step="0.01" defaultValue="1" />
                                        </div>
                                        <div>Rotate:</div>
                                        <div className="space-x-2">
                                            <span className="fad fa-chevron-left fa-2x cursor-pointer" onClick={rotateLeft}></span>
                                            <span className="fad fa-chevron-right fa-2x cursor-pointer" onClick={rotateRight}></span>
                                        </div>
                                        <div>
                                            <input name="newImage" type="file" onChange={handleNewImage} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <InputCheckbox name="isDisabled" label="Disabled" className="mt-4" />

                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" />
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default AffiliateEdit;
