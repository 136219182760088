import { useField, useFormikContext } from 'formik';
import React from 'react';

const InputSpinner = ({ label, max, min = 0, total, wrapperClassName, right, center, ...props }) => {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();

    const upClass = field.value < max && (total || field.value) < max ? 'cursor-pointer text-gray-800' : 'text-transparent';
    const downClass = field.value > min ? 'cursor-pointer text-gray-800 fa-flip-vertical' : 'text-transparent';

    const up = () => {
        if (field.value === max) {
            return;
        }

        setFieldValue(field.name, field.value + 1);
    };

    const down = () => {
        if (field.value === 0) {
            return;
        }

        if (field.value === min) {
            return;
        }

        setFieldValue(field.name, field.value - 1);
    };

    let justifyClass = 'justify-start';

    if (center) {
        justifyClass = 'justify-center';
        wrapperClassName = `text-center ${wrapperClassName}`;
    } else if (right) {
        justifyClass = 'justify-end';
        wrapperClassName = `text-right ${wrapperClassName}`;
    }

    return (
        <div className={wrapperClassName}>
            {label && <div className="ml-2 mt-4 mb-2 leading-3 text-sm font-semibold">{label}</div>}
            <div className={`flex items-center ${justifyClass}`}>
                <span className={`fas fa-lg fa-minus mr-2 ${downClass}`} onClick={down} />
                <div className="leading-3 text-lg font-semibold w-6 text-center">{field.value}</div>
                <span className={`fas fa-lg fa-plus ml-2 ${upClass}`} onClick={up} />
            </div>
        </div>
    );
};

export default InputSpinner;
