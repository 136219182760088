import React, { useContext, useState } from 'react';
import ReactExport from 'react-export-excel';
import { apiReportSingleUseDiscounts } from '../../api/api-reports';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { Button, PageTitle } from '../common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ReportSingleUseDiscounts = () => {
    const [data, setData] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);

    const runReport = async () => {
        const result = await apiReportSingleUseDiscounts(tourTypeId);

        setData(result);
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Single Use Promo Codes Report" />

                <Button onClick={runReport} className="mt-4" label="Run" />

                {data && (
                    <ExcelFile element={<Button label="Download" className="mt-2" />} filename="Single Use Discounts Report">
                        <ExcelSheet data={data.reportRows} name="Data">
                            <ExcelColumn label="Name" value="singleUseDiscountName" />
                            <ExcelColumn label="Code" value="code" />
                            <ExcelColumn label="Batch Id" value="batchId" />
                            <ExcelColumn label="Amount (£)" value="amount" />

                            <ExcelColumn label="Used" value={(col) => (col.used ? 'Yes' : '')} />
                            <ExcelColumn label="Tour Type" value="tourTypeName" />
                            <ExcelColumn label="Ticket Category" value="ticketCategoryName" />
                            <ExcelColumn label="Monday" value={(col) => (col.monday ? 'Yes' : '')} />
                            <ExcelColumn label="Tuesday" value={(col) => (col.tuesday ? 'Yes' : '')} />
                            <ExcelColumn label="Wednesday" value={(col) => (col.wednesday ? 'Yes' : '')} />
                            <ExcelColumn label="Thursday" value={(col) => (col.thursday ? 'Yes' : '')} />
                            <ExcelColumn label="Friday" value={(col) => (col.friday ? 'Yes' : '')} />
                            <ExcelColumn label="Saturday" value={(col) => (col.saturday ? 'Yes' : '')} />
                            <ExcelColumn label="Sunday" value={(col) => (col.sunday ? 'Yes' : '')} />
                            <ExcelColumn label="Valid Before" value="validBefore" />
                            <ExcelColumn label="Valid After" value="validAfter" />
                            <ExcelColumn label="Tour From" value={(col) => col?.tourFrom?.substring(0, 10)} />
                            <ExcelColumn label="Tour To" value={(col) => col?.tourTo?.substring(0, 10)} />
                            <ExcelColumn label="Disabled" value={(col) => (col.isDisabled ? 'Yes' : '')} />
                            <ExcelColumn label="Tour Date" value={(col) => col?.date?.substring(0, 10)} />
                            <ExcelColumn label="Tour Time" value="time" />
                            <ExcelColumn label="Lane" value="lane" />
                            <ExcelColumn label="Private" value={(col) => (col.isPrivate ? 'Yes' : '')} />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Order Id" value="orderId" />
                            <ExcelColumn label="Payment Method" value="paymentMethod" />
                            <ExcelColumn label="Paid" value={(col) => (col.isPaid ? 'Yes' : '')} />
                            <ExcelColumn label="Provisional" value={(col) => (col.provisional ? 'Yes' : '')} />
                            <ExcelColumn label="Boat Deleted" value={(col) => (col.boatDeleted ? 'Yes' : '')} />
                            <ExcelColumn label="Booking Deleted" value={(col) => (col.bookingDeleted ? 'Yes' : '')} />
                        </ExcelSheet>
                    </ExcelFile>
                )}
            </div>
        </div>
    );
};

export default ReportSingleUseDiscounts;
