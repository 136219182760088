import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiTicketCategoriesGet } from '../../api/api-ticket-types';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { FontAwesomeIcon, PageTitle, Table, TableCell, TableRow } from '../common';
import { ticketCategoriesPageActions } from './page-actions';

const TicketCategoryList = () => {
    const [ticketCategories, setTicketCategories] = useState([]);
    const { displayType } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);
    const [anyDisabledTicketCategories, setAnyDisabledTicketCategories] = useState(false);

    const LoadData = useCallback(async () => {
        setTicketCategories(await apiTicketCategoriesGet(tourTypeId));
    }, [tourTypeId]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        setAnyDisabledTicketCategories(ticketCategories.filter((x) => x.isDisabled).length > 0);
    }, [ticketCategories]);

    const ticketCategoriesToDisplay = () => {
        if (displayType === 'disabled') {
            return ticketCategories.filter((x) => x.isDisabled);
        } else {
            return ticketCategories.filter((x) => !x.isDisabled);
        }
    };

    return (
        <div className="max-w-screen-md my-2 sm:m-4 md:mx-auto">
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Categories" actions={ticketCategoriesPageActions(displayType, anyDisabledTicketCategories)} />
                <Table>
                    <tbody>
                        {ticketCategoriesToDisplay().map((item, key) => (
                            <TableRow key={key} rowNum={key}>
                                <TableCell>
                                    {item.ticketCategoryName}
                                    {item.isDisabled ? ' (Disabled)' : ''}
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                    <div className="w-8 text-center" title="Split Groups Across Tours">
                                        {item.isPrivate && <FontAwesomeIcon icon="lock" />}
                                    </div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                    <div className="flex items-center w-12 justify-center" title="Max Tickets Per Booking">
                                        <FontAwesomeIcon icon="ticket-alt" className="mr-1" />
                                        <span>{item.maxTicketsPerBooking}</span>
                                    </div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                    <div className="flex items-center justify-center" title="Preferred Configuration">
                                        {item.preferredTourConfigurationName && (
                                            <>
                                                <FontAwesomeIcon icon="heart" className="mr-1" />
                                                {item.preferredTourConfigurationName}
                                            </>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                    <div className="w-8 text-center" title="Split Groups Across Tours">
                                        {item.allowSplitAcrossTours && <FontAwesomeIcon icon="candy-corn" />}
                                    </div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                    <div className="w-8 text-center" title="COVID Seating">
                                        {item.covidSeating && <FontAwesomeIcon icon="head-side-mask" />}
                                    </div>
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">
                                    <div className="w-8 text-center" title="Specified Slots Only">
                                        {item.specifiedSlotsOnly && <FontAwesomeIcon icon="stream" primaryColour="red" secondaryColour="blue" secondaryOpacity={1} />}
                                    </div>
                                </TableCell>

                                <TableCell action>
                                    <Link to={`/ticket-category/${item.id}`}>
                                        <FontAwesomeIcon icon="edit" title="Edit" />
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default TicketCategoryList;
