import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiDayGet = async (tourTypeId, date) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Day',
        params: { tourTypeId, date: fixDate(date) },
    });

    return result.data;
};

export const apiDaySave = async (tourTypeId, date, maxGroups, tourConfigurations, limits) => {
    const result = await axios({
        method: 'post',
        url: '/api/v1/Day',
        data: { tourTypeId, date: fixDate(date), maxGroups, tourConfigurations, limits },
    });

    return result.data;
};
