import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Login from '../auth/login';
import Logout from '../auth/logout';
import DashboardLayout from './dashboard-layout';

const Layout = ({ location }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Router fallback={<span />}>
            <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/logout" exact component={Logout} />
                <Route component={DashboardLayout} />
            </Switch>
        </Router>
    );
};

export default withRouter(Layout);
