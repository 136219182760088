import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { apiBookingsSearch } from '../../api/api-bookings';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { formatDate, formatTime } from '../../helpers/helpers';
import { ButtonSubmit, FontAwesomeIcon, InputText, PageTitle, Table, TableCell, TableRow } from '../common';

const Search = () => {
    const [data, setData] = useState([]);
    const { tourTypeId } = useContext(TourTypeContext);
    const history = useHistory();

    const PrivateDisplay = ({ tourIsPrivate, bookingIsPrivate, toutPrivate }) => {
        if (tourIsPrivate || bookingIsPrivate || toutPrivate) {
            const title = tourIsPrivate ? 'Private Tour' : bookingIsPrivate ? 'Private Booking' : toutPrivate ? 'Tout Private' : '';

            return <FontAwesomeIcon icon="lock-alt" className="mx-1" title={title} />;
        }
        return null;
    };

    const NotesDisplay = ({ tourNotes, bookingNotes }) => {
        if (tourNotes && bookingNotes) {
            return (
                <>
                    <FontAwesomeIcon icon="sticky-note" className="mx-1" title={bookingNotes} />
                    <FontAwesomeIcon icon="sticky-note" className="mx-1" title={tourNotes} />
                </>
            );
        }

        if (tourNotes || bookingNotes) {
            return <FontAwesomeIcon icon="sticky-note" className="mx-1" title={bookingNotes || tourNotes} />;
        }
        return null;
    };

    const ContactNumberIssueDisplay = ({ contactNumberIssue }) => {
        return contactNumberIssue ? <FontAwesomeIcon icon="comment-alt-exclamation" className="mx-1" danger title="Contact Number Issue" /> : null;
    };

    const SmsDisplay = ({ smsHold }) => {
        return smsHold ? <FontAwesomeIcon icon="comment-alt-times" className="mx-1" title="SMS Held" /> : null;
    };

    const PaidDisplay = ({ isPaid }) => {
        return isPaid ? null : <FontAwesomeIcon icon="pound-sign" className="mx-1" danger title="Unpaid" />;
    };

    const formSchema = Yup.object().shape({
        search: Yup.string().required('Required'),
    });

    const goTo = (dateString, g2bookingId) => {
        history.push(`/${dateString.substring(0, 10)}/${g2bookingId}`);
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Search" />
            <Formik
                initialValues={{ search: '' }}
                enableReinitialize
                validationSchema={formSchema}
                onSubmit={async (values) => {
                    setData(await apiBookingsSearch(tourTypeId, values.search));
                }}>
                {({ isSubmitting }) => (
                    <Form className="px-2 sm:px-0">
                        <div className="flex gap-x-2">
                            <InputText name="search" wrapperClassName="flex-grow" />
                            <div className="flex-initial">
                                <ButtonSubmit isSubmitting={isSubmitting} label="Search" />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <Table>
                <thead>
                    <tr>
                        <TableCell text="Date" header center className="w-1/12" />
                        <TableCell text="Time" header className="w-1/12" />
                        <TableCell text="Lane" header center className="w-1/12" />
                        <TableCell text="Type" header className="w-1/12" />
                        <TableCell text="Seats" header center className="w-1/12" />
                        <TableCell text="Order Id" header />
                        <TableCell text="Name" header />
                        <TableCell text="Source" header className="hidden md:table-cell" />
                        <TableCell text="" header className="w-2/12" />
                    </tr>
                </thead>

                <tbody>
                    {data.map((item, key) => (
                        <TableRow key={key} rowNum={key}>
                            <TableCell text={formatDate(item.date)} />
                            <TableCell text={formatTime(item.time)} />
                            <TableCell center text={item.lane} />
                            <TableCell>|{item.noOfSections}|</TableCell>
                            <TableCell center>
                                <PrivateDisplay {...item} />
                                {item.seatsBooked}
                                {!item.bookingIsPrivate && !item.toutPrivate && !item.tourIsPrivate && item.seatsRequired && item.seatsRequired !== item.seatsBooked && <span>/{item.seatsRequired}</span>}
                            </TableCell>
                            <TableCell>
                                {item.orderId}
                                {!item.deletedBy && item.g2BookingId && <FontAwesomeIcon icon="edit" className="ml-1" onClick={() => goTo(item.date, item.g2BookingId)} />}
                            </TableCell>
                            <TableCell>
                                {item.deletedBy && (
                                    <>
                                        <span className="mr-2 text-red-600">DELETED ({item.deletedBy})</span>
                                        <br />
                                    </>
                                )}
                                {item.name}
                                <NotesDisplay {...item} />
                                {item.g2BookingId && (
                                    <>
                                        <br />
                                        <span>[{item.g2BookingId}]</span>
                                    </>
                                )}
                            </TableCell>
                            <TableCell text={item.sourceName || item.sourceUserName} />
                            <TableCell right>
                                <ContactNumberIssueDisplay {...item} />
                                <SmsDisplay {...item} />
                                <PaidDisplay {...item} />
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default Search;
