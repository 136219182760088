import moment from 'moment';
import { colours, tourTypeIdPunting } from '../../../helpers/constants';
import { range } from '../../../helpers/helpers';

export const getNowTime = (date) => {
    //Past
    if (moment(date).isBefore(moment(), 'day')) {
        return '23:59';
    }
    // Future
    if (moment(date).isAfter(moment(), 'day')) {
        return '00:00';
    }
    //Today
    return moment().format('HH:mm');
};

export const getLanes = (tourType, bookings, useCore) => {
    const bookingLanes = bookings.map((x) => x.lane);

    if (useCore) {
        bookingLanes.push(tourType.coreFromLane);
        bookingLanes.push(tourType.coreToLane);
    }

    if (bookingLanes.length === 0) {
        return null;
    }

    return range(Math.min(...bookingLanes), Math.max(...bookingLanes));
};

export const getHours = (tourType, bookings, useCore) => {
    const bookingHours = [...bookings.map((x) => parseInt(moment(x.time, 'HH:mm:ss').format('H'))), ...bookings.map((x) => parseInt(moment(x.time, 'HH:mm:ss').add(x.duration, 'minutes').format('H')))];

    if (useCore) {
        bookingHours.push(tourType.coreFromHour);
        bookingHours.push(tourType.coreToHour);
    }

    if (bookingHours.length === 0) {
        return null;
    }

    return range(Math.min(...bookingHours), Math.max(...bookingHours));
};

export const calcIsFull = (tourTypeId, totalSeatsBooked, noOfSections) => {
    if (tourTypeId === tourTypeIdPunting)
        if (noOfSections === 2 && totalSeatsBooked >= 12) {
            return true;
        }
    if (noOfSections === 3 && totalSeatsBooked >= 11) {
        return true;
    }

    return false;
};

export const tourStyle = (firstTour, isFull, isPrivate, hasChineseGroup, hasProvisional) => {
    let colour = colours.open;

    if (isFull) {
        colour = colours.full;
    }

    if (isPrivate) {
        colour = colours.private;
    }

    if (hasChineseGroup) {
        colour = colours.chinese;
    }

    if (hasProvisional) {
        colour = colours.provisional;
    }

    const height = 0.1 * firstTour.duration;
    const top = 0.1 * moment(firstTour.time, 'HH:mm:ss').subtract(firstTour.timeNearest15Minutes).minute();

    return {
        cssStyle: {
            height: `${height}rem`,
            top: `${top}rem`,
        },
        colours: colour,
    };
};
