import React, { useContext } from 'react';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { tourTypes } from '../../helpers/constants';
import { Table, TableCell, TableRow, PageTitle, FontAwesomeIcon } from '../common';

const TypeSwitch = () => {
    const { tourTypeId, selectTourTypeId } = useContext(TourTypeContext);

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 sm:rounded-lg shadow-md bg-white sm:px-4 py-4 md:mx-auto">
            <PageTitle title="Switch Type" />
            <Table>
                <tbody>
                    {tourTypes.map((item, key) => (
                        <TableRow key={key} rowNum={key} onClick={() => selectTourTypeId(item.id)}>
                            <TableCell text={item.tourTypeName} />
                            <TableCell action>{tourTypeId !== item.id && <FontAwesomeIcon icon="random" onClick={() => selectTourTypeId(item.id)} />}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default TypeSwitch;
