import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiG1BookingsGet = async (tourTypeId, date) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Glide1/Bookings',
        params: { tourTypeId, date: fixDate(date) },
    });

    return result.data;
};

export const apiG2BookingGet = async (tourTypeId, g2BookingId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/booking',
        params: { tourTypeId, g2BookingId },
    });

    return result.data;
};

export const apiBookingsSearch = async (tourTypeId, search) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Bookings/Search',
        params: { tourTypeId, search },
    });

    return result.data;
};

export const apiG1TourEdit = async (tourTypeId, id, date, time, duration, lane, noOfSections, createdById, chauffeurId, notes, smsHold, smsHoldOriginal) => {
    if (!chauffeurId) {
        chauffeurId = null;
    }

    if (!createdById) {
        createdById = null;
    }

    try {
        const result = await axios({
            method: 'post',
            url: '/api/v1/Glide1/Tour',
            data: { tourTypeId, id, date: fixDate(date), time, duration, lane, noOfSections, createdById, chauffeurId, notes, smsHold, smsHoldOriginal },
        });

        return { successful: true, id: result.data, error: undefined };
    } catch (ex) {
        return { successful: false, id: undefined, error: ex.data };
    }
};

export const apiG2BookingEdit = async (
    tourTypeId,
    g2BookingId,
    date,
    time,
    g1Booking1,
    g1Booking2,
    g1Booking3,
    g1Booking4,
    source,
    name,
    ticketNumber,
    contactNumber,
    email,
    depositPaid,
    paymentMethod,
    isPrivate,
    isPaid,
    ticketCategoryId,
    companyId,
    invoiceId,
    happyToSitOpposite,
    notes,
    tickets,
    boltOns,
    sendEmail,
    provisional
) => {
    if (!source) {
        source = null;
    }

    try {
        const result = await axios({
            method: 'post',
            url: '/api/v1/Booking',
            data: {
                tourTypeId,
                g2BookingId,
                date: fixDate(date),
                time,
                g1Booking1,
                g1Booking2,
                g1Booking3,
                g1Booking4,
                source,
                name,
                ticketNumber,
                contactNumber,
                email,
                depositPaid,
                paymentMethod,
                isPrivate,
                isPaid,
                ticketCategoryId,
                companyId,
                invoiceId,
                happyToSitOpposite,
                notes,
                tickets,
                boltOns,
                sendEmail,
                provisional,
            },
        });

        return { successful: true, id: result.data, error: undefined };
    } catch (ex) {
        return { successful: false, id: undefined, error: ex.data };
    }
};

export const apiBookingSummaryContactNumberValidator = async (contactNumber) => {
    if (!contactNumber) {
        //Not a required field
        return {
            formattedContactNumber: undefined,
            isValid: true,
        };
    }

    const result = await axios({
        method: 'post',
        url: '/api/v1/ContactNumberValidator',
        params: { contactNumber },
    });

    return result.data;
};

export const apiG2BookingDelete = async (tourTypeId, g2BookingId, emailAffiliate = false) => {
    await axios({
        method: 'delete',
        url: '/api/v1/Booking',
        data: {
            tourTypeId,
            g2BookingId,
            emailAffiliate,
        },
    });
};

export const apiG1TourDelete = async (tourTypeId, boatId) => {
    try {
        await axios({
            method: 'delete',
            url: '/api/v1/Glide1/Boat',
            data: {
                tourTypeId,
                boatId,
            },
        });

        return { successful: true, error: undefined };
    } catch (ex) {
        return { successful: false, error: ex.data };
    }
};
