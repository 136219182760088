import axios from 'axios';
import { fixDate } from '../helpers/helpers';

export const apiReport = async (tourTypeId, purchasedFrom, tourFrom, includeDeleted, affiliateOnly) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Report',
        params: { tourTypeId, purchasedFrom: fixDate(purchasedFrom), tourFrom: fixDate(tourFrom), includeDeleted, affiliateOnly },
    });

    return result.data;
};

export const apiReportBoltOn = async (tourTypeId, purchasedFrom, tourFrom) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Report/BoltOn',
        params: { tourTypeId, purchasedFrom: fixDate(purchasedFrom), tourFrom: fixDate(tourFrom) },
    });

    return result.data;
};

export const apiReportSingleUseDiscounts = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Report/SingleUseDiscounts',
        params: { tourTypeId },
    });

    return result.data;
};

export const apiReportDashboard = async (tourTypeId, from) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/Report/Dashboard',
        params: { tourTypeId, from: fixDate(from) },
    });

    return result.data;
};

export const apiBoatLaneIssues = async (tourTypeId) => {
    const result = await axios({
        method: 'get',
        url: '/api/v1/BoatLaneIssues',
        params: { tourTypeId },
    });

    return result.data;
};
