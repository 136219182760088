import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import ReactExport from 'react-export-excel';
import * as Yup from 'yup';
import { apiReportDashboard } from '../../api/api-reports';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { Button, ButtonSubmit, ErrorDisplay, InputDate, PageTitle } from '../common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ReportDashboard = () => {
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);
    const { tourTypeId } = useContext(TourTypeContext);

    const formSchema = Yup.object().shape({
        from: Yup.date().nullable().typeError('Invalid'),
    });

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <div className="sm:rounded-lg shadow-md bg-white sm:px-4 py-4 mb-2">
                <PageTitle title="Dashboard Report" />

                <Formik
                    initialValues={{ from: '' }}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={async (values) => {
                        setData(undefined);
                        setError(undefined);
                        if (!values.from) {
                            setError('Please the from date');
                            return;
                        }

                        const result = await apiReportDashboard(tourTypeId, values.from);

                        setData(result);
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="flex gap-2 items-center mb-1">
                                <div className="leading-3 text-sm font-semibold w-36">From:</div>
                                <InputDate name="from" />
                            </div>
                            <div className="mt-4">
                                <span className="font-semibold">Note:</span> Report runs for a month from the selected date.
                            </div>

                            <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" label="Run" />
                            <ErrorDisplay error={error} />
                        </Form>
                    )}
                </Formik>

                {data && (
                    <ExcelFile element={<Button label="Download" className="mt-2" />} filename="Glide Dashboard Report">
                        <ExcelSheet data={data.reportRows} name="Data">
                            <ExcelColumn label="Date" value={(col) => col.date.substring(0, 10)} />

                            <ExcelColumn label="Private Bookings (Online)" value="bookingsPrivateOnline" />
                            <ExcelColumn label="Private Bookings (Street)" value="bookingsPrivateStreet" />
                            <ExcelColumn label="Shared Groups (All)" value="bookingsShared" />

                            <ExcelColumn label="Shared Seats (Online)" value="seatsSharedOnline" />
                            <ExcelColumn label="Shared Seats (Street)" value="seatsSharedStreet" />
                            <ExcelColumn label="Shared Seats (Total)" value="seatsSharedTotal" />

                            <ExcelColumn label="Shared Required (Online)" value="requiredSharedOnline" />
                            <ExcelColumn label="Shared Required (Street)" value="requiredSharedStreet" />
                            <ExcelColumn label="Shared Required (Total)" value="requiredSharedTotal" />

                            <ExcelColumn label="Boats (Booked)" value="bookedBoats" />
                            <ExcelColumn label="Boats (Total)" value="totalBoats" />

                            <ExcelColumn label="Sales" value="salesAmount" />
                        </ExcelSheet>
                    </ExcelFile>
                )}
            </div>
        </div>
    );
};

export default ReportDashboard;
