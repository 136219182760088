import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiReviewsGet, apiReviewsSave } from '../../api/api-reviews';
import { TourTypeContext } from '../../contexts/tour-type-context';
import { ButtonSubmit, FontAwesomeIcon, InputCheckbox, PageTitle, Table, TableCell, TableRow } from '../common';
import { reviewsPageActions } from './page-actions';

const ReviewList = () => {
    const [reviewSmsBookings, setReviewSmsBookings] = useState([]);
    const [dailyNotes, setDailyNotes] = useState([]);
    const [hasMoreRecords, setHasMoreRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const { displayType } = useParams();
    const { tourTypeId } = useContext(TourTypeContext);

    const LoadData = useCallback(async () => {
        let reviewSmsStatus;
        switch (displayType) {
            case 'blocked':
                reviewSmsStatus = 'D';
                break;
            case 'failed':
                reviewSmsStatus = 'F';
                break;
            default:
                reviewSmsStatus = null;
                break;
        }

        setLoading(true);
        var data = await apiReviewsGet(tourTypeId, null, null, reviewSmsStatus);
        setReviewSmsBookings(data.reviewSmsBookings);
        setDailyNotes(data.dailyNotes);
        setHasMoreRecords(data.hasMoreRecords);
        setLoading(false);
    }, [tourTypeId, displayType]);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    const getStatusIcon = (statusDisplay) => {
        switch (statusDisplay) {
            case 'Blocked':
                return 'ban';
            case 'Failed':
                return 'exclaimation-triangle';
            default:
                return '';
        }
    };

    let d;
    const showDateHeader = (date) => {
        if (date === d) {
            return false;
        }
        d = date;
        return true;
    };

    return (
        <div className="max-w-screen-lg my-2 sm:m-4 md:mx-auto">
            <div className="md:grid md:grid-cols-5 md:gap-4">
                <div className="sm:rounded-lg shadow-md md:col-span-3 bg-white sm:px-4 py-4 mb-2">
                    <PageTitle title="Reviews" actions={reviewsPageActions(displayType)} />

                    {!loading && (
                        <Formik
                            enableReinitialize
                            initialValues={{ data: reviewSmsBookings }}
                            onSubmit={async (values) => {
                                await apiReviewsSave(tourTypeId, values.data);

                                await LoadData();

                                if (reviewSmsBookings.length === 0) {
                                    history.push('/');
                                }
                            }}>
                            {({ isSubmitting, values }) => (
                                <FieldArray
                                    name="data"
                                    render={() => (
                                        <>
                                            <Form>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            {displayType && <TableCell text="" header />}
                                                            <TableCell text="Date/Time" header />
                                                            <TableCell text="Lane" header />
                                                            <TableCell text="Company" header />
                                                            <TableCell text="Name" header />
                                                            <TableCell text="Block?" header />
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {values.data.map((item, key) => (
                                                            <TableRow key={key} rowNum={key}>
                                                                {displayType && (
                                                                    <TableCell>
                                                                        <FontAwesomeIcon icon={getStatusIcon(item.statusDisplay)} className="mr-1" title={item.statusDisplay} />
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>{item.dateTimeDisplay}</TableCell>
                                                                <TableCell>
                                                                    {item.lane}
                                                                    {item.isPrivate && <FontAwesomeIcon icon="lock" className="ml-2" />}
                                                                </TableCell>
                                                                <TableCell>{item.companyId ? <span>{item.companyId}</span> : item.isTPC ? <span>TPC</span> : <span>LGP</span>}</TableCell>
                                                                <TableCell className="tablecell-wrap-normal">
                                                                    {item.name}
                                                                    {item.ticketNumber && <span className="text-xs"> (Ticket: {item.ticketNumber})</span>}
                                                                    {item.source && <div className="text-xs">Source: {item.source}</div>}
                                                                    {item.chauffeur && <div className="text-xs">Chauffeur: {item.chauffeur}</div>}
                                                                </TableCell>
                                                                <TableCell actionLeft>
                                                                    <InputCheckbox name={`data.${key}.doNotSend`} hideLabel="true" />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                {!loading && reviewSmsBookings && reviewSmsBookings.length > 0 && <ButtonSubmit isSubmitting={isSubmitting} className="mt-4" label={hasMoreRecords ? 'Send and load next' : 'Send'} />}
                                            </Form>
                                        </>
                                    )}
                                />
                            )}
                        </Formik>
                    )}
                </div>
                <div className="sm:rounded-lg shadow-md md:col-span-2 bg-white sm:px-4 py-4 mb-2">
                    <PageTitle title="Daily Notes" />
                    {dailyNotes.map((item, key) => (
                        <div key={key}>
                            {showDateHeader(item.date) && <h2 className="text-l font-bold  text-blue-700 pt-4">{item.dateDisplay}</h2>}
                            <p>{item.notes}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReviewList;
