import React, { useEffect, useState } from 'react';
import { apiBoltOnsGetUpcoming } from '../../api/api-bolt-ons';
import { formatDate, formatTime } from '../../helpers/helpers';
import { Table, TableCell, TableRow } from '../common';

export default function BoltOnsUpcoming({ tourTypeId, date }) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);

            setData(await apiBoltOnsGetUpcoming(tourTypeId, date));

            setIsLoading(false);
        };

        loadData();
    }, [date, tourTypeId]);

    return (
        <>
            {!isLoading && (
                <>
                    {data.length === 0 ? (
                        <div>None</div>
                    ) : (
                        <div className="overflow-y-auto max-h-60">
                            <Table slim>
                                <thead>
                                    <tr>
                                        <TableCell text="Date" header />
                                        <TableCell text="Time" header />
                                        <TableCell text="Lane" header center />
                                        <TableCell text="Booking Name" header />
                                        <TableCell text="Amount" header right />
                                        <TableCell text="Details" header />
                                    </tr>
                                </thead>
                                <tbody className="align-baseline">
                                    {data.map((item, key) => (
                                        <TableRow key={key} rowNum={key}>
                                            <TableCell text={formatDate(item.date)} />
                                            <TableCell text={formatTime(item.time)} />
                                            <TableCell center text={item.lanes} />
                                            <TableCell text={item.name} />
                                            <TableCell text={item.amount} right />
                                            <TableCell>
                                                {item.boltOnName}
                                                {item.answer && (
                                                    <div>
                                                        {item.question}: {item.answer}
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
