const ticketCategoriesDefaultPageActions = [
    {
        title: 'Add New',
        to: '/ticket-category',
    },
];

export const ticketCategoriesPageActions = (displayType, anyDisabled) => {
    switch (displayType) {
        case 'disabled':
            return [
                {
                    title: 'Show Active',
                    to: '/ticket-categories',
                },
                ...ticketCategoriesDefaultPageActions,
            ];
        case 'active':
        default:
            if (anyDisabled) {
                return [
                    {
                        title: 'Show Disabled',
                        to: '/ticket-categories/disabled',
                    },
                    ...ticketCategoriesDefaultPageActions,
                ];
            } else {
                return ticketCategoriesDefaultPageActions;
            }
    }
};

export const ticketCategoryEditPageActions = [
    {
        title: 'Back',
        to: '/ticket-categories',
    },
];
